import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JsonConvert } from 'json2typescript';
import { Observable } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Account, AccountData } from '../../models/account';
import { HttpUtilsService } from '../http-utils.service';
import { Location } from '../../models/location';
import { MonitorLocker, MonitorLockerArrayData } from '../../models/locker/monitor-locker';

@Injectable({
	providedIn: 'root'
})
export class LoginService {

	readonly endpoint: string = environment.baseUrl;

	constructor(private http: HttpClient, private httpUtils: HttpUtilsService, private router: Router) { }
	
	/** Get user's data */
	me(): Observable<Account | undefined> {
		try {
			return this.httpUtils.getHTTPHeadersAsync().pipe(
				mergeMap(httpHeaders => {
					return this.http.get(`${this.endpoint}/me`, { headers: httpHeaders }).pipe(
						map((res: any) => {
							const jsonConvert: JsonConvert = new JsonConvert();
							const result: AccountData = jsonConvert.deserializeObject(res, AccountData);
							if (result.success) {
								return result.data;
							} else {
								this.httpUtils.handleError(result);
								return;
							}
						}, catchError(err => {
							if (err.status === 401) { // Unauthorized
								this.httpUtils.logout();
							}
							throw err;
						})));
				}), catchError(err => {
					throw err;
				})
			);
		} catch (error) {
			throw error;
		}
	}

	getAccountLockers(): Observable<Array<MonitorLocker>> {
		// let location1 = new Location();
		// location1.name = 'Location 1';
		// location1.uuid = '1';
		// location1.address = {
		// 	city: 'city 1',
		// 	country: 'country 1',
		// 	zip_code: 'zip_code 1',
		// 	province: 'province 1',
		// 	region: 'region 1',
		// 	address: 'address 1'
		// };
		// let location2 = new Location();
		// location2.name = 'Location 2';
		// location2.uuid = '2';
		// location2.address = {
		// 	city: 'city 2',
		// 	country: 'country 2',
		// 	zip_code: 'zip_code 2',
		// 	province: 'province 2',
		// 	region: 'region 2',
		// 	address: 'address 2'
		// };
		// let locker1 = new MonitorLocker();
		// locker1.uuid = '1';
		// locker1.name = 'Locker 1';
		// locker1.location = location1;
		// locker1.vendor = 'vendor 1';
		// let locker2 = new MonitorLocker();
		// locker2.uuid = '2';
		// locker2.name = 'Locker 2';
		// locker2.location = location1;
		// locker2.vendor = 'vendor 2';
		// let locker3 = new MonitorLocker();
		// locker3.uuid = '3';
		// locker3.name = 'Locker 3';
		// locker3.location = location2;
		// locker3.vendor = 'vendor 2';
		// let locker4 = new MonitorLocker();
		// locker4.uuid = '4';
		// locker4.name = 'Locker 4';
		// locker4.location = location2;
		// locker4.vendor = 'vendor 3';
		// let locker5 = new MonitorLocker();
		// locker5.uuid = '5';
		// locker5.name = 'Locker 5';
		// locker5.location = location1;
		// locker5.vendor = 'vendor 3';
		// return new Observable<Array<MonitorLocker>>(observer => {
		// 	observer.next([
		// 		locker1,
		// 		locker2,
		// 		locker3,
		// 		locker4,
		// 		locker5
		// 	]);
		// });
		try {
			return this.httpUtils.getHTTPHeadersAsync().pipe(
				mergeMap(httpHeaders => {
					return this.http.get(`${this.endpoint}/me/lockers`, { headers: httpHeaders }).pipe(
						map((res: any) => {
							const jsonConvert: JsonConvert = new JsonConvert();
							const result: MonitorLockerArrayData = jsonConvert.deserializeObject(res, MonitorLockerArrayData);
							if (result.success) {
								return result.data;
							} else {
								this.httpUtils.handleError(result);
								return [];
							}
						}, catchError(err => {
							if (err.status === 401) { // Unauthorized
								this.httpUtils.logout();
							}
							throw err;
						})));
				}), catchError(err => {
					throw err;
				})
			);
		} catch (error) {
			throw error;
		}
	}
}
