import { JsonConverter, JsonCustomConvert } from "json2typescript";

@JsonConverter
export class BoxTypeConverter implements JsonCustomConvert<BoxType> {
	serialize(): any { }
	deserialize(data: any): BoxType {
		let resp: BoxType = BoxType.UNKNOWN;

		switch(data) {
			case "NORMAL": {
				resp = BoxType.NORMAL;
				break;
			}
			case "RESERVED": {
				resp = BoxType.RESERVED;
				break;
			}
			default: {
				resp = BoxType.UNKNOWN;
				break;
			}
		}

		return resp;
	}
}

export enum BoxType {
	NORMAL = "NORMAL",
	RESERVED = "RESERVED",
	UNKNOWN = "UNKNOWN"
}