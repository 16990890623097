import { JsonConvert, JsonConverter, JsonCustomConvert, JsonObject, JsonProperty } from "json2typescript";
import { Resp } from "./resp";

@JsonObject('DateAnalysisObject')
export class DateAnalysisObject {
    @JsonProperty('key', Number)
    key: number = 0;
    @JsonProperty('value', Number)
    value: number = 0;
}

@JsonConverter
export class DateAnalysisObjectConverter implements JsonCustomConvert<DateAnalysisObject> {
    serialize() {}
    deserialize(data: any): DateAnalysisObject {
        const jsonConvert: JsonConvert = new JsonConvert();
        return jsonConvert.deserializeObject(data, DateAnalysisObject);
    }
}

@JsonConverter
export class DateAnalysisObjectArrayConverter implements JsonCustomConvert<Array<DateAnalysisObject>> {
    serialize() { }
    deserialize(data: any): Array<DateAnalysisObject> {
        const jsonConvert: JsonConvert = new JsonConvert();
        return jsonConvert.deserializeArray(data, DateAnalysisObject);
    }
}

@JsonObject("DateAnalysisObjectArrayData")
export class DateAnalysisObjectArrayData extends Resp {
    @JsonProperty("data", DateAnalysisObjectArrayConverter, true)
    data: Array<DateAnalysisObject> = [];
}