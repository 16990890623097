import { RBG } from "../models/rgb";
import { Tone } from "../models/tone.enum";

/**
 * This class provides color utilities
 */
export class ColorUtils {
	private static readonly _lightClass: string = "light-widget-color";
	/**
	 * Get light font color class
	 */
	public static get lightClass(): string {
		return this._lightClass;
	}
	private static readonly _darkClass: string = "dark-widget-color";
	/**
	 * Get dark font color class
	 */
	public static get darkClass(): string {
		return this._darkClass;
	}

	/**
	 * Check if color is light or dark
	 * @param {*} color 
	 * @returns 
	 */
	static colorTone(color: string): Tone {
		// Get rgb component of color
		const rgb: RBG | null = this.toRGB(color);
		if (rgb) {
			const luma: number = 0.2126 * rgb.r + 0.7152 * rgb.g + 0.0722 * rgb.b; // per ITU-R BT.709
			if (luma < 140) {
				return Tone.DARK;
			} else {
				return Tone.LIGHT;
			}
		} else {
			return Tone.DARK;
		}
	}

	/**
	 * Convert hexadecimal color to rgb color
	 * @param {*} color 
	 * @returns 
	 */
	 static toRGB(color: string): RBG | null {
		// Color is hexadecimal
		if (color.indexOf('#') > -1) {
			const c: string = color.substring(1);
			const aRgbHex: RegExpMatchArray | null = c.match(/.{1,2}/g);
			if (aRgbHex) {
				const aRgb: RBG = {
					r: parseInt(aRgbHex[0], 16),
					g: parseInt(aRgbHex[1], 16),
					b: parseInt(aRgbHex[2], 16)
				};
				return aRgb;
			} else {
				return null;
			}
		} else if (color.indexOf('rgb') > -1) { // Color is rgb
			const rgb: Array<string> = color.substring(4, color.length - 1)
				.replace(/ /g, '')
				.split(',');
			const aRgb: RBG = {
				r: parseInt(rgb[0], 16),
				g: parseInt(rgb[1], 16),
				b: parseInt(rgb[2], 16)
			};
			return aRgb;
		} else {
			return null;
		}
	}
}