import { JsonConvert, JsonConverter, JsonCustomConvert, JsonObject, JsonProperty } from "json2typescript";
import { Resp } from "./resp";
import { Role, RoleConverter } from "./role";

@JsonConverter
export class BackOfficeUserArrayConverter implements JsonCustomConvert<Array<BackOfficeUser>> {
    serialize() {}
    deserialize(data: any): Array<BackOfficeUser> {
        const jsonConvert: JsonConvert = new JsonConvert();
        return jsonConvert.deserializeArray(data, BackOfficeUser);
    }
}

@JsonConverter
export class BackOfficeUserConverter implements JsonCustomConvert<BackOfficeUser> {
    serialize() {}
    deserialize(data: any): BackOfficeUser {
        const jsonConvert: JsonConvert = new JsonConvert();
        return jsonConvert.deserializeObject(data, BackOfficeUser);
    }
}

@JsonConverter
export class BackOfficeUsersListConverter implements JsonCustomConvert<BackOfficeUsersList> {
    serialize() {}
    deserialize(data: any): BackOfficeUsersList {
        const jsonConvert: JsonConvert = new JsonConvert();
        return jsonConvert.deserializeObject(data, BackOfficeUsersList);
    }
}

@JsonObject("BackOfficeUser")
export class BackOfficeUser {
    @JsonProperty("uuid", String, true)
    uuid: string = "";
    @JsonProperty("avatar", String, true)
    avatar: string = '';
    @JsonProperty("email", String, true)
    email: string = "";
    @JsonProperty("first_name", String, true)
    first_name: string = "";
    @JsonProperty("last_name", String, true)
    last_name: string = "";
    @JsonProperty("role", RoleConverter, true)
    role: Role | undefined = undefined;

    /** Return right body for the creation and updating requests */
    dto() {
        return {
			email: this.email,
			first_name: this.first_name,
            last_name: this.last_name,
            role: this.role,
            avatar: this.avatar
        }
    }
}

@JsonObject("BackOfficeUsersList")
export class BackOfficeUsersList {
    @JsonProperty("count", Number, true)
    count: number = 0;
    @JsonProperty("data", BackOfficeUserArrayConverter, true)
    data: Array<BackOfficeUser> = [];
}

@JsonObject("BackOfficeUsersArrayData")
export class BackOfficeUsersArrayData extends Resp {
    @JsonProperty("data", BackOfficeUserArrayConverter, true)
	data: Array<BackOfficeUser> = [];
}

@JsonObject("BackOfficeUserData")
export class BackOfficeUserData extends Resp {
    @JsonProperty("data", BackOfficeUserConverter, true)
    data: BackOfficeUser = new BackOfficeUser;
}

@JsonObject("BackOfficeUsersListData")
export class BackOfficeUsersListData extends Resp {
    @JsonProperty("data", BackOfficeUsersList, true)
    data: BackOfficeUsersList = new BackOfficeUsersList;
}