import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-auth-base',
  templateUrl: './auth-base.component.html',
  styleUrls: ['./auth-base.component.less']
})
export class AuthBaseComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
