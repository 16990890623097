import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JsonConvert } from 'json2typescript';
import { observable, Observable } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Resp } from '../../models/resp';
import { Widget, WidgetData } from '../../models/widget';
import { HttpUtilsService } from '../http-utils.service';

@Injectable({
	providedIn: 'root'
})
export class HomeService {
	readonly endpoint: string = environment.baseUrl;

	constructor(private http: HttpClient, private httpUtils: HttpUtilsService) { }

	/** Retrieves the list of saved home page widgets */
	getWidgets(): Observable<Array<Widget> | undefined> {
		try {
			return this.httpUtils.getHTTPHeadersAsync().pipe(
				mergeMap(httpHeaders => {
					const url: string = `${this.endpoint}/home/widgets`;
					return this.http.get(url, { headers: httpHeaders })
						.pipe(
							map((res: any) => {
								const jsonConvert: JsonConvert = new JsonConvert();
								const result: WidgetData = jsonConvert.deserializeObject(res, WidgetData);
								if (result.success) {
									return result.data;
								} else {
									this.httpUtils.handleError(result);
									return;
								}
							}, catchError(err => {
								if (err.status === 401) { // Unauthorized
									this.httpUtils.logout();
								}
								throw err;
							}))
						);
				})
			);
		} catch (error) {
			throw error;
		}
	}

	/** Update the list of home page widgets 
	 * @param body
	*/
	updateWidgets(body: string): Observable<Array<Widget> | undefined> {
		try {
			console.info("body update widgets");
			return this.httpUtils.getHTTPHeadersAsync().pipe(
				mergeMap(httpHeaders => {
					const url: string = `${this.endpoint}/home/widgets`;
					return this.http.put(url, body, { headers: httpHeaders })
						.pipe(
							map((res: any) => {
								const jsonConvert: JsonConvert = new JsonConvert();
								const result: WidgetData = jsonConvert.deserializeObject(res, WidgetData);
								if (result.success) {
									return result.data;
								} else {
									this.httpUtils.handleError(result);
									return;
								}
							}, catchError(err => {
								if (err.status === 401) { // Unauthorized
									this.httpUtils.logout();
								}
								throw err;
							}))
						);
				})
			);
		} catch (error) {
			throw error;
		}
	}
}
