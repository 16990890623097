import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import { BoxSize } from '../models/box-size';

@Directive({
  selector: '[appBox]'
})
export class LockerBoxDirective implements AfterViewInit {

  @Input() appBox: string = BoxSize.UNKNOWN;

  colorExtraSmall = '#85c5e3';
  colorSmall = '#d49487';
  colorMedium = '#c7ccbf';
  colorLarge = '#c7ccbf';
  colorExtraLarge = '#AABAD7';

  constructor(private el: ElementRef) {}

  ngAfterViewInit(){
    const cardHeader = this.el.nativeElement.querySelector('.ant-card-head');
    cardHeader.setAttribute('style', `background-color: ${this.getColorBasedOnSize()} !important`);
  }

  private getRandomColor(): string {
    let color = "#";
    for (let i = 0; i < 3; i++)
      color += ("0" + Math.floor(((1 + Math.random()) * Math.pow(16, 2)) / 2).toString(16)).slice(-2);
    return color;
  }

  private getColorBasedOnSize(): string {
    switch(this.appBox){
		case BoxSize.XL:
			return this.colorExtraLarge;
		case BoxSize.L: 
        	return this.colorLarge;
        case BoxSize.M: 
        	return this.colorMedium;  
        case BoxSize.S: 
        	return this.colorSmall;
        case BoxSize.XS: 
        	return this.colorExtraSmall;
		case BoxSize.UNKNOWN:
      	default:
			return this.getRandomColor();
    }
  }

}
