import { JsonConvert, JsonConverter, JsonCustomConvert, JsonObject, JsonProperty } from "json2typescript";
import { EventType, EventTypeConverter } from "./event-type";
import { MapConverter } from "../helpers/converters/map-converter";
import { Resp } from "./resp";

@JsonConverter
export class LockerUserArrayConverter implements JsonCustomConvert<Array<LockerUser>> {
    serialize() {}
    deserialize(data: any): Array<LockerUser> {
        const jsonConvert: JsonConvert = new JsonConvert();
        return jsonConvert.deserializeArray(data, LockerUser);
    }
}

@JsonObject("LockerUser")
export class LockerUser {
    @JsonProperty("user_uuid", String, true)
    user_uuid: string = "";
    @JsonProperty("user_email", String, true)
    user_email: string = "";
    @JsonProperty("exchange_recipient_email", String, true)
    exchange_recipient_email: string | null = null;
    @JsonProperty("box_id", String, true)
    box_id: string = "";
    @JsonProperty("type", EventTypeConverter, true)
    type: EventType | undefined = undefined;
    @JsonProperty("attributes", MapConverter, true)
    attributes: Map<string, any> = new Map();
}

@JsonObject("LockerUserArrayData")
export class LockerUserArrayData extends Resp {
    @JsonProperty("data", LockerUserArrayConverter, true)
    data: Array<LockerUser> = [];
}
