import { JsonConvert, JsonConverter, JsonCustomConvert, JsonObject, JsonProperty } from "json2typescript";
import { Parity, ParityConverter } from "./parity";
import { StopBits, StopBitsConverter } from "./stop-bits";

@JsonConverter
export class SerialPortConverter implements JsonCustomConvert<SerialPort> {
	serialize() { }

	deserialize(data: any): SerialPort {
		const jsonConvert: JsonConvert = new JsonConvert();
		const response = jsonConvert.deserializeObject(data, SerialPort);
		return response;
	}
}


@JsonObject('SerialPort')
export class SerialPort {
	@JsonProperty('baudRate', Number, true)
	baudRate: number = 9600;

	@JsonProperty('dataBits', Number, true)
	dataBits: number = 8;

	@JsonProperty('parity', ParityConverter, true)
	parity: Parity = Parity.No;

	@JsonProperty('stopBits', StopBitsConverter, true)
	stopBits: StopBits = StopBits.UNKNOWN;

	dto() {
		return {
			baudRate: this.baudRate,
			dataBits: this.dataBits,
			parity: this.parity,
			stopBits: this.stopBits === StopBits.UNKNOWN? null: this.stopBits,
		}
	}
}