<ng-container *ngIf="!noLockerVisibility; else noVisibility">
    <div *ngIf="boxesStatus.size > 0; else noData" class="d-flex flex-wrap">
        <ng-container *ngIf="fixedWidth; else noFixedWidth">
            <div>
                <div class="mb-1">
                    <nz-tag nzColor="#218a57" style="width: 82px">
                        <span>{{getSumByStatus(boxStatus.FREE)}} {{'general.status.free' | translate}}</span>
                    </nz-tag>
                </div>
                <div class="mb-md-1">
                    <nz-tag nzColor="#e41115" style="width: 82px">
                        <span>{{getSumByStatus(boxStatus.BUSY)}} {{'general.status.busy' | translate}}</span>
                    </nz-tag>
                </div>
            </div>
            <div>
                <div class="mb-1">
                    <nz-tag nzColor="#efad02" style="width: 82px">
                        <span>{{getSumByStatus(boxStatus.OVERDUE)}} {{'general.status.overdue' | translate}}</span>
                    </nz-tag>
                </div>
                <div class="mb-md-1">
                    <nz-tag nzColor="#a9a9a9" style="width: 82px">
                        <span>{{getSumByStatus(boxStatus.BLOCKED)}} {{'general.status.BLOCKED' | translate}}</span>
                    </nz-tag>
                </div>
            </div>
            <div>
                <nz-tag nzColor="#faff06" style="width: 82px">
                    <span style="color: #000">{{getSumByStatus(boxStatus.RESERVED)}} {{'general.status.reserved' | translate}}</span>
                </nz-tag>
            </div>
        </ng-container>
    </div>
</ng-container>

<ng-template #noData>
    <h6 *ngIf="!fixedWidth">{{'monitor.boxesStatus' | translate | uppercase}}</h6>
    <p>{{'general.noDataAvailable' | translate}}</p>
</ng-template>
<ng-template #noFixedWidth>
    <h6>{{'monitor.boxesStatus' | translate | uppercase}}</h6>
    <nz-tag nzColor="#218a57" style="width: 100%" class="mb-1 me-0">
        <span class="fw-bold">{{getSumByStatus(boxStatus.FREE)}} {{'general.status.free' | translate}}</span>
    </nz-tag>
    <nz-tag nzColor="#e41115" style="width: 100%" class="mb-1 me-0">
        <span class="fw-bold">{{getSumByStatus(boxStatus.BUSY)}} {{'general.status.busy' | translate}}</span>
    </nz-tag>
    <nz-tag nzColor="#efad02" style="width: 100%" class="mb-1 me-0">
        <p class="fw-bold m-0 text-truncate">{{getSumByStatus(boxStatus.OVERDUE)}} {{'general.status.overdue' | translate}}: 
            <span class="fw-normal">{{'general.status.overdueDescription' | translate}}</span> 
        </p>
    </nz-tag>
    <nz-tag nzColor="#a9a9a9" style="width: 100%" class="mb-1 me-0">
        <p class="fw-bold m-0 text-truncate">{{getSumByStatus(boxStatus.BLOCKED)}} {{'general.status.BLOCKED' | translate}}: 
            <span class="fw-normal">{{'general.status.blockedDescription' | translate}}</span>
        </p>
    </nz-tag>
    <nz-tag nzColor="#faff06" style="width: 100%" class="mb-1 me-0">
        <p class="fw-bold m-0 text-truncate" style="color: #000">{{getSumByStatus(boxStatus.RESERVED)}} {{'general.status.reserved' | translate}}: 
            <span class="fw-normal" style="color: #000">{{'general.status.reservedDescription' | translate}}</span>
        </p>
    </nz-tag>
</ng-template>

<ng-template #noVisibility>
    <div class="col-12">{{'general.noVisibilityForThisLocker' | translate}}</div>
</ng-template>