import { JsonConverter, JsonCustomConvert, JsonObject, JsonProperty } from "json2typescript";
import { Resp } from "./resp";

@JsonConverter
export class WidgetTypeConverter implements JsonCustomConvert<WidgetType | undefined> {
	serialize() { }
	deserialize(data: any): WidgetType | undefined {
		switch (data) {
			case "MATH":
				return WidgetType.MATH;
			case "OVERDUE":
				return WidgetType.OVERDUE;
			case "USERS":
				return WidgetType.USERS;
			case "PROBLEMS":
				return WidgetType.PROBLEMS;
			case "LOGS":
				return WidgetType.LOGS;
			case "CONNECTIVITY":
				return WidgetType.CONNECTIVITY;
			case "CONTROL":
				return WidgetType.CONTROL;
			default:
				return undefined;
		}
	}
}

@JsonConverter
export class WidgetTypeArrayConverter implements JsonCustomConvert<Array<WidgetType | undefined>> {
	serialize() { }
	deserialize(data: any): Array<WidgetType | undefined>{
		const widgetTypes: Array<WidgetType | undefined> = [];
		<Array<any>>data.forEach((d: any) => {
			switch (d) {
				case "MATH":
					widgetTypes.push(WidgetType.MATH);
					break;
				case "OVERDUE":
					widgetTypes.push(WidgetType.OVERDUE);
					break;
				case "USERS":
					widgetTypes.push(WidgetType.USERS);
					break;
				case "PROBLEMS":
					widgetTypes.push(WidgetType.PROBLEMS);
					break;
				case "LOGS":
					widgetTypes.push(WidgetType.LOGS);
					break;
				case "CONNECTIVITY":
					widgetTypes.push(WidgetType.CONNECTIVITY);
					break;
				case "CONTROL":
					widgetTypes.push(WidgetType.CONTROL);
					break;
				default:
					widgetTypes.push(undefined);
					break;
			}
		});
		return widgetTypes;
	}
}

/**
 * Widget types
 * - MATH
 * - OVERDUE
 * - USERS
 * - PROBLEMS
 * - LOGS
 * - CONNECTIVITY
 * - CONTROL
 */
export enum WidgetType {
	MATH = 'MATH',
	OVERDUE = 'OVERDUE',
	USERS = 'USERS',
	PROBLEMS = 'PROBLEMS',
	LOGS = 'LOGS',
	CONNECTIVITY = 'CONNECTIVITY',
	CONTROL = 'CONTROL'
}

@JsonObject("WidgetTypeData")
export class WidgetTypeData extends Resp {
	@JsonProperty("data", WidgetTypeArrayConverter, true)
	data: Array<WidgetType> = [];
}
