<nz-card widgetLocker [nzTitle]="title" [nzExtra]="showDelete? extras: extrasRefresh" class="h-100">
	<div *ngIf="showLoader" class="w-100 position-relative p-3">
		<div class="full-page-loader">
			<nz-spin nzTip="{{'general.loaderMessage' | translate}}..."></nz-spin>
		</div>
	</div>
	
	<div *ngIf="!showLoader" class="row">
		<ng-container *ngIf="!noLockerVisibility; else noVisibility">
			<ng-container *ngIf="!isError;else empty">
				<div class="col-12 col-md-5 col-lg-5 col-xl-5 col-xxl-4">
					<img [src]="svg" width="100%" [redZoom]="svg" redZoomClass="red-zoom--style--window" />
				</div>
			</ng-container>
			<div class="col-12 col-md-7 col-lg-7 col-xl-7 col-xxl-8 mt-4 mt-xl-0">
				<ng-container *ngIf="showConnectivityStatus">
					<div class="mb-4">
						<app-connection-status [lockerUuid]="lockerUuid" [isFromMonitorLockerList]="false"></app-connection-status>
					</div>
				</ng-container>
				<div class="mb-4">
					<app-show-boxes-status [lockerUuid]="lockerUuid" [fixedWidth]="false"></app-show-boxes-status>
				</div>
				<app-users-per-locker [lockerUuid]="lockerUuid" [showCard]="false" [showNotification]="false"></app-users-per-locker>
			</div>
		</ng-container>
	</div>
</nz-card>

<ng-template #empty>
	<div class="col-12 col-md-5 col-lg-5 col-xl-5 col-xxl-4">
		<nz-empty nzNotFoundImage="simple"></nz-empty>
	</div>
</ng-template>

<ng-template #title>
	<h5 class="title">{{'monitor.math.title' | translate | uppercase}}
		<small *ngIf="lockerDescription">{{lockerDescription}}</small>
	</h5>
	<span class="text-muted subtitle">{{'monitor.math.subtitle' | translate}}</span>
</ng-template>

<ng-template #extrasRefresh>
	<button nz-button (click)="onRefresh()"><span nz-icon [nzType]="'sync'"></span></button>
</ng-template>


<ng-template #extras>
    <button nz-button nz-dropdown [nzDropdownMenu]="menu" nzPlacement="bottomRight"><span nz-icon nzType="ellipsis"></span></button>
    <nz-dropdown-menu #menu="nzDropdownMenu">
        <ul nz-menu>
			<li nz-menu-item (click)="onRefresh()"><span nz-icon nzType="sync" class="me-2"></span> {{'general.update' | translate}}</li>
			<li nz-menu-item nz-popconfirm
				nzPopconfirmTitle="{{'widgets.confirmDelete' | translate}}"
				nzPopconfirmPlacement="top"
				(nzOnConfirm)="confirmDelete()">
				<span nz-icon nzType="delete" class="me-2"></span> {{'general.delete' | translate}}</li>
        </ul>
    </nz-dropdown-menu>
</ng-template>

<ng-template #noVisibility>
	<nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="noLockersMessage"></nz-empty>
</ng-template>

<ng-template #noLockersMessage>
	<p>{{'general.noVisibilityForThisLocker' | translate}}<br><span>{{'general.pleaseContactAdmin' | translate}}</span></p>
</ng-template>