import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Subscription } from 'rxjs';
import { Helpers } from 'src/app/core/helpers';
import { MonitorLocker } from 'src/app/core/models/locker/monitor-locker';
import { LoginService } from 'src/app/core/services/network/login.service';
import { TenantService } from 'src/app/core/services/tenant.service';

@Component({
	selector: 'app-account-lockers',
	templateUrl: './account-lockers.component.html',
	styleUrls: ['./account-lockers.component.less']
})
export class AccountLockersComponent implements OnInit {

	lockers: Array<MonitorLocker> = [];
	lockersPerLocationMap: Map<string, Array<MonitorLocker>> = new Map();
	subscriptions: Array<Subscription> = [];
	loading: boolean = false;
	
	constructor(private loginService: LoginService,
		private notification: NzNotificationService,
		private translate: TranslateService,
		private tenantService: TenantService) { }

	ngOnInit(): void {
		this.loading = true;
		this.subscriptions.push(this.tenantService.tenant$.subscribe((tenant) => {
			this.getAccountLockers();
		}));
	}

	getAccountLockers(): void {
		this.loading = true;
		this.lockersPerLocationMap.clear();
		this.subscriptions.push(
			this.loginService.getAccountLockers().subscribe(
				{
					next: (resp: Array<MonitorLocker>) => {
						this.lockers = resp;
						if(this.lockers.length > 0){
							this.lockersPerLocationMap = this.lockersPerLocation();
						}
						this.loading = false;
					},
					error: (err: any) => {
						console.error(err);
						this.loading = false;
						Helpers.errorNotification(this.notification, this.translate, 'account.errors.unableToGetAccountLockers');
					}

				}
			)
		)
	}

	private lockersPerLocation(): Map<string, Array<MonitorLocker>>{
		let map: Map<string, Array<MonitorLocker>> = new Map();
		this.lockers.forEach(l => {
			if(map.has(l.location.uuid)){
				map.get(l.location.uuid)?.push(l);
			} else {
				map.set(l.location.uuid, [l]);
			}
		});
		return map;
	}
}
