<nz-card widgetLocker 
    [nzTitle]="title" 
    class="h-100">
    <div class="exportToPdfButton">
        <button nz-button [nzType]="'default'" (click)="exportToPdf()" [nzLoading]="loadingPdf" [disabled]="!boxSizeAnalisis || boxSizeAnalisis.data.length === 0">
            {{'statistics.exportToPdf' | translate}}
        </button>
    </div>
    <div class="loading-spin">
        <nz-spin *ngIf="loadingPdf" nzTip="{{'statistics.pdfFileGenerating' | translate}}..."></nz-spin>
    </div>
    <nz-tabset *ngIf="loading;else loading">
        <nz-tab [nzTitle]="titleTemplateChart">
            <ng-template #titleTemplateChart>
                <span nz-icon nzType="pie-chart"></span>
                {{'statistics.chart' | translate}}
            </ng-template>
            <div *ngIf="boxSizeAnalisis && boxSizeAnalisis.data.length > 0; else noData" id="boxSizeChartDiv" style="width: 100%; height: 400px"></div>
        </nz-tab>
        <nz-tab [nzTitle]="titleTemplateTable">
            <ng-template #titleTemplateTable>
              <span nz-icon nzType="table"></span>
              {{'statistics.table' | translate}}
            </ng-template>
            <nz-table [nzData]="boxSizeAnalisis.data" [nzTitle]="tableTitle" nzShowPagination="false">
                <thead>
                    <tr>
                        <th>{{'statistics.boxSize' | translate}}</th>
                        <th>{{'statistics.rateOfUsage' | translate}}</th>
                        <th>{{'statistics.operationsNumber' | translate}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of boxSizeAnalisis.data">
                        <td>{{item.key}}</td>
                        <td>{{getUsageRate(item.key)}}</td>
                        <td>{{item.value}}</td>
                    </tr>
                </tbody>
            </nz-table>
          </nz-tab>
    </nz-tabset>
</nz-card>

<ng-template #title>
	<h5 class="title text-truncate">{{'statistics.sizeBoxUsedAnalysis' | translate | uppercase}}</h5>
    <span class="text-muted subtitle">{{'statistics.sizeBoxUsedAnalysisSubtitle' | translate}}</span>
</ng-template>

<ng-template #tableTitle>
    <div class="d-flex justify-content-between align-items-center">
        <h6>{{'statistics.totalOperationsRecorded' | translate}} {{boxSizeAnalisis.operations}}</h6>
        <button nz-button nzType="default" (click)="exportToCsv()">{{'statistics.exportToCsv' | translate}}</button>
    </div>
</ng-template>

<ng-template #loading>
    <div class="full-page-loader">
        <nz-spin nzTip="{{'general.loaderMessage' | translate}}..."></nz-spin>
    </div>
</ng-template>

<ng-template #noData>
    <nz-empty nzNotFoundImage="simple"></nz-empty>
</ng-template>