import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { JsonConvert } from "json2typescript";
import { Observable } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { Box, BoxListData } from "../../models/box";
import { LockerConnectivityStatus, LockerConnectivityStatusData } from "../../models/locker-status";
import { LockerUser, LockerUserArrayData } from "../../models/locker-user";
import { Overdue, OverdueArrayData } from "../../models/overdue";
import { Paging } from "../../models/paged-data";
import { Problem, ProblemArrayData } from "../../models/problem";
import { PlatformUsersList, PlatformUsersListData } from "../../models/platform-user";
import { WidgetType, WidgetTypeData } from "../../models/widget-type";
import { HttpUtilsService } from "../http-utils.service";
import { MonitorLockerList, MonitorLockerListData } from "../../models/locker/monitor-locker";
import { BoolResp, StringListResp } from "../../models/primitive-resp";
import { Notification } from "../../models/notification";
import { LogList, LogListResp } from "../../models/log";

@Injectable({
    providedIn: 'root'
})
export class LockerService {

    readonly endpoint: string = environment.baseUrl;

    constructor(private http: HttpClient, private httpUtils: HttpUtilsService) { }

    /** Get monitor locker list
     * @param {Paging | null} paging
    */
    getLockers(paging?: Paging): Observable<MonitorLockerList> {
        try {
            return this.httpUtils.getHTTPHeadersAsync().pipe(
                mergeMap(httpHeaders => {
                    let url: string = paging ? `${this.endpoint}/lockers${paging.getQs()}` : `${this.endpoint}/lockers`;
                    return this.http.get(url, { headers: httpHeaders })
                        .pipe(
                            map((res: any) => {
                                const jsonConvert: JsonConvert = new JsonConvert();
                                const result: MonitorLockerListData = jsonConvert.deserializeObject(res, MonitorLockerListData);
                                if (result.success) {
                                    return result.data;
                                } else {
                                    this.httpUtils.handleError(result);
                                    return new MonitorLockerList();
                                }
                            }, catchError(err => {
                                if (err.status === 401) { // Unauthorized
                                    this.httpUtils.logout();
                                }
                                throw err;
                            }))
                        );
                })
            );
        } catch (error) {
            throw error;
        }
    }

    /** Get locker math
     * @param lockerUuid
    */
    getLockerMath(lockerUuid: string): Observable<Array<Box>> {
        try {
            return this.httpUtils.getHTTPHeadersAsync().pipe(
                mergeMap(httpHeaders => {
                    return this.http.get(`${this.endpoint}/lockers/${lockerUuid}/widgets/MATH`, { headers: httpHeaders })
                        .pipe(
                            map((res: any) => {
                                const jsonConvert: JsonConvert = new JsonConvert();
                                const result: BoxListData = jsonConvert.deserializeObject(res, BoxListData);
                                if (result.success) {
                                    return result.data;
                                } else {
                                    this.httpUtils.handleError(result);
                                    return [];
                                }
                            }, catchError(err => {
                                if (err.status === 401) { // Unauthorized
                                    this.httpUtils.logout();
                                }
                                throw err;
                            }))
                        );
                })
            );
        } catch (error) {
            throw error;
        }
    }

    /** Get box with overdue list
     * @param lockerUuid
    */
    getLockerOverdue(lockerUuid: string): Observable<Array<Overdue>> {
        try {
            return this.httpUtils.getHTTPHeadersAsync().pipe(
                mergeMap(httpHeaders => {
                    return this.http.get(`${this.endpoint}/lockers/${lockerUuid}/widgets/OVERDUE`, {headers: httpHeaders})
                    .pipe(
                        map((res: any) => {
                            const jsonConvert: JsonConvert = new JsonConvert();
                            const result: OverdueArrayData = jsonConvert.deserializeObject(res, OverdueArrayData);
                            if (result.success) {
                                return result.data;
                            } else {
                                this.httpUtils.handleError(result);
                                return [];
                            }
                        }, catchError(err => {
							if (err.status === 401) { // Unauthorized
								this.httpUtils.logout();
							}
							throw err;
                        }))
                    );
                })
            );           
        } catch (error) {
            throw error;
        }
    }

    /** Get box with problem list
     * @param lockerUuid
    */
    getLockerProblems(lockerUuid: string): Observable<Array<Problem>> {
        try {   
            return this.httpUtils.getHTTPHeadersAsync().pipe(
                mergeMap(httpHeaders => {
                    return this.http.get(`${this.endpoint}/lockers/${lockerUuid}/widgets/PROBLEMS`, {headers: httpHeaders})
                    .pipe(
                        map((res: any) => {
                            const jsonConvert: JsonConvert = new JsonConvert();
                            const result: ProblemArrayData = jsonConvert.deserializeObject(res, ProblemArrayData);
                            if (result.success) {
                                return result.data;
                            } else {
                                this.httpUtils.handleError(result);
                                return [];
                            }
                        }, catchError(err => {
							if (err.status === 401) { // Unauthorized
								this.httpUtils.logout();
							}
							throw err;
                        }))
                    );
                })
            );           
        } catch (error) {
            throw error;
        }
    }

    /** Get user's log list
     * @param userUuid
     * @param lockerUuid
    */
    getLockerUserLogs(lockerUuid: string, userUuid: string, paging: Paging): Observable<LogList> {
        let baseUrl: string = `${this.endpoint}/lockers/${lockerUuid}/widgets/LOGS/${userUuid}`; 
        try {
            return this.httpUtils.getHTTPHeadersAsync().pipe(
                mergeMap(httpHeaders => {
                    let url: string = `${baseUrl}${paging.getOffsetAndLimitQs()}`;
                    return this.http.get(url, {headers: httpHeaders})
                    .pipe(
                        map((res: any) => {
                            const jsonConvert: JsonConvert = new JsonConvert();
                            const result: LogListResp = jsonConvert.deserializeObject(res, LogListResp);
                            if (result.success) {
                                return result.data;
                            } else {
                                this.httpUtils.handleError(result);
                                return new LogList();
                            }
                        }, catchError(err => {
							if (err.status === 401) { // Unauthorized
								this.httpUtils.logout();
							}
							throw err;
                        }))
                    );
                })
            );           
        } catch (error) {
            throw error;
        }
    }

    /** Get the list of users associated with the locker
     * @param lockerUuid
    */
    getLockerUsers(lockerUuid: string): Observable<PlatformUsersList> {
        try {
            return this.httpUtils.getHTTPHeadersAsync().pipe(
                mergeMap(httpHeaders => {
                    return this.http.get(`${this.endpoint}/lockers/${lockerUuid}/users`, { headers: httpHeaders })
                        .pipe(
                            map((res: any) => {
                                const jsonConvert: JsonConvert = new JsonConvert();
                                const result: PlatformUsersListData = jsonConvert.deserializeObject(res, PlatformUsersListData);
                                if (result.success) {
                                    return result.data;
                                } else {
                                    this.httpUtils.handleError(result);
                                    return new PlatformUsersList();
                                }
                            }, catchError(err => {
                                if (err.status === 401) { // Unauthorized
                                    this.httpUtils.logout();
                                }
                                throw err;
                            }))
                        );
                })
            );
        } catch (error) {
            throw error;
        }
    }

    /** 
     * Get the list of users who have operations on the locker
     * @param lockerUuid
    */
    getLockerUsersActions(lockerUuid: string): Observable<Array<LockerUser>> {
        try {          
            return this.httpUtils.getHTTPHeadersAsync().pipe(
                mergeMap(httpHeaders => {
                    return this.http.get(`${this.endpoint}/lockers/${lockerUuid}/widgets/USERS`, {headers: httpHeaders})
                    .pipe(
                        map((res: any) => {
                            const jsonConvert: JsonConvert = new JsonConvert();
                            const result: LockerUserArrayData = jsonConvert.deserializeObject(res, LockerUserArrayData);
                            if (result.success) {
                                return result.data;
                            } else {
                                this.httpUtils.handleError(result);
                                return [];
                            }
                        }, catchError(err => {
							if (err.status === 401) { // Unauthorized
								this.httpUtils.logout();
							}
							throw err;
                        }))
                    );
                })
            );           
        } catch (error) {
            throw error;
        }
    }

    /** Get locker connectivity status
     * @param lockerUuid
    */
    getLockerConnectivityStatus(lockerUuid: string): Observable<LockerConnectivityStatus> {
        try {
            return this.httpUtils.getHTTPHeadersAsync().pipe(
                mergeMap(httpHeaders => {
                    return this.http.get(`${this.endpoint}/lockers/${lockerUuid}/widgets/CONNECTIVITY`, {headers: httpHeaders})
                    .pipe(
                        map((res: any) => {
                            const jsonConvert: JsonConvert = new JsonConvert();
                            const result: LockerConnectivityStatusData = jsonConvert.deserializeObject(res, LockerConnectivityStatusData);
                            if (result.success) {
                                return result.data;
                            } else {
                                this.httpUtils.handleError(result);
                                return new LockerConnectivityStatus();
                            }
                        }, catchError(err => {
							if (err.status === 401) { // Unauthorized
								this.httpUtils.logout();
							}
							throw err;
                        }))
                    );
                })
            );           
        } catch (error) {
            throw error;
        }
    }

    /** Send a overdue notification to user or concierge or admin or service
     * @param lockerUuid
     * @param overdueNotification
    */
    notifyOverdue(lockerUuid: string, overdueNotification: Notification): Observable<boolean> {
        try {
            return this.httpUtils.getHTTPHeadersAsync().pipe(
                mergeMap(httpHeaders => {
                    return this.http.post(`${environment.baseUrl}/lockers/${lockerUuid}/notifications/OVERDUE`,
                        overdueNotification,
                        { headers: httpHeaders })
                        .pipe(
                            map((res: any) => {
                                const jsonConvert: JsonConvert = new JsonConvert();
                                const result: BoolResp = jsonConvert.deserializeObject(res, BoolResp);
                                if (result.success) {
                                    return result.data;
                                } else {
                                    this.httpUtils.handleError(res);
                                    return false;
                                }
                            }, catchError(err => {
                                if (err.status === 401) { // Unauthorized
                                    this.httpUtils.logout();
                                }
                                throw err;
                            }))
                        );
                }), catchError(err => {
                    if (err.status === 401) { // Unauthorized
                        this.httpUtils.logout();
                    }
                    throw err;
                })
            );
        } catch (error) {
            throw error;
        }
    }

    /** Send a problem notification to concierge or admin or service
     * @param lockerUuid
     * @param problemNotification
    */
    notifyProblem(lockerUuid: string, problemNotification: Notification): Observable<any> {
        try {
            return this.httpUtils.getHTTPHeadersAsync().pipe(
                mergeMap(httpHeaders => {
                    return this.http.post(`${environment.baseUrl}/lockers/${lockerUuid}/notifications/PROBLEMS`,
                        problemNotification,
                        { headers: httpHeaders })
                        .pipe(
                            map((res: any) => {
                                const jsonConvert: JsonConvert = new JsonConvert();
                                const result: BoolResp = jsonConvert.deserializeObject(res, BoolResp);
                                if (result.success) {
                                    return result.data;
                                } else {
                                    this.httpUtils.handleError(res);
                                    return false;
                                }
                            }, catchError(err => {
                                if (err.status === 401) { // Unauthorized
                                    this.httpUtils.logout();
                                }
                                throw err;
                            }))
                        );
                }), catchError(err => {
                    if (err.status === 401) { // Unauthorized
                        this.httpUtils.logout();
                    }
                    throw err;
                })
            );
        } catch (error) {
            throw error;
        }
    }

    /** Send an email with a service pin to a list of email addresses 
     * @param lockerUuid
     * @param servicePin
     * @param emails
    */
    sendServicePin(lockerUuid: string, servicePin: string, emails: Array<string>): Observable<any> {
        let body = { service_pin: servicePin, emails: emails }
        try {
            return this.httpUtils.getHTTPHeadersAsync().pipe(
                mergeMap(httpHeaders => {
                    return this.http.post(`${environment.baseUrl}/lockers/${lockerUuid}/notifications/CONTROL`,
                        body,
                        { headers: httpHeaders })
                        .pipe(
                            map((res: any) => {
                                if (res.success) {
                                    return res.data;
                                } else {
                                    this.httpUtils.handleError(res);
                                    return;
                                }
                            }, catchError(err => {
                                if (err.status === 401) { // Unauthorized
                                    this.httpUtils.logout();
                                }
                                throw err;
                            }))
                        );
                }), catchError(err => {
                    if (err.status === 401) { // Unauthorized
                        this.httpUtils.logout();
                    }
                    throw err;
                })
            );
        } catch (error) {
            throw error;
        }
    }

    /** Get widget list availabled for the locker
     * @param lockerUuid
    */
    getAvailableWidgets(lockerUuid: string): Observable<Array<WidgetType>> {
        try {
            return this.httpUtils.getHTTPHeadersAsync().pipe(
                mergeMap(httpHeaders => {
                    const url: string = `${this.endpoint}/lockers/${lockerUuid}/available-widgets`;
                    return this.http.get(url, { headers: httpHeaders })
                        .pipe(
                            map((res: any) => {
                                const jsonConvert: JsonConvert = new JsonConvert();
                                const result: WidgetTypeData = jsonConvert.deserializeObject(res, WidgetTypeData);
                                if (result.success) {
                                    return result.data;
                                } else {
                                    this.httpUtils.handleError(result);
                                    return [];
                                }
                            }, catchError(error => {
                                throw error;
                            }))
                        );
                })
            );
        } catch (error) {
            throw error;
        }
    }
}