<app-spinner></app-spinner>
<nz-layout class="app-layout">
    <nz-sider class="menu-sidebar" nzCollapsible nzWidth="256px" nzBreakpoint="md" [(nzCollapsed)]="isCollapsed"
        [nzTrigger]="null">
		<div class="sidebar-logo">
			<ng-container *ngIf="isCollapsed">
				<img src="./assets/images/logo-mini-md.png" class="isCollapsed" alt="logo">
			</ng-container>
			<ng-container *ngIf="!isCollapsed">
				<img src="./assets/images/logo-big.png" alt="logo">
			</ng-container>
		</div>
		<span *ngIf="!isCollapsed" class="h6 fst-italic dashboard-title text-center w-100 d-block pt-2 pb-2 mt-2">
			<nz-avatar [nzSize]="60" [nzText]="getUserInitals()" [nzSrc]="getAvatar()">
			</nz-avatar>
			<p class="mb-1 mt-2 text-dark"><b>{{getUserName()}}</b></p>
			<p class="mb-1 mt-1"><small>{{'general.roles.role' | translate}}: {{(tenant && tenant.role? (('general.roles.' + tenant.role.toLowerCase()) | translate): '---') | lowercase}}</small></p>
			<p class="mb-0 text-muted"><i><small><small>({{'general.lastLogin' | translate}}: {{ getLastLogin()? (getLastLogin() | date: 'medium': '': helpers.getLanguage()): '---' }})</small></small></i></p>
		</span>
		<ul nz-menu nzMode="inline" [nzInlineCollapsed]="isCollapsed">
			<ng-container *ngTemplateOutlet="menuTpl; context: { $implicit: menuConfig, isSubMenu: false }"></ng-container>
			<ng-template #menuTpl let-menus let-isSubMenu="isSubMenu">
				<ng-container *ngIf="tenant">
					<ng-container *ngFor="let menu of menus">
						<li *ngIf="!menu.subMenu && menu.roles.includes(tenant.role)" nz-menu-item 
							[nzPaddingLeft]="(isCollapsed && isSubMenu)? 24: menu.level * 24"
							[routerLink]="[menu.link]"
							nzMatchRouter>
							<i nz-icon [nzType]="menu.icon" *ngIf="menu.icon"></i>
							<a *ngIf="!isCollapsed || (isCollapsed && isSubMenu)" [routerLink]="[menu.link]">{{menu.translate| translate}}</a>
						</li>
						<li *ngIf="menu.subMenu && menu.roles.includes(tenant.role)" nz-submenu nzOpen
							nzTitle="{{menu.translate | translate}}"
							[nzPaddingLeft]="menu.level * 24"
							[nzIcon]="menu.icon">
							<ul>
								<ng-container *ngTemplateOutlet="menuTpl; context: { $implicit: menu.subMenu, isSubMenu: true }"></ng-container>
							</ul>
						</li>
					  </ng-container>
				</ng-container>
			</ng-template>
		</ul>
    </nz-sider>
    <nz-layout>
        <nz-header>
            <div nz-row [nzGutter]="0">
                <div nz-col [nzSpan]="8">
					<span class="header-trigger" (click)="isCollapsed = !isCollapsed">
						<i class="trigger" nz-icon [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"></i>
					</span>
				</div>
                <div nz-col [nzSpan]="16">
					<ul nz-menu nzMode="horizontal" [nzSelectable]="false" class="text-end">
                        <li nz-menu-item>
							<app-tenant-selector></app-tenant-selector>
                        </li>
                        <li nz-menu-item>
                            <app-language-selector></app-language-selector>
                        </li>
                        <li nz-menu-item>
                            <a (click)="logout()" nzType="primary"><i nz-icon nzType="logout" nzTheme="outline"></i> Logout</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nz-header>
        <nz-content (scroll)="handleScroll($event)" #routerOutletContent id="router-outlet-content">
            <div class="inner-content-transparent">
                <router-outlet></router-outlet>
            </div>
        </nz-content>
		<app-version></app-version>
    </nz-layout>
</nz-layout>
