<nz-card widgetLocker 
    [nzTitle]="title" 
    [nzExtra]="extraTemplate"
    class="h-100">
    <div id="chartdiv" style="width: 100%; height: 500px"></div>
</nz-card>

<ng-template #title>
	<h5 class="title text-truncate">{{'statistics.deliveriesAndReturns' | translate | uppercase}}</h5>
</ng-template>

<ng-template #extraTemplate>
    <nz-select [(ngModel)]="selectedLocker" (ngModelChange)="onSelectChange()" [nzPlaceHolder]="'home.selectLocker' | translate">
        <nz-option *ngFor="let locker of lockers.data" [nzValue]="locker.uuid" [nzLabel]="locker.name"></nz-option>
    </nz-select>
</ng-template>