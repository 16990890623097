import { JsonConvert, JsonConverter, JsonCustomConvert, JsonObject, JsonProperty } from 'json2typescript';

@JsonConverter
export class BoardArrayConverter implements JsonCustomConvert<Array<Board>> {
	serialize() { }

	deserialize(data: any): Array<Board> {
		const jsonConvert: JsonConvert = new JsonConvert();
		const response = jsonConvert.deserializeArray(data, Board);
		return response;
	}
}


@JsonObject('Board')
export class Board {
	@JsonProperty('address', Number, true)
	address: number = 0;

	@JsonProperty('numberOfBoxManaged', Number, true)
	numberOfBoxManaged: number = 0;

	@JsonProperty('boxNumberOffset', Number, true)
	boxNumberOffset: number = 0;

	dto() {
		return {
			address: this.address,
			numberOfBoxManaged: this.numberOfBoxManaged,
			boxNumberOffset: this.boxNumberOffset,
		}
	}
}
