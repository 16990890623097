import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Helpers } from '../helpers';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
	/** Selected language upadted */
	language$: BehaviorSubject<string> = new BehaviorSubject<string>("");

	constructor() { 
		// Get current language
		const language: string = Helpers.getLanguage();
		this.update(language);
	}

	/** Emit new language update event */
	update(language: string) {
		/* Creating a custom event and then dispatching it. */
		const event = new CustomEvent('langSelected', { detail: { lang: language } });
		window.dispatchEvent(event);
		this.language$.next(language);
	}
}
