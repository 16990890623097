import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { JsonConvert } from "json2typescript";
import * as moment from "moment";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { Account } from "../models/account";
import { Role } from "../models/role";
import { Tenant } from "../models/tenant";
import { Location } from "../models/location";
import { DateAnalysisObject } from "../models/date-analysis-object";
import { AnalysisObject } from "../models/analysis-object";

export class Helpers {
	/** Email regular expression */
	static readonly emailRegExp: RegExp = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
	static readonly urlRegEx = /^(http|https):\/\/[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/i;
	static readonly allLocations: string = 'ALL_LOCATION';
	static readonly allLockers: string = 'ALL_LOCKERS';

	/**
	 * Remove all the items in the local storage
	 */
	static cleanStorage() {
		const config: any = this.getConfig();
		delete config['fu'];
		delete config['session-expire'];
		delete config['identity'];
		delete config['tenants'];
		delete config['tenant'];
		this.setConfig(config);
	}

	/** Save configuration to local storage */
	static setConfig(config: any) {
		localStorage.setItem('sl-operations-config', JSON.stringify(config));
	}

	/** Get configuration object from local storage */
	static getConfig(): any {
		return localStorage.getItem('sl-operations-config')? JSON.parse(localStorage.getItem('sl-operations-config')!): {};
	}

	/** Save firebase user in local storage  */
	static setFirebaseUser(fu: any) {
		const config: any = this.getConfig();
		config['fu'] = fu;
		this.setConfig(config);
	}

	/** Get firebase user from local storage  */
	static getFirebaseUser() {
		const config: any = this.getConfig();
		return config['fu'] || null;
	}

	/** Save session expire in local storage  */
	static setSessionExpiration() {
		const config: any = this.getConfig();
		const expire: any = moment().add(12, 'hours').unix();
		config['session-expire'] = expire;
		this.setConfig(config);
	}

	/** Get session expire from local storage  */
	static getSessionExpire() {
		const config: any = this.getConfig();
		if (config['session-expire']) {
			return moment.unix(Number(config['session-expire']));
		} else {
			return moment().subtract(10, 'years');
		}
	}

	/** Save identity in local storage  */
	static setIdenity(identity: Account | null) {
		const config: any = this.getConfig();
		config['identity'] = identity? identity?.dto(): null;
		this.setConfig(config);
	}

	/** Get identity from local storage  */
	static getIdentity() {
		const config: any = this.getConfig();
		if (config && config['identity']) {
			const jsonConvert: JsonConvert = new JsonConvert();
			const identity: Account = jsonConvert.deserializeObject(config['identity'], Account);
			return identity || null;
		} else {
			return null;
		}
	}

	/** Save tenants list in local storage */
	static setTenants(tenants: Array<Tenant>) {
		const config: any = this.getConfig();
		config['tenants'] = tenants.length > 0? tenants.map(t => t.dto()): [];
		this.setConfig(config);
	}

	/** Get tenants list from local storage */
	static getTenantsFromLocalStorage(): Array<Tenant> {
		const identity: Account | null = this.getIdentity();
		if (identity && identity.tenants.length > 0) {
			return identity.tenants;
		} else {
			return [];
		}
	}

	/** Get tenant compacts list from local storage */
	static getTenantCompactsFromLocalStorage(): Array<Tenant> {
		const config: any = this.getConfig();
		if (config && config['tenants']) {
			const jsonConvert: JsonConvert = new JsonConvert();
			const tenants: Array<Tenant> = jsonConvert.deserializeArray(config['tenants'], Tenant);
			if (tenants && tenants.length > 0) {
				return tenants;
			} else {
				return [];
			}
		} else {
			return [];
		}
	}

	/** Save tenant in local storage */
	static setTenant(tenant: Tenant | null) {
		const config: any = this.getConfig();
		config['tenant'] = tenant? tenant.dto(): null;
		this.setConfig(config);
	}

	/** Get tenant from local storage */
	static getTenantFromLocalStorage(): Tenant | null {
		const config: any = this.getConfig();
		if (config && config['tenant']) {
			const jsonConvert: JsonConvert = new JsonConvert();
			const tenant: Tenant = jsonConvert.deserializeObject(config['tenant'], Tenant);
			if (tenant) {
				if (tenant.name) {
					return tenant;
				} else {
					return null;
				}
			} else {
				return null;
			}
		} else {
			return null;
		}
	}

	/** Save current language in local storage */
	static setLanguage(userLang: string) {
		return localStorage.setItem('language', userLang);
	}

	/** Get current language */
	static getLanguage() {
		return localStorage.getItem('language') || navigator.language.substring(0, 2).toLowerCase();
	}

	/** Save email in local storage in a list of emails */ 
	static setEmail(email?: string | null) {
		if(email){
			const emails: Array<string> = this.getEmails();
			if(emails.indexOf(email) == -1){
				emails.push(email);
			}
			localStorage.setItem('emails', JSON.stringify(emails));
		}
	}

	/** Get emails from local storage */
	static getEmails(): Array<string> {
		const emails: Array<string> = localStorage.getItem('emails')? JSON.parse(localStorage.getItem('emails')!): [];
		return emails;
	}

	/**
	 * Check if the current tenant has the passed roles
	 * @param roles 
	 */
	 static checkRole(roles: Array<Role>) {
		if (!this.isLogin()) {
			if (!this.isGod()) {
				const tenant: Tenant | null = this.getTenantFromLocalStorage();
				return roles.some(pr => pr == tenant?.role);
			} else {
				return true;
			}
		} else {
			return true;
		}
	}

	/** Check if current dashboard user has god role */
	static isGod(passedTenant?: Tenant) {
		if(passedTenant){
			return passedTenant.role == Role.GOD;
		}else {
			const tenant: Tenant | null = this.getTenantFromLocalStorage();
			return tenant?.role == Role.GOD;
		}
	}

	/** Check if current dashboard user has god role or super-admin role or admin role */
	static isAdminGodOrSuperAdmin(tenant?: Tenant): boolean{
		if(tenant){
			return tenant.role == Role.GOD || tenant.role == Role.ADMIN || tenant.role == Role.SUPER_ADMIN;
		}else{
			return false;
		}
	}

	/**
	 * Build filter querystring
	 * @param filter Array of dates
	 * @returns 
	 */
	static buildQs(filter: Array<Date>) {
		if (filter.length === 2) {
			const start = moment(filter[0].getTime()).format('YYYY-MM-DD');
			const end = moment(filter[1].getTime()).format('YYYY-MM-DD');
			return `since=${start}&until=${end}`;
		} else {
			return '';
		}
	}

	/** Check if login is in progress */
	static isLogin() {
		const isLogin = localStorage.getItem('isLogin');
		if (isLogin) {
			 return true;
		} else {
			return false;
		}
	}

	/** Check if it's logout process */
	static isLogout() {
		const isLogout = localStorage.getItem('isLogout');
		return isLogout && isLogout === "1";
	}

	static get datePickerDateFormat(): string {
		if (this.getLanguage() === 'it') {
			return 'dd-MM-YYYY';
		} else if (this.getLanguage() === 'en'){
			return 'MM-dd-YYYY';
		} else {
			return 'YYYY-MM-dd';
		}
	}

	/** Check if tenants exists */
	static checkTenants(): boolean {
		if (Helpers.getTenantCompactsFromLocalStorage().length > 0 && Helpers.getTenantFromLocalStorage()) {
			return true;
		} else {
			return false;
		}
	}

	/** Show error notification */
	static errorNotification(notification: NzNotificationService, translate: TranslateService, message: string): void{
		notification.create(
			'error',
			translate.instant('general.error'),
			translate.instant(message)
		);
	}

	/** Show success notification */
	static successNotification(notification: NzNotificationService, translate: TranslateService, message: string): void{
		notification.create(
			'success',
			'',
			translate.instant(message)
		);
	}

	/** Navigate to unauthorized */
	static goToUnauthorized(router: Router): void {
		router.navigateByUrl('/unauthorized');
	}

	static validateUrl(url: string) {
        const isValid: boolean = this.urlRegEx.test(url.trim());
        return isValid;
    }

	/** Return address string to show to user */
	static showAddress(location: Location): string {
		let addressToShow: string;
		let addressDetails: string[] = [
		  location.address.address, 
		  location.address.zip_code, 
		  location.address.city, 
		  location.address.province, 
		  location.address.region, 
		  location.address.country
		];
		addressToShow = addressDetails.filter(item => item != null && item != '').join(', ');
		return addressToShow;
	}

	/** Prevent unsescape warning */
	public static get unescape() {
		return window.decodeURIComponent || window.unescape;
	}

	/**
	 * It takes a date range and an array of objects with a date and a value, and returns an array of
	 * objects with a date and a value, where the date range is normalized to the date range passed in
	 * @param {string} since - the start date of the range
	 * @param {string} until - The end date of the range
	 * @param data - Array<DateAnalysisObject>
	 * @returns An array of DateAnalysisObjects
	 */
	public static normalizeData(since: string, until: string, data: Array<DateAnalysisObject>): Array<DateAnalysisObject> {
		const normalizedDate: Array<DateAnalysisObject> = [];
		let i = 0;
		const days = getDatesBetween(since, until);
		for(let day of days) {
			let value = data.find(d => moment(d.key).format('YYYY-MM-DD') === day);
			if(value) {
				normalizedDate.push(value);
			} else {
				normalizedDate.push({ key: moment(day).valueOf(), value: 0 });
			}
		}
		return normalizedDate;

	}

	public static normalizeDataForChart(startHour: number, endHour: number, data: Array<AnalysisObject>): Array<AnalysisObject> {
		const normalizedDate: Array<AnalysisObject> = [];
		for(let i = startHour; i <= endHour; i++) {
			if(i < startHour) {
				normalizedDate.push({ key: i.toString(), value: 0 });
			} else {
				let value = data.find(d => parseInt(d.key) === i);
				if(value) {
					normalizedDate.push(value);
				} else {
					normalizedDate.push({ key: i.toString(), value: 0 });
				}
			}
		}
		return normalizedDate;
	}
}

/**
 * It takes two dates in the format of YYYY-MM-DD and returns an array of dates between the two dates
 * @param {string} startDate - The start date of the range.
 * @param {string} endDate - The end date of the date range.
 * @returns An array of strings in the format of YYYY-MM-DD
 */
function getDatesBetween(startDate: string, endDate: string): Array<string> {
	let start = moment(startDate, 'YYYY-MM-DD');
		let end = moment(endDate, 'YYYY-MM-DD');
		let dates = [];
		while(start.isSameOrBefore(end)) {
			dates.push(start.format('YYYY-MM-DD'));
			start.add(1, 'days');
		}
		return dates;
}

