import { JsonConverter, JsonCustomConvert, JsonConvert, JsonObject, JsonProperty } from "json2typescript";
import { Resp } from "./resp";
import { Tenant, TenantArrayConverter } from "./tenant";
import { DashboardUser, DashboardUserConverter } from "./dashboard-user";

@JsonConverter
export class AccountConverter implements JsonCustomConvert<Account> {
    serialize() {}
    deserialize(data: any): Account {
        const jsonConvert: JsonConvert = new JsonConvert();
        return jsonConvert.deserializeObject(data, Account);
    }
}

@JsonObject("Account")
export class Account {
    @JsonProperty("user", DashboardUserConverter, true)
	user: DashboardUser = new DashboardUser();
    @JsonProperty("tenants", TenantArrayConverter, true)
	tenants: Array<Tenant> = [];

    dto() {
        return {
            user: this.user.dto(),
            tenants: this.tenants.map(t => t.dto())
        }
    }
}

@JsonObject("AccountData")
export class AccountData extends Resp {
    @JsonProperty("data", AccountConverter, true)
	data: Account = new Account();
}