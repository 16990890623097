<div class="login-container">
	<div class="row">
		<div class="d-none d-lg-block col-lg-7 left-bg"></div>
		<div class="col-lg-5">
			<div class="form-signin">
				<img class="mb-4" src="./assets/images/logo-big.png" alt="" width="260"><br />
				<span class="h5 auth-title">Smart Locker Dashboard</span><br /><br />
				<router-outlet></router-outlet>
			</div>
		</div>
	</div>
	<app-version class="login-footer"></app-version>
</div>