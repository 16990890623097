import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import * as moment from 'moment';
import { Observable, of } from 'rxjs';
import { Helpers } from '../helpers';
import { environment } from 'src/environments/environment';
import { Role } from '../models/role';
import { Auth, getAuth, onAuthStateChanged } from 'firebase/auth';

@Injectable({
	providedIn: 'root'
})
export class AuthGuard implements CanActivate {

	constructor(private router: Router) {

	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		try {
			const user = Helpers.getFirebaseUser();
			if (user && user.stsTokenManager) {
				const expire = moment(user.stsTokenManager.expirationTime);
				const now = moment();

				// Check if token is expired
				if (expire.isAfter(now)) {
					this.checkPermission(route);
					return of(true);
				} else {
					// If session is expired since 12 hours, refresh token and save it in localstorage
					const sessionExpire = Helpers.getSessionExpire();;
					if (sessionExpire.isAfter(now)) {
						return new Observable<boolean>((observer) => {
							const auth: Auth = getAuth();
							onAuthStateChanged(auth, user => {
								if (user) {
									// Update user
									user.reload().then(() => {
										const newUser = auth.currentUser;
										if (newUser) {
											Helpers.setFirebaseUser(newUser.toJSON());
											this.checkPermission(route);
											return observer.next(true);
										} else {
											return observer.next(false);
										}
									});
									this.checkPermission(route);
									return observer.next(true)
								} else {
									return this.logout();
								}
							});
						});
					} else { // Session is expired more than 12 hours ago, logout
						return this.logout();
					}
				}
			} else { // It means user logged out
				return this.logout();
			}
		} catch {
			this.checkPermission(route);
			return of(true);
		}
	}

	private checkPermission(route: ActivatedRouteSnapshot) {
		const pagePermettedRoles: Array<Role> = route.data.permittedRoles;
		if (pagePermettedRoles) {
			const permission: boolean = Helpers.checkRole(pagePermettedRoles) || false;
			if (!permission) {
				this.router.navigateByUrl('/unauthorized');
			}
		}
	}

	private logout() {
		return new Observable<boolean>((observer) => {
			Helpers.cleanStorage();
			localStorage.setItem('isLogout', "1");
			const auth: Auth = getAuth();
			auth.signOut().then(() => {
				localStorage.removeItem('isLogout');
				this.router.navigate([environment.firebase.params.loginPath]);
				return observer.next(false);
			});
		});
	}

}
