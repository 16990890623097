import { Component, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/core/services/loader.service';

@Component({
	selector: 'dashboard-page-not-found',
	templateUrl: './page-not-found.component.html',
	styleUrls: ['./page-not-found.component.less']
})
export class PageNotFoundComponent implements OnInit {

	constructor(private loaderService: LoaderService) { }

	ngOnInit(): void {
		this.loaderService.update(false);
	}

}
