import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Helpers } from '../helpers';
import { Tenant } from '../models/tenant';

@Injectable({
	providedIn: 'root'
})
export class TenantService {
	/** Tenants list updated */
	tenants$: BehaviorSubject<Array<Tenant>> = new BehaviorSubject<Array<Tenant>>([]);
	/** Selected tenant updated */
	tenant$: BehaviorSubject<Tenant | null> = new BehaviorSubject<Tenant | null>(null);

	constructor() {
		/** Get tenants list if exists in local storage */
		if (Helpers.checkTenants()) {
			const tenants: Array<Tenant> = Helpers.getTenantsFromLocalStorage();
			const tenant: Tenant | null = Helpers.getTenantFromLocalStorage();
			this.updateTenants(tenants);
			this.updateTenant(tenant);
		}
	}

	/** Save tenants list in local storage and emit new tenants update event */
	updateTenants(newTenants: Array<Tenant>) {
		Helpers.setTenants(newTenants);
		this.tenants$.next(newTenants.length > 0? newTenants: []);
	}

	/** Save tenant in local storage and emit new tenant update event */
	updateTenant(newTenant: Tenant | null) {
		Helpers.setTenant(newTenant);
		this.tenant$.next(newTenant);
	}
}
