import { JsonConvert, JsonConverter, JsonCustomConvert, JsonObject, JsonProperty } from "json2typescript";
import { Resp } from "../resp";
import { AdminLockerBase } from "./admin-locker-base";
import { Configuration, ConfigurationConverter } from "./configuration";


@JsonConverter
export class AdminLockerConverter implements JsonCustomConvert<AdminLocker> {
    serialize() {}
    deserialize(data: any): AdminLocker {
        const jsonConvert: JsonConvert = new JsonConvert();
        return jsonConvert.deserializeObject(data, AdminLocker);
    }
}

@JsonConverter
export class AdminLockerArrayConverter implements JsonCustomConvert<Array<AdminLocker>> {
    serialize() { }
    deserialize(data: any): Array<AdminLocker> {
        const jsonConvert: JsonConvert = new JsonConvert();
        return jsonConvert.deserializeArray(data, AdminLocker);
    }
}

@JsonObject("AdminLocker")
export class AdminLocker extends AdminLockerBase {
    @JsonProperty("location_uuid", String, true)
	location_uuid: string = "";

    @JsonProperty("image", String, true)
	image: string = "";

    @JsonProperty("configuration", ConfigurationConverter, true)
	configuration: Configuration = new Configuration();

    /** Return right body for the creation and updating requests */
    dto() {
        return {
            vendor_uuid: this.vendor_uuid,
            vendor_locker_id: this.vendor_locker_id,
            location_uuid: this.location_uuid,
            name: this.name,
            description: this.description,
            image: this.image,
            metadata: this.getMetadataDto(),
            configuration: this.configuration.dto()
        }
    }
}

@JsonConverter
export class AdminLockerListConverter implements JsonCustomConvert<AdminLockerList> {
    serialize() {}
    deserialize(data: any): AdminLockerList {
        const jsonConvert: JsonConvert = new JsonConvert();
        return jsonConvert.deserializeObject(data, AdminLockerList);
    }
}

@JsonObject("AdminLockerData")
export class AdminLockerData extends Resp{
    @JsonProperty("data", AdminLockerConverter, true)
	data: AdminLocker = new AdminLocker();
}

@JsonObject("AdminLockerList")
export class AdminLockerList {
    @JsonProperty("count", Number, true)
	count: number = 0;
    @JsonProperty("data", AdminLockerArrayConverter, true)
	data: Array<AdminLocker> = [];
}

@JsonObject("AdminLockerListData")
export class AdminLockerListData extends Resp{
    @JsonProperty("data", AdminLockerListConverter, true)
	data: AdminLockerList = new AdminLockerList();
}