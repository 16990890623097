import { Component, OnInit } from '@angular/core';
import { Helpers } from 'src/app/core/helpers';
import { Tenant } from 'src/app/core/models/tenant';
import { TenantService } from 'src/app/core/services/tenant.service';

@Component({
  selector: 'app-tenant-selector',
  templateUrl: './tenant-selector.component.html',
  styleUrls: ['./tenant-selector.component.less']
})
export class TenantSelectorComponent implements OnInit {

	constructor(public tenantsService: TenantService) { }

	ngOnInit(): void {
		/** Get tenants list if exists in local storage */
		if (!Helpers.checkTenants()) {
			const tenants: Array<Tenant> = Helpers.getTenantsFromLocalStorage();
			this.tenantsService.updateTenants(tenants);
			if (tenants.length > 0) {
				this.tenantsService.updateTenant(tenants[0]);
			}
		} else {
			this.tenantsService.updateTenants(Helpers.getTenantsFromLocalStorage());
			this.tenantsService.updateTenant(Helpers.getTenantFromLocalStorage());
		}
	}

	/** A tenant is selected */
	changeTenant(tenant: Tenant) {
		const currentTenant: Tenant | null = Helpers.getTenantFromLocalStorage();
		// If selected tenant is new, update data
		if (currentTenant && currentTenant.name !== tenant.name) {
			this.tenantsService.updateTenant(tenant);
		} 
		// commented to fix a bug (selection of the same tenant)
		// else {
		// 	this.tenantsService.updateTenant(null);
		// 	this.tenantsService.updateTenants([]);
		// }
	}

	/** Disable selection of  the already selected tenant */
	disableAlreadySelectedTenant(tenant: Tenant): boolean {
		const currentTenant: Tenant | null = Helpers.getTenantFromLocalStorage();
		if(currentTenant) {
			return currentTenant.uuid === tenant.uuid;
		}else {
			return false;
		}
	}
}
