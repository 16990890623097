export const locale = {
	lang: 'it',
	data: {
		login: {
			forgotPassword: 'Password dimenticata?',
			firstAccess: 'È il tuo primo accesso? Clicca qui',
			errors: {
				email: 'Inserire un\'email valida',
				password: 'Inserire la password'
			}
		},
		firstAccess: {
			title: 'Crea la tua password',
			email: 'Inserisci la tua email',
			description: 'Inserisci il tuo indirizzo email. Ti invieremo una mail per la creazione della tua password.',
			button: 'Procedi',
			back: 'Torna alla pagina di login',
		},
		forgotPassword: {
			title: 'Recupera la tua password',
			description: 'Inserisci il tuo indirizzo email. Ti invieremo una mail per il reset della tua password.',
			email: 'Inserisci la tua email',
			button: 'Procedi',
			back: 'Torna alla pagina di login',
		},
		authentication: {
			sign_in: 'Login',
			register: 'Registrati',
			signing_in: 'Effettua il login',
			forgot_password: 'Hai dimenticato la password?',
			registration: 'Registrazione',
			reset_password: 'Reimposta la password',
			registration_completed: 'Registrazione completata',
			emailConfirmationText: 'Ti abbiamo inviato un\'email di verifica, controlla la tua inbox e clicca sul link per confermare il tuo indirizzo e-mail.',
			emailConfirmationTitle: 'Invia un\'email di conferma',
			resetSuccess: 'Ti abbiamo inviato un\'email contenente un link per ripristinare la password. Segui le istruzioni per ripristinare la password.',
			confirm_password: 'Conferma password',
			tos: 'Approvo le Condizioni e i Termini del Servizio',
			privacy: 'Ho letto e compreso la Privacy',
			error: 'Errore',
			google_sign_in: 'Accedi con Google',
			firstAccess: 'È il tuo primo accesso? Clicca qui',
			firstAccessTitle: 'Imposta la nuova password',
			new_password: 'Inserisci la tua email',
			emailSent: 'Email inviata con successo',
			newUserSuccess: 'Ti abbiamo inviato un\'email contenente un link per impostare la password. Segui le istruzioni per creare la password.',
			errors: {
				generic: 'Si è verificato un errore, si prega di riprovare più tardi',
				user_not_found: 'L\'utente non esiste o potrebbe essere stato rimosso.',
				user_disabled: 'L\'email inserita è disabilitata.',
				invalid_email: 'L\'indirizzo email inserito non è valido',
				wrong_password: 'La password non è valida per l\'email inserita, oppure l\'account corrispondente all\'email non ha la password impostata.',
				account_exists_with_different_credential: 'Esiste già un account associato a questa email',
				popup_blocked: 'Il popup è stato bloccato dal browser, si prega di abilitare i popup per questo sito web.',
				email_already_in_use: 'Esiste un altro account associato all\'indirizzo email inserito',
				weak_password: 'La password inserita non è abbastanza complicata',
				tos: 'Devi approvare le Condizioni e i Termini del Servizio',
				privacy: 'Devi approvare la Privacy',
				email_not_verified: 'Il tuo indirizzo email non è stato ancora verificato. Clicca sul link di verifica presente nell\'email che abbiamo inviato al tuo indirizzo.',
				unauthorized: 'L\'utente non è autorizzato all\'accesso',
				missingTenants: 'Errore di configurazione dei tenant'
			}
		},
		general: {
			it: 'Italiano',
			en: 'Inglese',
			male: 'Maschio',
			female: 'Femmina',
			other: 'Altro',
			pageNotFound: 'La pagina non esiste',
			unauthorized: 'Non hai i permessi per visualizzare la pagina',
			createUserUnauthorized: 'Non hai i permessi per creare l\'utente',
			importUsersUnauthorized: 'Non hai i permessi per importare gli utenti',
			createLockerUnauthorized: 'Non hai i permessi per creare il locker',
			createLocationUnauthorized: 'Non hai i permessi per creare la location',
			updateUserUnauthorized: 'Non hai i permessi per aggiornare i dati dell\'utente',
			updateLockerUnauthorized: 'Non hai i permessi per aggiornare i dati del locker',
			updateLocationUnauthorized: 'Non hai i permessi per aggiornare i dati della location',
			deleteUserUnauthorized: 'Non hai i permessi per rimuovere l\'utente',
			deleteLockerUnauthorized: 'Non hai i permessi per rimuovere il locker',
			deleteLocationUnauthorized: 'Non hai i permessi per rimuovere la location',
			getLockerIdentifiersUnauthorized: 'Non hai i permessi per creare il QR code',
			associateUsersLockersUnauthorized: 'Non hai i permessi per associare utenti e locker',
			deleteAssociateUsersLockersUnauthorized: 'Non hai i permessi per rimuovere l\'associazione utenti/locker',
			getStatisticsUnauthorized: 'Non hai i permessi per visualizzare le statistiche',
			spinnerMessage: 'Caricamento della pagina',
			loaderMessage: 'Caricamento dei dati',
			lastLogin: 'Ultimo accesso',
			emailAddress: 'Indirizzo email',
			emailVerified: 'Email verificata',
			warning: 'Attenzione',
			error: 'Errore',
			save: 'Salva',
			cancel: 'Annulla',
			edit: 'Modifica',
			update: 'Aggiorna',
			show: 'Mostra',
			delete: 'Elimina',
			back: 'Indietro',
			confirm: 'Conferma',
			done: 'Fatto',
			add: 'Carica',
			reset: 'Reset',
			report: 'Report',
			firstname: 'Nome',
			name: 'Nome',
			middlename: 'Secondo nome',
			lastname: 'Cognome',
			gender: 'Genere',
			type: 'Tipo',
			box: 'Box',
			date: 'Data',
			startDate: 'Inizio',
			endDate: 'Fine',
			inProgress: 'In corso',
			userUuid: 'Identificativo utente',
			userEmail: 'Email utente',
			exchangeRecepientEmail: 'Email destinatario',
			otherTenantUser: 'Utente di un altro tenant',
			roles: {
				role: 'Ruolo',
				admin: 'Admin',
				super_admin: 'Super admin',
				god: 'God',
				concierge: 'Concierge',
				service: 'Service'
			},
			phoneNumber: 'Numero di telefono',
			preferredLanguage: 'Idioma preferito',
			registrationSource: "Modalità di registrazione",
			sources: {
				selfsignin: "Self Sign-In",
				admindashboard: "Censito da un amministratore",
			},
			status: {
				status: 'Stato',
				REGISTERED: 'Registrato',
				SUSPENDED: 'Sospeso',
				DELETED: 'Rimosso',
				ACTIVE: 'Attivo',
				actived: 'Attivo',
				deactivated: 'Disattivo',
				online: 'Connesso',
				offline: 'Disconnesso',
				undefined: 'Non definito',
				free: 'Libero',
				busy: 'Occupato',
				overdue: 'Scaduto',
				BLOCKED: 'Bloccato',
				reserved: 'Riservato',
				overdueDescription: 'box occupato per un tempo superiore a quello consentito dal sistema',
				blockedDescription: 'box bloccato a causa di un problema tecnico',
				reservedDescription: 'box prenotato da un utente',
			},
			assignedLockers: 'Locker assegnati',
			visibilityLockers: 'Locker visibili',
			deliveries: 'consegne',
			delivery: 'Consegna',
			returns: 'resi',
			return: 'Reso',
			selfDeposits: 'auto depositi',
			deposit: 'Auto deposito',
			exchange: 'Scambio',
			exchanges: 'scambi',
			reservations: 'prenotazioni',
			reservation: 'Prenotazione',
			delegations: 'deleghe',
			delegation: 'Delega',
			export: 'Esporta',
			exportToPdf: 'Esporta in PDF',
			notify: 'Invia notifica',
			notifyTechnician: 'Invia notifica a un tecnico',
			notifyUser: 'Invia notifica all\'utente',
			notifyConfirm: 'Confermi di voler inviare la notifica?',
			notifyService: 'Invia notifica al service',
			notifyConcierge: 'Invia notifica al concierge',
			notifyAdmin: 'Invia notifica all\'amministratore',
			notifyConfirmUser: 'Confermi di voler inviare una notifica all\'utente?',
			notifyConfirmService: 'Confermi di voler inviare una notifica al service?',
			notifyConfirmConcierge: 'Confermi di voler inviare una notifica al concierge?',
			notifyConfirmAdmin: 'Confermi di voler inviare una notifica all\'amministratore?',
			sentNotifyTo: 'Notifica inviata a ',
			notSentNotifyTo: 'Notifica non inviata a ',
			event: 'Evento',
			attributes: 'Attributi',
			actions: 'Azioni',
			multipleActions: 'Azioni multiple',
			address: 'Indirizzo',
			street: 'Via',
			city: 'Città',
			zip: 'CAP',
			province: 'Provincia',
			region: 'Regione',
			state: 'Stato',
			selectAll: 'Seleziona tutto',
			fillFormCorrectly: 'Compila il form correttamente',
			vendor: 'Vendor',
			model: 'Modello',
			location: 'Location',
			locations: 'Location',
			user: 'Utente',
			users: 'Utenti',
			noDataAvailable: 'Dato non disponibile',
			addMessage: 'Aggiungi un eventuale messaggio',
			selectUser: 'Seleziona utente',
			genericError: 'Si è verificato un errore',
			filters: 'Filtri',
			noLockersAssigned: 'Non risultano esserci locker assegnati al tuo account.',
			noVisibilityForThisLocker: 'Non hai i permessi per visualizzare questo locker.',
			pleaseContactAdmin: 'Contatta il tuo amministratore per maggiori informazioni.',
		},
		cloudinary: {
			howToLoad: 'Scegli come caricare l\'immagine',
			addImage: 'Carica%20immagine',
			replaceImage: 'Sostituisci l\'immagine',
			addNewImage: 'Carica un\'immagine',
			fromUrlText: 'Immagine da URL',
			fromFolderText: 'Immagine da file',
			remoteUrl: 'Inserire un URL remoto',
			chooseFile: 'Scegli un file',
			removeImage: 'Rimuovi immagine',
			selectImage: 'Seleziona un\'immagine...',
			selctFromList: 'O scegli dalla lista',
			useThisImage: 'Usa questa immagine',
			deleteImage: 'Elimina questa immagine',
			limits: {
				msg1: 'L\'immagine deve essere larga ',
				msg2: ' e deve essere alta ',
			},
			errors: {
				emptyURL: 'Inserire un URL valido',
				upload: 'Si è verificato un errore, si prega di riprovare.',
				limits: `La dimensione dell'immagine è sbagliata: la larghezza deve essere %%%px, l'altezza deve essere: ###px`,
			}
		},
		menu: {
			account: 'Account',
			monitor: 'Monitoraggio',
			statistics: 'Statistiche',
			administration: 'Amministrazione',
			platformUsers: 'Utenti di piattaforma',
			backofficeUsers: 'Utenti di back office',
			lockers: 'Locker',
			functions: 'Gestione funzioni',
		},
		home: {
			title: 'Smart Locker dashboard ',
			subtitleNoWidget: 'Non hai ancora aggiunto dei widget alla tua home.',
			subtitleNoWidgetSpan: 'Clicca il bottone + per aggiungerli.',
			subtitleWidget: 'Clicca il bottone + per aggiungere altri widget.',
			message: 'Seleziona una delle funzionalità dal menù principale',
			addWidgetTip: 'Oppure aggiungi un nuovo widget',
			addWidgetTitle: 'Aggiungi un widget',
			selectLocker: 'Seleziona un locker',
			selectWidget: 'Seleziona un widget',
			errors: {
				unableGetHomeWidgets: 'Impossbile recuperare la lista dei widget',
				unableUpdateWidgets: 'Impossbile aggiornare la lista dei widget',
			},
		},
		widgets: {
			MATH: 'Stato del locker',
			OVERDUE: 'Ritardi',
			USERS: 'Utenti',
			PROBLEMS: 'Problematiche',
			LOGS: 'Logbook',
			CONNECTIVITY: 'Stato della connettività',
			CONTROL: 'Controllo',
			confirmDelete: 'Sei sicuro di voler eliminare questo widget?',
		},
		account: {
			details: 'Dettagli del tuo account',
			associatedLockers: 'Locker associati',
			noLockers: 'Non hai locker associati',
			successes: {
				updateAccount: 'Account aggiornato con successo'
			},
			errors: {
				unableToUpdateAccount: 'Non è stato possibile modificare l\' account',
				unableToGetAccountLockers: 'Non è stato possibile recuperare i locker associati al tuo account',
			}
		},
		monitor: {
			description: 'Descrizione',
			showDetails: 'Vedi dettagli',
			boxesStatus: 'Stato dei box',
			connectivity: 'Connettività',
			details: {
				details: 'Dettaglio locker',
				title: 'Dettaglio',
				noAvailableWidget: 'Nessun widget diponibile per il locker',
				errors: {
					unableGetLocker: 'Impossibile recuperare il locker',
					unableToGetAvailableWidgets: 'Impossibile recuperare la lista dei widget disponibili per il locker'
				}
			},
			math: {
				title: 'Stato del locker',
				subtitle: 'Aggiornamento automatico ogni 5 minuti, in alternativa aggiornare manualmente',
				errors: {
					unableToGetMath: 'Non è stato possibile recuperare la matematica per il locker',
					unableToGetSvg: 'Non è stato possibile recuperare l\'immagine SVG del locker'
				}
			},
			overdue: {
				title: 'Ritardi',
				subtitle: 'Box occupati per un tempo superiore a quello previsto dal sistema',
				successNotify: 'Notifica per il ritardo inviata correttamente',
				errorNotify: 'Non è stato possibile inviare la notifica',
				errors: {
					unableToGetOverdues: 'Non è stato possibile recuperare i ritardi'
				}
			},
			lockerUsers: {
				type: 'Operazione',
				subtitle: 'Utenti che hanno delle operazioni sul locker',
				recipientUserForExchange: 'Email dell\'utente che riceve lo scambio',
				errors: {
					unabletoGetLockerUserOperations: 'Non è stato possibile recuperare gli utenti con delle operazioni sul locker',
					unableToGetLockerUsers: 'Non è stato possibile recuperare gli utenti associati al locker'
				}
			},
			problems: {
				title: 'Problematiche',
				subtitle: 'Problematiche riscontrate sui box',
				successNotify: 'Notifica per la problematica inviata correttamente',
				problemType: 'Problematica',
				errorNotify: 'Non è stato possibile inviare la notifica',
				errors: {
					unableToGetProblems: 'Non è stato possibile recuperare i problemi relativi al locker'
				}
			},
			logs: {
				title: 'Logbook',
				subtitle: 'Registro dei log in base all\'utente',
				errors: {
					unableToGetLogs: 'Non è stato possibile recuperare i log',
					unableToCreateSvgFile: 'Non è stato possibile recuperare i log per creare il file SVG'
				}
			},
			connectivityStatus: {
				title: 'Stato della connettività',
				lastSync: 'Ultima sincronizzazione',
				errors: {
					unableToGetStatus: 'Non è stato possibile recuperare lo stato della connettività'
				}
			},
			control: {
				title: 'Controllo',
				subtitle: 'Genera e invia pin di controllo',
				servicePin: 'Pin di servizio',
				generateServicePin: 'Genera Pin di servizio',
				selectValidityPeriod: 'Seleziona periodo di validità',
				addEmailAddress: 'Aggiungi indirizzo email',
				formInstructions: 'Inserisci uno o più indirizzi email ai quali inviare la notifica con il pin di servizio',
				successes: {
					sentNotification: 'Notifica inviata con successo',
					sentNotifications: 'Notifiche inviate con successo'
				},
				errors: {
					requiredEmailAddress: 'Inserire un indirizzo email',
					unableToSendNotification: 'Non è stato possibile inviare la notifica',
					unableToSendNotifications: 'Non è stato possibile inviare le notifiche'
				}
			},
			errors: {
				unableToGetLockers: 'Non è stato possibile recuperare la lista dei locker',
			},
		},
		teamviewer_id: 'Teamviewer id',
		project_name: 'Nome del progetto',
		administration: {
			locationLockers: 'Locker della location:',
			users: {
				platformUsers: 'Utenti di piattaforma',
				backofficeUsers: 'Utenti di back office',
				deleteUserConfirm: 'Confermi di voler rimuovere questo utente?',
				importUsers: 'Importa utenti',
				associateToLockers: 'Associa locker',
				associationLockersToUser: 'Associazione dei locker all\'utente',
				associationLockersToUsers: 'Associazione dei locker agli utenti',
				removeAssociationLockers: 'Rimuovi i locker associati',
				activeUsers: 'Attiva utenti',
				deactivateUsers: 'Disattiva utenti',
				activeUserConfirm: 'Confermi di voler attivare gli utenti selezionati?',
				deactivateUserConfirm: 'Confermi di voler disattivare gli utenti selezionati?',
				associationUsersLockersConfirmTitle: 'Seleziona i locker da associare agli utenti selezionati',
				singleAssociationUserLockersConfirmTitle: 'Seleziona i locker da associare all\'utente',
				associationUsersLockersConfirm: 'Confermi di voler associare i locker selezionati agli utenti selezionati?',
				singleAssociationUserLockersConfirm: 'Confermi di voler associare i locker selezionati all\'utente?',
				removeAssociationUsersLockersConfirmTitle: 'Seleziona i locker da rimuovere dagli utenti selezionati',
				removeAssociationUsersLockersConfirm: 'Confermi di voler rimuovere l\'associazione tra gli utenti selezionati e i locker selezionati?',
				removeSingleAssociationUserLockersConfirmTitle: 'Seleziona i locker da rimuovere dall\'utente',
				removeSingleAssociationUserLockersConfirm: 'Confermi di voler rimuovere l\'associazione tra l\'utente e i locker selezionati?',
				previouslyAssociatedLockers: 'Locker attualmente associati:',
				noAssociatedLockers: 'L\'utente non ha locker attualmente associati',
				noLockersForAssociation: 'Non ci sono locker disponibili per l\'associazione',
				addUser: 'Nuovo utente',
				editUser: 'Modifica utente',
				deleteUser: 'Rimuovi utente',
				singleUserAssociateToLockers: 'Associa l\'utente a uno o più locker',
				singleUserSelectAccociationsLockers: 'Rimuovi l\'associazione tra l\'utente e uno o più locker',
				newUserInstructions: 'Compila il form con i dati del nuovo utente',
				editUserInstructions: 'Modifica i dati relativi all\'utente',
				importUsersInstructions: 'Importa degli utenti da un file csv.',
				// importUsersInstructionsDetails: 'I campi "email", "first_name", "last_name", sono obbligatori. Tutti gli altri campi sono opzionali e possono essere lasciati vuoti. Il campo "gender" accetta come opzioni "MALE", "FEMALE" e "OTHER".',
				importUsersInstructionsDetails: 'I campi "email", "first_name", "last_name", sono obbligatori. Tutti gli altri campi sono opzionali e possono essere lasciati vuoti.',
				importButton: 'Importa da csv',
				downloadExample: 'Scarica un file CSV di esempio',
				selectCsv: 'Seleziona un file CSV',
				separator: 'Seleziona un separatore',
				comma: 'Usa la virgola ,',
				semicolon: 'Usa il punto e virgola ;',
				selectLanguage: 'Seleziona lingua',
				selectGender: 'Seleziona genere',
				associateTo: 'Assegna a:',
				lockerAssociation: 'Associazione locker',
				selectAllLockers: 'Seleziona tutti i locker',
				orSelectBasedOnLocation: 'o seleziona i locker in base alla location:',
				oneUserSelectToBeActivated: 'É stato selezionato un utente per essere attivato.',
				haveBeenSelectedToBeActivated: 'utenti sono stati selezionati per essere attivati.',
				oneUserHasRegisteredStatusForActivation: 'L\'utente è in stato "Registrato" e non può essere attivato.',
				moreUsersHasRegisteredStatusForActivation: 'di questi utenti sono in stato "Registrato" e non possono essere attivati.',
				oneUserSelectToBeDeactivated: 'É stato selezionato un utente per essere disattivato.',
				haveBeenSelectedToBeDeactivated: 'utenti sono stati selezionati per essere disattivati.',
				oneUserHasRegisteredStatusForDeactivation: 'L\'utente è in stato "Registrato" e non può essere disattivato.',
				moreUsersHasRegisteredStatusForDeactivation: 'di questi utenti sono in stato "Registrato" e non possono essere disattivati.',
				successes: {
					deleteUser: 'Utente rimosso con successo',
					associateToLockers: 'Associazione avvenuta con successo',
					deleteAssociations: 'Associazioni rimosse con successo',
					activeUsers: 'Gli utenti sono stati attivati ',
					activeUser: 'L\'utente è stato attivato',
					deactivateUsers: 'Gli utenti sono stati disattivati',
					deactivateUser: 'L\'utente è stato disattivato',
					createUser: 'Utente creato con successo',
					updateUser: 'Utente modificato con successo',
					importUsers: 'I nuovi utenti sono stati importati con successo'
				},
				errors: {
					unableToGetUsers: 'Non è stato possibile recuperare la lista degli utenti',
					unableToDeleteUser: 'Non è stato possibile rimuovere l\'utente',
					unableToAssociateToLockers: 'Non è stato possibile associare gli utenti ai locker',
					unableToDeleteAssociations: 'Non è stato possibile rimuovere le associazioni',
					unableToActiveUsers: 'Non è stato possibile attivate gli utenti',
					unableToDeactivateUsers: 'Non è stato possibile disattivare gli utenti',
					selectUsers: 'Seleziona almeno un utente',
					selectUserAndLocker: 'Seleziona almeno un utente e un locker',
					unableToCreateUser: 'Non è stato possibile creare il nuovo utente',
					unableToUpdateUser: 'Non è stato possibile modificare l\'utente',
					unableReadCsv: 'Impossibile leggere il file CSV',
					notCsvFile: 'Selezionare un file CSV valido',
					unableToUploadFile: 'Non è stato possibile caricare gli utenti',
					noEmailField: 'Nei dati importati non è stato trovato il campo "email"',
					noFirstNameField: 'Nei dati importati non è stato trovato il campo "first_name"',
					noMiddleNameField: 'Nei dati importati non è stato trovato il campo "middle_name"',
					noLastNameField: 'Nei dati importati non è stato trovato il campo "last_name"',
					noGenderField: 'Nei dati importati non è stato trovato il campo "gender"',
					noPhoneNumberNameField: 'Nei dati importati non è stato trovato il campo "phone_number"',
					noPreferredLanguageField: 'Nei dati importati non è stato trovato il campo "preferred_language"',
					nullEmailField: 'Il campo "email" non può essere vuoto',
					nullFirstNameField: 'Il campo "first_name" non può essere vuoto',
					nullLastNameField: 'Il campo "last_name" non può essere vuoto',
					unableToImportUsers: 'Non è stato possibile importare i nuovi utenti',
					noUsersImported: 'Nessun utente è stato importato'
				}
			},
			backoffice: {
				selectRole: 'Seleziona ruolo',
				cantSeeUserLockers: 'Non puoi vedere i locker visibili dall\'utente.',
				cantSeeUserVisibility: 'Non puoi vedere i locker visibili dall\'utente. Contatta l\'amministratore.',
				allTenantLockers: 'Tutti i locker associati al tenant',
				noLockersAssigned: 'Nessun locker visibile dall\'utente',
				makeVisibile: 'Rendi uno o più locker visibili dall\'utente',
				addVisibility: 'Aggiungi visibilità dei locker',
				removeVisibility: 'Rimuovi visibilità dei locker',
				previouslyVisibleLockers: 'Locker attualmente visibili:',
				noVisibleLockers: 'L\'utente non ha locker attualmente visibili',
				noLockers: 'Non ci sono locker disponibili',
				confirm: 'Confermi di voler rendere i locker selezionati visibili dall\'utente?',
				confirmRemove: 'Confermi di voler togliere all\'utente la visibilità dei locker selezionati?',
				successes: {
					createBackOfficeUser: 'Utente di back office creato con successo',
					updateBackOfficeUser: 'Utente di back office aggiornato con successo',
					deleteBackOfficeUser: 'Utente di back office rimosso con successo',
				},
				errors: {
					unableToGetBackOfficeUsers: 'Non è stato possibile recuperare la lista degli utenti di back office',
					unableToCreateBackOfficeUser: 'Non è stato possibile creare il nuovo utente di back office',
					unableToUpdateBackOfficeUser: 'Non è stato possibile aggiornare l\'utente di back office',
					unableToDeleteBackOfficeUser: 'Non è stato possibile rimuovere l\'utente di back office',
					unableToAssociateUserToLockersAfterItsCreation: 'Non è stato possibile dare visibilità dei locker all\'utente creato. Per favore riprova dall\'elenco degli utenti',
					unableToAssociateUserToLockers: 'Non è stato possibile dare visibilità dei locker all\'utente',
					unableToDeleteAssociationToLockers: 'Non è stato possibile rimuovere la visibilità dei locker all\'utente',
				}
			},
			lockers: {
				title: 'Locker',
				deleteLockerConfirm: 'Confermi di voler rimuovere questo locker?',
				associateToLocation: 'Associa a una location',
				addLocker: 'Nuovo locker',
				addLockerInstructions: 'Compila il form con i dati del nuovo locker',
				editLocker: 'Modifica locker',
				deleteLocker: 'Rimuovi locker',
				createQRCode: 'Crea QR code',
				editLockerInstructions: 'Modifica i dati relativi al locker',
				image: 'Immagine',
				model: 'Modello',
				metadata: 'Metadati',
				vendorLockerUuid: 'Vendor locker id',
				softwareVersion: 'Versione software',
				processes: 'Processi',
				selectLocation: 'Seleziona una location',
				selectVendor: 'Seleziona un vendor',
				addProcess: 'Aggiungi un processo',
				noLockerData: 'Dati non disponibili per questo locker',
				steps: {
					1: "Impostazioni generali",
					2: "Grafica",
					3: "Impostazioni vendor",
					4: "Conclusione",
				},
				next: 'Prosegui',
				previous: 'Precedente',
				done: 'Concludi',
				metadataKey: 'Chiave',
				metadataValue: 'Valore',
				addMetadata: 'Aggiungi un metadato',
				environment: 'Ambiente',
				appDownloadUrl: 'URL download app',
				userEnabled: 'Utente abilitato',
				courierEnabled: 'Corriere abilitato',
				conciergeEnabled: 'Concierge abilitato',
				userReturnEnabled: 'Reso abilitato',
				userDelegationEnabled: 'Delega abilitata',
				userExchangeEnabled: 'Scambio abilitato',
				userSelectionEnabled: 'Selezione utente abiltiata',
				noAppearance: 'Per questo vendor non sono previste personalizzazioni grafiche',
				noVendorSpecific: 'Per questo vendor non sono previste impostazioni specifiche',
				serialPort: 'Porta seriale',
				boards: 'Schede',
				boxes: 'Box',
				address: 'Indirizzo',
				numberOfBoxManaged: 'N. di box gestiti',
				boxNumberOffset: 'Offset del numero di box',
				addBoard: 'Aggiungi una scheda',
				boxNumber: 'Numero del box',
				boxType: 'Tipo di box',
				addBox: 'Aggiungi un box',

				remoteAccessId: 'Id per l\'accesso da remoto',
				lockFeedbackType: 'Tipologia di feedback della chiusura',
				maxOpeningAttempts: 'Numero massimo di tentativi di apertura',
				transactionsExpireDays: 'Scadenza transazioni (in giorni)',
				overdueDays: 'Giorni per overdue',
				lockerEnvironment: {
					CORPORATE: 'Corporate',
					RESIDENTIAL: 'Residenziale',
					RETAIL: 'Retail'
				},
				lockFeedbackTypeEnum: {
					LOCK_OPEN_FEEDBACK_OPEN: 'Feedback all\'apertura',
					LOCK_OPEN_FEEDBACK_CLOSED: 'Feedback alla chiusura',
					UNKNOWN: 'Sconosciuto',
				},
				parityEnum: {
					No: 'Nessuno',
					Odd: 'Dispari',
					Even: 'Pari',
				},
				stopBitsEnum: {
					ONE_STOP_BIT: 'Un bit di stop',
					ONE_POINT_FIVE: 'Uno punto cinque',
					TWO: 'Due',
				},
				boxTypeEnum: {
					NORMAL: 'Normale',
					RESERVED: 'Riservato',
				},
				lockerWizardEnd: {
					title: 'Tutti i dati sono stati inseriti con successo',
					subtitle: 'Premere sul pulsante \'Concludi\' per salvare',
				},
				successes: {
					deleteLocker: 'Locker rimosso con successo',
					updateLocker: 'Locker modificato con successo',
					createLocker: 'Locker creato con successo'
				},
				errors: {
					unableToGetLockers: 'Non è stato possibile recuperare la lista dei locker',
					unableToGetLocker: 'Non è stato possibile recuperare i dati del locker',
					unableToGetLockerImage: 'Non è stato possibile recuperare l\'immagine del locker',
					unableToDeleteLocker: 'Non è stato possibile rimuovere il locker',
					unableToUpdateLocker: 'Non è stato possibile modificare il locker',
					unableToCreateLocker: 'Non è stato possibile creare il nuovo locker',
					unableToGetVendors: 'Non è stato possibile recuperare la lista dei vendor',
					unableToGetLockerIdentifier: 'Non è stato possibile recuperare l\'identificativo del locker e creare il QR code',
					form: {
						name: 'Il nome è obbligatorio',
						vendor: 'Selezionare un vendor',
						location: 'Selezionare una location',
						image: 'Caricare un\'immagine del locker',
						environment: 'Selezionare l\'ambiente',
						appDownloadUrl: 'Inserire l\'URL per il download dell\'app',
						appDownloadUrlFormat: 'Inserire un URL valido',
						project_name: 'Inserire il nome del progetto',
						baudRate: 'Inserire il baud rate',
						dataBits: 'Inseriure il data bits',
						parity: 'Selezionare la parity',
						stopBits: 'Selezionare gli stop bits',
						boards: 'Inserire almeno una scheda',
						boardsFields: 'Tutti i campi sono obbligatori',
						boxes: 'Inserire almeno un box',
						boxesFields: 'Tutti i campi sono obbligatori',
						model: 'Inserire il modello',
						softwareVersion: 'Inserire la versione software',
					}
				}
			},
			locations: {
				deleteLocationConfirm: 'Confermi di voler rimuovere questa location?',
				addLocation: 'Nuova location',
				newLocationInstructions: 'Compila il form con i dati della nuova location',
				editLocation: 'Modifica location',
				deleteLocation: 'Rimuovi location',
				editLocationInstructions: 'Modifica i dati relativi alla location',
				successes: {
					deleteLocation: 'Location rimossa con successo',
					updateLocation: 'Location modificata con successo',
					createLocation: 'Location creata con successo'
				},
				errors: {
					unableToGetLocations: 'Non è stato possibile recuperare la lista delle location',
					unableToDeleteLocation: 'Non è stato possibile rimuovere la location',
					unableToUpdateLocation: 'Non è stato possibile modificare la location',
					unableToCreateLocation: 'Non è stato possibile creare la nuova location'
				}
			}
		},
		statistics: {
			labels: {
				locker: 'Locker',
				location: 'Location',
				granularity: 'Granularità',
				range: 'Intervallo di tempo'
			},
			selectLocker: 'Seleziona un locker',
			selectLocation: 'Seleziona una location',
			selectGranularity: 'Seleziona la granularità',
			allLockers: 'Tutti i locker',
			allLockerOfLocation: 'Tutti i locker della location ',
			allLocations: 'Tutte le location',
			allLocationsSelectedDescription: 'Selezionando \'Tutte le location\', le statistiche riportano i dati aggregati di tutti i locker di tutte le location',
			allLockersSelectedDescription: 'Selezionando \'Tutti i locker\', le statistiche riportano i dati aggregati di tutti i locker della location selezionata',
			hour: 'Ore',
			deliveries: 'Consegne',
			returns: "Ritiri",
			overdues: 'Overdues',
			selectRangeError: 'Seleziona un intervallo di tempo',
			chart: 'Grafico',
			table: 'Tabella',
			averageBoxOccupationTime: 'Tempo medio di occupazione dei box',
			deliveriesAndReturns: 'Consegne e resi',
			totalOperations: 'Totale operazioni',
			XS: 'Extra Small',
			S: 'Small',
			M: 'Medium',
			L: 'Large',
			XL: 'Extra Large',
			DAY: 'Giorno',
			WEEK: 'Settimana',
			MONTH: 'Mese',
			YEAR: 'Anno',
			sizeBoxUsedAnalysis: 'Analisi dimensione del box utilizzato',
			sizeBoxUsedAnalysisSubtitle: 'Percentuale delle operazioni suddivise per dimensione del box',
			totalOperationsRecorded: 'Totale operazioni rilevate:',
			boxSize: 'Dimensione box',
			rateOfUsage: 'Percentuale di utilizzo',
			operationsNumber: 'Numero di operazioni',
			usersOperationsAnalysis: 'Analisi dati utenti e dati di utilizzo',
			usersOperationsAnalysisSubtitle: 'Statistiche sull\'utilizzo del locker da parte degli utenti',
			tableHeaders: {
				totalUsers: 'Utenti totali registrati e attivi',
				usersMadeOperations: 'Utenti che hanno eseguito operazioni',
				operationsPerUserAverage: 'Media numero operazioni per utente',
				storageRangeAverage: 'Tempo media giacenza pacco (giorni)',
				overdueTimes: 'Numero di overdue',
				operationsTotalNumber: 'Totale numero operazioni rilevate attendibili',
				conciergeOrTestOperations: 'Totale numero operazioni rilevate come prove o ritiri da concierge',
				overdueDurationAverage: 'Tempo medio di permanenza in overdue (giorni)',
			},
			deliveriesPerHour: 'Fascia oraria di consegna al locker',
			deliveriesPerHourSubtitle: 'Statistiche sugli orari di consegna dei pacchi',
			returnsPerHour: 'Fascia oraria di ritiro dal locker',
			returnsPerHourSubtitle: 'Statistiche sugli orari di ritiro dei pacchi',
			deliveriesPerDay: 'Andamento delle consegne per data',
			deliveriesPerDaySubtitle: 'Statistiche sulle date di consegna dei pacchi',
			returnsPerDay: 'Andamento dei ritiri per data',
			returnsPerDaySubtitle: 'Statistiche sulle date di ritiro dei pacchi',
			overduesPerDay: 'Andamento degli overdue per data',
			overduesPerDaySubtitle: 'Trend degli overdue per giorno',
			statisticsFrom: 'Statistiche dal',
			to: 'al',
			exportToCsv: 'Esporta in CSV',
			exportToPdf: 'Esporta in PDF',
			pdfFileGenerating: 'Generazione del file PDF in corso...',
			exportFileFooter: 'Smart Locker S.r.l.\nSede legale: Viale Mentana, 41 43121 Parma\n' +
				'Sede operativa: Via Don Minzoni, 112 - 42043 - Taneto di Gattatico - (RE)\n' +
				'P.I: 02878640347 Tel. +39 0522 674170 - info@samartlocker.it - www.smartlocker.it',
			errors: {
				unableToGetBoxSizeSatatistics: 'Non è stato possibile ottenere le statistiche riguardo l\'analisi delle dimensioni dei box utilizzati',
				unableToGetUsersOperationsSatatistics: 'Non è stato possibile ottenere le statistiche riguardo l\'utilizzo del locker da parte degli utenti',
				unableToGetDeliveriesPerHourSatatistics: 'Non è stato possibile ottenere le statistiche riguardo le operazioni di consegna per ora del giorno',
				unableToGetReturnsPerHourSatatistics: 'Non è stato possibile ottenere le statistiche riguardo le operazioni di ritiro per ora del giorno',
				unableToGetDeliveriesPerDaySatatistics: 'Non è stato possibile ottenere le statistiche riguardo le operazioni di consegna per giorno',
				unableToGetReturnsPerDaySatatistics: 'Non è stato possibile ottenere le statistiche riguardo le operazioni di ritiro per giorno',
				unableToGetOverduesPerDayStatistics: 'Non è stato possibile ottenere le statistiche riguardo l\'andamento degli overdue per giorno',
				selectARange: 'Seleziona un intervallo di tempo',
				unableToExportStatistics: 'Non è stato possibile esportare le statistiche',
				unableToLoadPdfMaker: 'Non è stato possibile caricare il generatore di file PDF',
				unableToGeneratePdf: 'Non è stato possibile generare il file PDF'
			}
		}
	}
}