export const environment = {
	production: true,
	version: '1.3.1-DEV',
	/**
	 * API configuration
	 */
	baseUrl: 'https://smartlocker-api.guglielmo.dev/back-office', // DEV

	baseUrlForQRCode: 'https://smartlocker-users.guglielmo.dev/user-registration/self-signin', // DEV

	/**
	 * Firebase configurations
	 */
	firebase: {
		// DEV
		configuration: {
			apiKey: "AIzaSyBLX6HUpINEZOh1U2dMk3ypxOeJZIuOt5M",
			authDomain: "ambiente-dev-smartlocker.firebaseapp.com",
			projectId: "ambiente-dev-smartlocker",
			storageBucket: "ambiente-dev-smartlocker.appspot.com",
			messagingSenderId: "143908795479",
			appId: "1:143908795479:web:a0b91888cee62441e0df12",
			measurementId: "G-3PNZE54WYE"
		},

		params: {
			enableFirestoreSync: true, // enable/disable autosync users with firestore
			toastMessageOnAuthSuccess: false, // whether to open/show a snackbar message on auth success - default : true
			toastMessageOnAuthError: false, // whether to open/show a snackbar message on auth error - default : true
			loginPath: '/auth/login', // url for unauthenticated users - to use in combination with canActivate feature on a route
			authGuardFallbackURL: '/home', // url for unauthenticated users - to use in combination with canActivate feature on a route
			authGuardLoggedInURL: '/home', // url for authenticated users - to use in combination with canActivate feature on a route
			passwordMaxLength: 60, // `min/max` input parameters in components should be within this range.
			passwordMinLength: 5, // Password length min/max in forms independently of each componenet min/max.
			// Same as password but for the name
			nameMaxLength: 50,
			nameMinLength: 2,
			// If set, sign-in/up form is not available until email has been verified.
			// Plus protected routes are still protected even though user is online.
			guardProtectedRoutesUntilEmailIsVerified: true,
			enableEmailVerification: true, // default: true
		}
	},

	/**
	 * Cloudinary configuration
	*/
	cloudinary: {
		folder: 'nyec9pfx',
		lockersFolder: 'a0wr1tdn',
		cloudName: 'do6okmdil',
		endpoint: 'https://api.cloudinary.com/v1_1/do6okmdil/upload'
	},
};
