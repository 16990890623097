import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NZ_DATE_CONFIG, NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { APP_BASE_HREF, PlatformLocation, registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/it';
import it from '@angular/common/locales/it';
import { IconDefinition } from '@ant-design/icons-angular';
import { NZ_ICONS } from 'ng-zorro-antd/icon';
import * as AllIcons from '@ant-design/icons-angular/icons';
import { it_IT } from './core/config/i18n-ng-zorro/it_IT';
import firebase from 'firebase/compat/app';
import 'firebase/auth';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from 'src/environments/environment';
import { NgZorroModule } from './core/modules/ng-zorro/ng-zorro.module';
import { TranslateModule } from '@ngx-translate/core';
import { AuthFirebaseService } from './core/services/auth-firebase.service';
import { HttpUtilsService } from './core/services/http-utils.service';
import { LanguageService } from './core/services/language.service';
import { LoaderService } from './core/services/loader.service';
import { SpinnerService } from './core/services/spinner.service';
import { TranslationService } from './core/services/translation.service';
import { LoginService } from './core/services/network/login.service';
import { LayoutModule } from './layout/layout.module';
import { AuthBaseComponent } from './pages/auth/auth-base/auth-base.component';
import { DashboardBaseComponent } from './pages/dashboard/dashboard-base/dashboard-base.component';
import { PageNotFoundComponent } from './pages/dashboard/page-not-found/page-not-found.component';
import { TenantService } from './core/services/tenant.service';
import { AdministrationService } from './core/services/network/administration.service';
import { LockerService } from './core/services/network/locker.service';
import { CheckRolesService } from './core/services/check-roles.service';
import { HomeService } from './core/services/network/home.service';

registerLocaleData(en);
registerLocaleData(it);

const antDesignIcons = AllIcons as {
	[key: string]: IconDefinition;
};
const icons: IconDefinition[] = Object.keys(antDesignIcons).map(key => antDesignIcons[key]);

firebase.initializeApp(environment.firebase.configuration);

export function getBaseHref(platformLocation: PlatformLocation): string {
	return platformLocation.getBaseHrefFromDOM();
}

@NgModule({
	declarations: [
		AppComponent,
		AuthBaseComponent,
		DashboardBaseComponent,
		PageNotFoundComponent,
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		FormsModule,
		HttpClientModule,
		BrowserAnimationsModule,
		NgZorroModule,
		TranslateModule.forRoot(),
		LayoutModule
	],
	providers: [
		{ provide: NZ_ICONS, useValue: icons },
		{ provide: NZ_DATE_CONFIG, useValue: { firstDayOfWeek: 1 } },
		{
			provide: NZ_I18N, useFactory: (localId: string) => {
				switch (localId) {
					case 'en':
						return en_US;
					case 'it':
						return it_IT;
					default:
						return en_US;
				}
			},
			deps: [LOCALE_ID]
		},
		{
			provide: APP_BASE_HREF,
			useFactory: getBaseHref,
			deps: [PlatformLocation]
		},
		AuthFirebaseService, 
		HttpUtilsService, 
		LanguageService, 
		LoaderService, 
		SpinnerService, 
		TranslationService, 
		TenantService,
		LoginService, 
		AdministrationService,
		LockerService,
		CheckRolesService,
		HomeService
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
