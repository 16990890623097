<nz-card widgetLocker class="h-100" [nzTitle]="title">
	<ng-container *ngIf="!loading; else loader">
		<ng-container *ngIf="lockersPerLocationMap.size > 0; else noLockers">
			<ng-container *ngFor="let item of lockersPerLocationMap | keyvalue">
				<p class="fw-bold">{{item.value[0].location.name}}
					<small class="text-muted d-block fw-normal">{{item.value[0].location.getAddress()}}</small>
				</p>
				<nz-space class="mb-4" nzWrap="true" >
					<small class="text-muted me-2">{{'administration.locationLockers' | translate}}</small>
					<div *ngFor="let locker of item.value">
						<nz-tag [nzColor]="'orange'">{{locker.name}}</nz-tag>
					</div>
				</nz-space>
			</ng-container>
		</ng-container>
	</ng-container>
	
</nz-card>

<ng-template #title>
	<h5 class="title">{{'account.associatedLockers' | translate | uppercase}}</h5>
</ng-template>

<ng-template #noLockers>
	<nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="noLockersMessage"></nz-empty>
</ng-template>

<ng-template #noLockersMessage>
	<p>{{'general.noLockersAssigned' | translate}}<br><span>{{'general.pleaseContactAdmin' | translate}}</span></p>
</ng-template>

<ng-template #loader>
	<div class="loading-spin">
		<nz-spin nzTip="{{'general.loaderMessage' | translate}}..."></nz-spin>
	</div>
</ng-template>