import { Component, Input, OnInit } from '@angular/core';
import { BoxSize } from 'src/app/core/models/box-size';
import { MonitorLockerList } from 'src/app/core/models/locker/monitor-locker';

@Component({
	selector: 'app-average-box-occupancy',
	templateUrl: './average-box-occupancy.component.html',
	styleUrls: ['./average-box-occupancy.component.less']
})
export class AverageBoxOccupancyComponent implements OnInit {

	@Input() lockers = new MonitorLockerList();

	selectedLocker: string = "";
	boxes: Map<string, string> = new Map;

	constructor() { }

	ngOnInit(): void {
		if (this.lockers && this.lockers.data.length > 0) {
			this.selectedLocker = this.lockers.data[0].uuid;
			this.getBoxesData();
		}
	}

	onSelectChange(): void {
		this.getBoxesData();
	}

	getBoxesData(): void {
		this.boxes.clear();
		if (this.selectedLocker == "16ecaf8e-48af-4be9-8e92-3691fcbce7a8") {
			this.boxes.set(BoxSize.XS, "2 giorni");
			this.boxes.set(BoxSize.M, "1 giorno");
			this.boxes.set(BoxSize.S, "1 giorno");
			this.boxes.set(BoxSize.XL, "2 giorni");
		} else {
			this.boxes.set(BoxSize.M, "1 giorno");
			this.boxes.set(BoxSize.S, "2 giorni");
			this.boxes.set(BoxSize.XL, "0 giorni");
		}
	}

}
