<nz-card widgetLocker [nzTitle]="title" [nzExtra]="extras" class="h-100">
    <ng-container *ngIf="!noLockerVisibility; else noVisibility">
        <div class="d-flex justify-content-between">
            <nz-select nzShowSearch nzAllowClear [(ngModel)]="userId" nzPlaceHolder="{{'general.selectUser' | translate}}" class="w-50" (ngModelChange)="onSelectChange()">
                <nz-option *ngFor="let user of users.data" nzValue="{{user.uuid}}" nzLabel="{{user.firstName}} {{user.lastName}}"></nz-option>
            </nz-select>
            <button nz-button (click)="exportToCsv()" [disabled]="logs.count === 0" [nzLoading]="csvLoading">
                {{'statistics.exportToCsv' | translate}}
            </button>
        </div>
    
        <nz-table [nzData]="logs.data" 
            [nzTotal]="logs.count"
            [nzPageSize]="paging.pageSize"
            [nzPageIndex]="paging.pageIndex"
            [nzFrontPagination]="false" 
            (nzQueryParams)="onQueryParamsChange($event)"
            [nzPaginationType]="'small'"
            nzBordered class="mt-2" 
            [nzScroll]="{x: '600px', y: '240px'}">
            <thead>
                <tr>
                    <th>{{'general.startDate' | translate}}</th>
                    <th>{{'general.endDate' | translate}}</th>
                    <th>{{'general.box' | translate}}</th>
                    <th>{{'monitor.lockerUsers.type' | translate}}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let log of logs.data">
                    <td>{{log.start | date:'short':'': helpers.getLanguage() }}</td>
                    <td>{{log.end ? (log.end | date:'short':'': helpers.getLanguage()) : 'general.inProgress' | translate}}</td>
                    <td>{{log.boxId}}</td>
                    <td>{{log.type ? (('general.' + log.type.toLowerCase()) | translate) : ''}}</td>
                </tr>
            </tbody>
        </nz-table>
    </ng-container>
</nz-card>

<ng-template #title>
	<h5 class="title">{{'monitor.logs.title' | translate | uppercase}}
		<small *ngIf="lockerDescription">{{lockerDescription}}</small>
	</h5>
    <span class="text-muted subtitle">{{'monitor.logs.subtitle' | translate}}</span>
</ng-template>

<ng-template #extras>
    <button *ngIf="showDelete" class="ms-2" nz-button
        nz-popconfirm
        nzPopconfirmTitle="{{'widgets.confirmDelete' | translate}}"
        nzPopconfirmPlacement="top"
        (nzOnConfirm)="confirmDelete()"
        ><span nz-icon [nzType]="'delete'"></span></button>
</ng-template>

<ng-template #noVisibility>
	<nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="noLockersMessage"></nz-empty>
</ng-template>

<ng-template #noLockersMessage>
	<p>{{'general.noVisibilityForThisLocker' | translate}}<br><span>{{'general.pleaseContactAdmin' | translate}}</span></p>
</ng-template>
