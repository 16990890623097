import { JsonConverter, JsonCustomConvert } from "json2typescript";

@JsonConverter
export class ProblemTypeConverter implements JsonCustomConvert<ProblemType> {
    serialize() {}
    deserialize(data: any): ProblemType {
        switch(data){
            case "BLOCKED":
            default:
                return ProblemType.BLOCKED;
        }
    }
}

/**
 * Problem types
 * - BLOCKED
 */
export enum ProblemType {
    BLOCKED = "BLOCKED"
}
