<div *ngIf="loading; else loaded" class="w-100 position-relative p-3">
	<div class="full-page-loader">
		<nz-spin nzTip="{{'general.loaderMessage' | translate}}..."></nz-spin>
	</div>
</div>

<ng-template #loaded>
	<h6 *ngIf="!isFromMonitorLockerList">{{'monitor.connectivityStatus.title' | translate | uppercase}}</h6>
	<div class="row">
		<ng-container *ngIf="status; else noData">
			<div class="col-2 pill me-1" >
				<div [ngClass]="{'online': status.status === lockerStatus.ONLINE, 'offline': status.status !== lockerStatus.ONLINE}"></div>
			</div>
			<div style="line-height: 1;" class="col-9">
				{{('general.status.' + status.status?.toLowerCase()) | translate}}
				<div>
					<small>{{'monitor.connectivityStatus.lastSync' | translate}}: {{status.last_seen | date: 'short': '': helpers.getLanguage()}}</small>
				</div>
			</div>
		</ng-container>
	</div>
</ng-template>

<ng-template #noData>
	<div class="col-12">{{noLockerAuthorized ? 'general.noVisibilityForThisLocker' : 'general.noDataAvailable' | translate}}</div>
</ng-template>