import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Subscription } from 'rxjs';
import { Helpers } from 'src/app/core/helpers';
import { LockerConnectivityStatus } from 'src/app/core/models/locker-status';
import { LockerStatus } from "src/app/core/models/locker-status.enum";
import { LockerService } from 'src/app/core/services/network/locker.service';

@Component({
	selector: 'app-connection-status',
	templateUrl: './connection-status.component.html',
	styleUrls: ['./connection-status.component.less']
})
export class ConnectionStatusComponent implements OnInit, OnDestroy {

	@Input() lockerUuid: string = "";
	@Input() isFromMonitorLockerList: boolean = false;

	subscriptions: Array<Subscription> = [];
	status: LockerConnectivityStatus | undefined = undefined;
	loading: boolean = false;
	lockerStatus = LockerStatus;
	noLockerAuthorized: boolean = false;
	helpers = Helpers;

	constructor(private lockerService: LockerService,
		private router: Router,
		private notification: NzNotificationService,
		private translate: TranslateService) { }

	ngOnInit(): void {
		this.getLockerStatus();
	}

	getLockerStatus() {
		this.loading = true;
		this.subscriptions.push(
			this.lockerService.getLockerConnectivityStatus(this.lockerUuid).subscribe(resp => {
				if (resp) {
					this.status = resp;
				} else {
					if (!this.isFromMonitorLockerList) {
						Helpers.errorNotification(this.notification, this.translate, 'monitor.connectivityStatus.errors.unableToGetStatus');
					}
				}
				this.loading = false;
			}, err => {
				console.error(err);
				this.loading = false;
				if (!this.isFromMonitorLockerList) {
					if (err.statusCode === 403 || err.status === 403) {
						this.noLockerAuthorized = true;
					} else {
						Helpers.errorNotification(this.notification, this.translate, 'monitor.connectivityStatus.errors.unableToGetStatus');
					}
				}
			})
		);
	}

	onRefresh() {
		this.getLockerStatus();
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(s => s.unsubscribe());
	}

}
