import { NotifyMenu } from "../models/notify-menu";
import { Role } from "../models/role";

export const OverdueNotifyMenuConfig: Array<NotifyMenu> = [
    {
        translate: 'general.notifyUser',
        translateConfirmTitle: 'general.notifyConfirmUser',
        roleToNotify: [Role.USER],
        rolesCanNotify: [Role.GOD, Role.SUPER_ADMIN, Role.ADMIN]
    },
    {
        translate: 'general.notifyConcierge',
        translateConfirmTitle: 'general.notifyConfirm',
        roleToNotify: [Role.CONCIERGE],
        rolesCanNotify: [Role.GOD, Role.SUPER_ADMIN, Role.ADMIN]
    },
    {
        translate: 'general.notifyAdmin',
        translateConfirmTitle: 'general.notifyConfirm',
        roleToNotify: [Role.ADMIN, Role.SUPER_ADMIN],
        rolesCanNotify: [Role.GOD, Role.CONCIERGE, Role.SERVICE]
    },
    {
        translate: 'general.notifyService',
        translateConfirmTitle: 'general.notifyConfirm',
        roleToNotify: [Role.SERVICE],
        rolesCanNotify: [Role.GOD, Role.CONCIERGE]
    }
]

export const ProblemNotifyMenuConfig: Array<NotifyMenu> = [
    {
        translate: 'general.notifyConcierge',
        translateConfirmTitle: 'general.notifyConfirm',
        roleToNotify: [Role.CONCIERGE],
        rolesCanNotify: [Role.GOD, Role.SUPER_ADMIN, Role.ADMIN]
    },
    {
        translate: 'general.notifyService',
        translateConfirmTitle: 'general.notifyConfirm',
        roleToNotify: [Role.SERVICE],
        rolesCanNotify: [Role.GOD, Role.CONCIERGE]
    }
]