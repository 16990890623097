import { JsonObject, JsonProperty } from "json2typescript";
import { NzTableSortOrder } from "ng-zorro-antd/table";

@JsonObject("PagedData")
export class PagedData {
	@JsonProperty("count", Number, true)
	count: number = 0;
}

export class Paging {
	pageIndex: number = 1;
	pageSize: number = 10;
	sort: Array<{ key: string, value: NzTableSortOrder }> = [];

	getOffsetAndLimitQs() {
		return `?offset=${(this.pageIndex - 1) * this.pageSize}&limit=${this.pageSize}`;
	}

	getQs() {
		return `?offset=${(this.pageIndex - 1) * this.pageSize}&limit=${this.pageSize}&field=${this.getSortField()}&direction=${this.getSortDirection()}`;
	}

	private getSortDirection() {
		const currentSort = this.sort.find(item => item.value !== null);
		if (currentSort) {
			if (currentSort.value === "ascend") {
				return 'ASC';
			} else if (currentSort.value === "descend") {
				return 'DESC';
			} else {
				return '';
			}
		} else {
			return '';
		}
	}

	private getSortField() {
		const currentSort = this.sort.find(item => item.value !== null);
		if (currentSort) {
			return currentSort.key;
		} else {
			return '';
		}
	}
}
