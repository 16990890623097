import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Helpers } from '../helpers';
import { Role } from '../models/role';

@Injectable({
	providedIn: 'root'
})
export class CheckRolesService {

	constructor(private router: Router) { }

	/**
	 * Check if passed route is permitted for current user role, otherwise retirect to home
	 * @param route 
	 */
	checkAndRedirect(route: ActivatedRoute) {
		route.data.subscribe((data) => {
			const pagePermittedRoles: Array<Role> = data.permittedRoles;
			if (pagePermittedRoles) {
				const permission: boolean = Helpers.checkRole(pagePermittedRoles) || false;
				if (!permission) {
					this.router.navigateByUrl('/home');
				}
			}
		})
	}

	/**
	 * Check if passed route is permitted for current user role
	 * @param route 
	 */
	check(route: ActivatedRoute) {
		return new Observable<boolean>((observer) => {
			route.data.subscribe((data) => {
				const pagePermettedRoles: Array<Role> = data.permittedRoles;
				if (pagePermettedRoles) {
					return observer.next(Helpers.checkRole(pagePermettedRoles) || false);
				} else {
					return observer.next(false);
				}
			})
		});
	}
}
