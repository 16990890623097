<ng-container *ngIf="lockers.count !== 0; else noLockers">
        <nz-table nzShowSizeChanger [nzData]="lockers.data" [nzFrontPagination]="false"
            [nzLoading]="loading" [nzTotal]="lockers.count" [nzPageSize]="paging.pageSize"
            [nzPageIndex]="paging.pageIndex" (nzQueryParams)="onQueryParamsChange($event)" 
            nzBordered [nzScroll]="{x: '1100px'}">
        <thead>
            <tr>
                <th nzColumnKey="name" [nzSortFn]="true">{{'general.name' | translate}}</th>
                <th nzColumnKey="vendor" [nzSortFn]="true">{{'general.vendor' | translate}}</th>
                <th>{{'monitor.connectivity' | translate}}</th>
                <th nzColumnKey="location">{{'general.location' | translate}}</th>
                <th nzColumnKey="boxesStatus">{{'monitor.boxesStatus' | translate}}</th>
                <th>{{'monitor.details.details' | translate}}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let locker of lockers.data">
                <td>{{locker.name}}</td>
                <td>{{locker.vendor}}</td>	
                <td><app-connection-status [lockerUuid]="locker.uuid" [isFromMonitorLockerList]="true"></app-connection-status></td>
                <td>{{locker.location.name}}</td>
                <td>
                    <app-show-boxes-status [lockerUuid]="locker.uuid" [fixedWidth]="true"></app-show-boxes-status>
                </td>
                <td><a nz-button nzType="link" 
                    [routerLink]="['/monitor/details/' + locker.uuid]" 
                    routerLinkActive="router-link-active">{{'monitor.showDetails' | translate}}</a></td>
                </tr>
        </tbody>
    </nz-table>
</ng-container>

<!-- <ng-template #noLockers>
    <nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="'general.noLockersAssigned' | translate"></nz-empty>
</ng-template> -->
<ng-template #noLockers>
    <div class="container-flex ps-5 pe-5">
        <div class="height-50 d-flex align-items-center justify-content-center">
            <div class="text-center">
				<nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="''"></nz-empty>
                <h6>{{'general.noLockersAssigned' | translate}}<br><span>{{'general.pleaseContactAdmin' | translate}}</span></h6>
            </div>
        </div>
    </div>
</ng-template>