import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, Inject, Input, NgZone, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { Root, color, Tooltip, DataProcessor, Bullet, Circle } from '@amcharts/amcharts5';
import { XYChart, ValueAxis, AxisRendererY, AxisRendererX, XYCursor, DateAxis, LineSeries } from '@amcharts/amcharts5/xy';
import am5lang_en_US from "@amcharts/amcharts5/locales/en_US";
import am5lang_it_IT from "@amcharts/amcharts5/locales/it_IT";
import { TranslateService } from '@ngx-translate/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Subscription } from 'rxjs';
import { Helpers } from 'src/app/core/helpers';
import { DateAnalysisObject } from 'src/app/core/models/date-analysis-object';
import { StatisticsService } from 'src/app/core/services/network/statistics.service';
import html2canvas from 'html2canvas';

@Component({
	selector: 'app-returns-per-day',
	templateUrl: './returns-per-day.component.html',
	styleUrls: ['./returns-per-day.component.less']
})

export class ReturnsPerDayComponent implements OnInit, OnDestroy, AfterViewInit {

	@Input() selectedLocker: string | undefined = undefined;
	@Input() selectedLocation: string | undefined = undefined;
	@Input() since: string = '';
	@Input() until: string = '';
	@Input() base64data: any;
  
	private subscriptions: Array<Subscription> = [];
	private root!: Root;
	loading: boolean = false;
	returnsPerDay: Array<DateAnalysisObject> = [];
	chart!: XYChart;
	docDefinition: any;
	loadingPdf: boolean = false;
	pdfmaker: any;
	pdffonts: any;

 	constructor(@Inject(PLATFORM_ID) private platformId: Object, 
		private zone: NgZone,
		private statisticsService: StatisticsService, 
		private notification: NzNotificationService,
		private translate: TranslateService) { }

	ngOnInit(): void {
		this.getStatistics();
	}

	async loadPdfmaker() {
		if(!this.pdfmaker){
			this.pdfmaker = await import('pdfmake/build/pdfmake');
			this.pdffonts = await import('pdfmake/build/vfs_fonts');
		}
	}

	getRoot(): Root {
		return this.root;
	}

	private getStatistics(): void {
		this.loading = true;

		let locationUuid = undefined;
		let lockerUuid = undefined;
		if(this.selectedLocation && this.selectedLocation !== Helpers.allLocations){
			locationUuid = this.selectedLocation;
		}
		if(this.selectedLocker && this.selectedLocker !== Helpers.allLockers){
			lockerUuid = this.selectedLocker;
		}
		// Disposing the chart and the root of the chart. 
		// This is necessary because if we don't dispose the chart, the chart will not be updated when the data changes.
		if(this.chart){
			this.root.dispose();
			this.chart.dispose();
		}

		this.subscriptions.push(
		this.statisticsService.getReturnsPerDayStatistics(this.since, this.until, locationUuid, lockerUuid)
			.subscribe(
				{
				next: resp => {
					if(resp.length === 0){
						this.returnsPerDay = [];
					}else {
						this.returnsPerDay = Helpers.normalizeData(this.since, this.until, resp);
					}
					if(this.root && this.root.isDisposed()){
						this.createChartWithCheck();
					}
					this.loading = false;
				},
				error: err => {
					console.error(err);
					if(err.statusCode === 403 || err.status === 403){
						Helpers.errorNotification(this.notification, this.translate, 'general.getStatisticsUnauthorized');
					}else {
						Helpers.errorNotification(this.notification, this.translate, 'statistics.errors.unableToGetReturnsPerDaySatatistics');
					}
					this.loading = false;
				}
				}
			)
		)
	}

	// Run the function only in the browser
	private browserOnly(f: () => void) {
		if (isPlatformBrowser(this.platformId)) {
			this.zone.runOutsideAngular(() => {
				f();
			});
		}
	}

	private createChart(): void {
		this.browserOnly(() => {
			this.root = Root.new("returnsPerDayDiv");

			this.root.interfaceColors.set('background', color(0xffffff));
			this.root.interfaceColors.set('alternativeBackground', color(0xffffff));

			this.chart = this.root.container.children.push(
				XYChart.new(this.root, {
					panY: false,
					layout: this.root.verticalLayout
				})
			);

			/* Set the chart language. */
			if (Helpers.getLanguage() === 'it') {
				this.root.locale = am5lang_it_IT;
			} else {
				this.root.locale = am5lang_en_US;
			}

			// Create Y-axis
			const yAxis = this.chart.yAxes.push(
				ValueAxis.new(this.root, {
					renderer: AxisRendererY.new(this.root, {}),
					maxPrecision: 0,
					max: this.axisYValue(),
					min: 0
				})
			);
			
			// Create X-axis
			const xAxis = this.chart.xAxes.push(
				DateAxis.new(this.root, {
					maxDeviation: 0.1,
					baseInterval: {
						timeUnit: "day",
						count: 1
					},
					renderer: AxisRendererX.new(this.root, {})
				})
			);

			let series = this.chart.series.push(LineSeries.new(this.root, {
				name: "Deliveries",
				xAxis: xAxis,
				yAxis: yAxis,
				valueXField: "key",
				valueYField: "value",
				minDistance: 0,
				tooltip: Tooltip.new(this.root, {})
			}));

			series.data.processor = DataProcessor.new(this.root, {
				dateFormat: "yyyy-MM-dd",
				dateFields: ["date"]
			})

			series.data.setAll(this.returnsPerDay);
			
			series.get("tooltip")?.label.set("text", "{key.formatDate('dd-MM-yy')}\n" + this.translate.instant('statistics.returns') + ": {value}");
			
			series.bullets.push(() => {
				return Bullet.new(this.root, {
					locationY: 0,
					sprite: Circle.new(this.root, {
						radius: 5,
						stroke: this.root.interfaceColors.get("background"),
						strokeWidth: 2,
						fill: series.get("fill")
					})
				})
			})

			this.chart.set("cursor", XYCursor.new(this.root, {
				behavior: "none",
				xAxis: xAxis
			}));
		})
	}

	async exportToPdf(): Promise<void> {
		this.loadingPdf = true;
		const isDone = await this.generatePdf();
		if(isDone){
			this.loadPdfmaker().then(() => {
				try{
					const win = window.open('', '_blank');
					this.pdfmaker.createPdf(this.docDefinition, null, null, this.pdffonts.pdfMake.vfs).open({}, win);
					this.loadingPdf = false;
				}catch(err){
					console.error(err);
					Helpers.errorNotification(this.notification, this.translate, 'statistics.errors.unableToExportStatistics');
					this.loadingPdf = false;
				}
			}).catch(err => {
				console.error(err);
				Helpers.errorNotification(this.notification, this.translate, 'statistics.errors.unableToLoadPdfMaker');
				this.loadingPdf = false;
			});
		} else {
			Helpers.errorNotification(this.notification, this.translate, 'statistics.errors.unableToGeneratePdf');
			this.loadingPdf = false;
		}
	}

	private generatePdf(): Promise<boolean> {
		const chart = document.getElementById('returnsPerDayDiv');
		const title = document.getElementById('statistics-title');
		const subtitle = document.getElementsByClassName("statistics-subtitle")[0];
		const chartTitle = this.translate.instant('statistics.returnsPerDay');
		return new Promise((resolve, reject) => {
			html2canvas(chart!, {
			allowTaint: true,
			backgroundColor: '#ffffff',
			}).then(canvas => {
				const dataUrl = canvas.toDataURL();
				this.docDefinition = {
					pageSize: "A4",
					pageOrientation: "portrait",
					marging: [20, 20, 20, 100],
					header: {
						image: this.base64data,
						width: 120,
						margin: [20, 10, 0, 0],
					},
					content: [],
					footer: {
						text: this.translate.instant('statistics.exportFileFooter'),
						margin: [20, 0, 0, 20],
						fontSize: 8,
						color: 'gray'
					}
				};
				this.docDefinition.content.push({
					text: title?.innerHTML,
					fontSize: 20,
					bold: true,
					margin: [0, 25, 0, 3]
				});
				this.docDefinition.content.push({
					text: subtitle?.innerHTML,
					fontSize: 18,
					bold: true,
					margin: [0, 0, 0, 15]
				});
				this.docDefinition.content.push({
					text: chartTitle,
					fontSize: 14,
					bold: true,
					margin: [0, 0, 0, 20]
				});
				this.docDefinition.content.push({
					image: dataUrl,
					width: 300,
				});	
				resolve(this.docDefinition);		
			}).catch(err => {
				console.error(err);
				reject(false);
			});
		});

	};

	onFiltersChange(since: string, until: string): void {
		this.since = since;
		this.until = until;
		this.getStatistics();
	}

	private axisYValue(): number {
		const max: number = Math.max(...this.returnsPerDay.map(i => i.value));
		return Math.ceil(max + ((max*10)/100));
	}

	ngAfterViewInit(): void {
		this.createChartWithCheck();
	}

	/**
	 * If the element with the id 'returnsPerDayDiv' exists, create the chart. If it doesn't exist, check
	 * again in 100 milliseconds. If it still doesn't exist after 3 tries, give up
	 */
	private createChartWithCheck(): void {
		let i = 0;
		const check = () => {
			if(document.getElementById('returnsPerDayDiv')){
				this.createChart();
			}else {
				if(i < 3){
					setTimeout(() => {
						check();
						i++;
					}, 100);
				}
			}
		}
		check();
	}

	ngOnDestroy() {
		this.subscriptions.forEach(s => s.unsubscribe());
		// Clean up chart when the component is removed
		this.browserOnly(() => {
			if (this.root) {
				this.root.dispose();
			}
		});
	}

}
