<nz-card widgetLocker [nzTitle]="title" [nzExtra]="extras" class="h-100">
    <ng-container *ngIf="!noLockerVisibility; else noVisibility">
        <nz-table [nzData]="problems" [nzLoading]="loading" [nzFrontPagination]="false" 
            nzBordered nzSize="small" [nzScroll]="{x: '600px', y: '240px'}">
            <thead>
                <tr>
                    <th nzLeft>{{'general.type' | translate}}</th>
                    <th nzWidth="60px" nzLeft>{{'general.box' | translate}}</th>
                    <th>{{'general.userEmail' | translate}}</th>
                    <th>{{'monitor.problems.problemType' | translate}}</th>
                    <th>{{'general.date' | translate}}</th>
                    <th nzWidth="60px" nzRight></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let problem of problems">
                    <td nzLeft>{{problem.type ? (('general.' + problem.type.toLowerCase()) | translate) : ''}}</td>
                    <td nzLeft>{{problem.box_id}}</td>
                    <td>{{problem.user_uuid && problem.user_uuid !== 'UNKNOWN' ? problem.user_email : 'general.otherTenantUser' | translate}}</td>
                    <td>{{('general.status.' + problem.problem_type) | translate}}</td>
                    <td>{{problem.timestamp | date: 'short': '': helpers.getLanguage()}}</td>
                    <td nzRight>
                        <button nz-button nzType="text" nz-dropdown [nzDropdownMenu]="notifyMenu">
                            <span nz-icon nzType="more"></span>
                        </button>
                        <nz-dropdown-menu #notifyMenu="nzDropdownMenu">
                            <ul nz-menu nzSelectable>
                                <ng-container *ngFor="let notifyItem of notifyMenuItems">
                                    <li *ngIf="checkRole(notifyItem)" nz-menu-item
                                        (click)="showModal(notifyItem, problem)">
                                        {{notifyItem.translate| translate}}
                                    </li>
                                </ng-container>
                            </ul>
                        </nz-dropdown-menu>
                    </td>
                </tr>
            </tbody>
        </nz-table>
    </ng-container>


    <nz-modal [(nzVisible)]="isModalVisible" nzTitle="{{notify.translate| translate}}" (nzOnCancel)="cancel()">
        <ng-container *nzModalContent>
            
            <div *ngIf="users.length != 0 || tempUsers.length != 0; else noData">
                <nz-input-group [nzSuffix]="textAreaClearTpl" class="ant-input-affix-wrapper-textarea-with-clear-btn">
                    <textarea nz-input [(ngModel)]="message" [placeholder]="'general.addMessage' | translate"></textarea>
                </nz-input-group>
                <ng-template #textAreaClearTpl>
                    <span
                    nz-icon
                    class="ant-input-clear-icon"
                    nzTheme="fill"
                    nzType="close-circle"
                    *ngIf="message"
                    (click)="message = ''"></span>
                </ng-template>
                <nz-select *ngIf="users.length != 0" nzMode="tags" 
                    [nzPlaceHolder]="'general.selectUser' | translate" 
                    [(ngModel)]="tempUsers" 
                    (ngModelChange)="sendResults.clear()"
                    class="mt-3 w-100">
                    <nz-option *ngFor="let user of users" [nzLabel]="user.first_name + ' ' + user.last_name" [nzValue]="user.uuid"></nz-option>
                </nz-select>
                <ul class="p-0 mt-2">
                    <li *ngFor="let result of sendResults | keyvalue" class="itemList" [ngClass]="result.value ? 'text-success' : 'text-danger'">
                      {{showResultMessage(result)}}
                    </li>
                </ul>
                <p class="mt-3 mb-0" *ngIf="!isConfirmLoading">{{notify.translateConfirmTitle | translate}}</p>
            </div>
               
        </ng-container>
        <div *nzModalFooter>
            <button class="me-1" nz-button nzType="default" (click)="cancel()">{{'general.cancel' | translate}}</button>
            <button nz-button nzType="primary" [ngClass]="{'d-none': sendResults.size === 0}" (click)="cancel()">{{'general.done' | translate}}</button>
            <button [disabled]="tempUsers.length == 0" nz-button nzType="primary" (click)="confirm(problem)"
                [nzLoading]="isConfirmLoading" [ngClass]="{'d-none': sendResults.size != 0}">{{'general.confirm' | translate}}</button>
        </div>
    </nz-modal>
</nz-card>

<ng-template #title>
	<h5 class="title">{{'monitor.problems.title' | translate | uppercase}}
		<small *ngIf="lockerDescription">{{lockerDescription}}</small>
	</h5>
    <span class="text-muted subtitle">{{'monitor.problems.subtitle' | translate}}</span>
</ng-template>

<ng-template #extras>
    <button *ngIf="showDelete" class="ms-2" nz-button
        nz-popconfirm
        nzPopconfirmTitle="{{'widgets.confirmDelete' | translate}}"
        nzPopconfirmPlacement="top"
        (nzOnConfirm)="confirmDelete()"
        ><span nz-icon [nzType]="'delete'"></span></button>
</ng-template>

<ng-template #noData>
	<nz-empty nzNotFoundImage="simple"></nz-empty>
</ng-template>

<ng-template #noVisibility>
	<nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="noLockersMessage"></nz-empty>
</ng-template>

<ng-template #noLockersMessage>
	<p>{{'general.noVisibilityForThisLocker' | translate}}<br><span>{{'general.pleaseContactAdmin' | translate}}</span></p>
</ng-template>