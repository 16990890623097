<nz-card widgetLocker [nzTitle]="title" [nzExtra]="extras" class="h-100">
    <div class="row">
        <div class="col-12 col-xxl-6">
            <nz-form-label>{{'monitor.control.servicePin' | translate}}</nz-form-label>
            <input readonly nz-input [ngModel]="servicePin" />
            <button class="mt-2" nz-button nzType="primary" (click)="generateServicePin()">
                {{'monitor.control.generateServicePin' | translate}}
            </button>
        </div>
        <div class="col-12 col-xxl-6 mt-2 mt-xxl-0" *ngIf="servicePin">
            <nz-form-label>{{'monitor.control.selectValidityPeriod' | translate}}</nz-form-label>
            <nz-range-picker [(ngModel)]="validityTime" (ngModelChange)="onChange($event)"></nz-range-picker>
        </div>
    </div>
    <div class="row mt-5">
        <div class="col-6">
            <button *ngIf="servicePin && validityTime" nz-button nzType="primary" 
                (click)="showModal()">
                {{'general.notify' | translate}}
            </button>
        </div>       
    </div>
</nz-card>

<ng-template #title>
	<h5 class="title">{{'monitor.control.title' | translate | uppercase}}
		<small *ngIf="lockerDescription">{{lockerDescription}}</small>
	</h5>
    <span class="text-muted subtitle">{{'monitor.control.subtitle' | translate}}</span>
</ng-template>

<ng-template #extras>
    <button *ngIf="showDelete" class="ms-2" nz-button
        nz-popconfirm
        nzPopconfirmTitle="{{'widgets.confirmDelete' | translate}}"
        nzPopconfirmPlacement="top"
        (nzOnConfirm)="confirmDelete()"
        ><span nz-icon [nzType]="'delete'"></span></button>
</ng-template>

<nz-modal [(nzVisible)]="isModalVisible" 
    [nzTitle]="'general.notifyTechnician' | translate"
    (nzOnCancel)="hideModal()">
    <ng-container *nzModalContent>
        <p>{{'monitor.control.formInstructions' | translate}}</p>
        <ng-container [ngTemplateOutlet]="form"></ng-container>
        <p *ngIf="showResp" [ngClass]="sentSuccess ? 'text-success' : 'text-danger'">{{getMessage()}}</p>
    </ng-container>
    <div *nzModalFooter>
        <button nz-button nzType="default" (click)="hideModal()">{{'general.cancel' | translate}}</button>
        <button nz-button nzType="primary" (click)="modalOk()" [nzLoading]="isConfirmLoading">{{'general.confirm' | translate}}</button>
    </div>
</nz-modal>

<ng-template #form>
    <form nz-form [formGroup]="validateForm">
        <nz-form-item *ngFor="let control of listOfControl; let i = index;">
            <nz-form-label nzRequired [nzXs]="24" [nzSm]="6" *ngIf="i === 0" [nzFor]="control.controlInstance">{{'general.emailAddress' | translate}}</nz-form-label>
            <nz-form-control [nzXs]="24" [nzSm]="18" [nzOffset]="i === 0 ? 0 : 6" [nzErrorTip]="'monitor.control.errors.requiredEmailAddress' | translate">
                <input class="email-input" nz-input [attr.id]="control.id" [formControlName]="control.controlInstance" />
                <span class="dynamic-delete-button" nz-icon nzType="minus-circle-o" (click)="removeField(control, $event)"></span>
            </nz-form-control>
        </nz-form-item>
        <nz-form-item>
            <nz-form-control [nzXs]="{ span: 24, offset: 0 }" [nzSm]="{ span: 18, offset: 6 }">
                <button nz-button nzType="dashed" (click)="addField($event)" class="add-button">
                    <span nz-icon nzType="plus"></span>
                    {{'monitor.control.addEmailAddress' | translate}}
                </button>
            </nz-form-control>
        </nz-form-item>
    </form>
</ng-template>
