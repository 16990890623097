import { JsonConverter, JsonCustomConvert, JsonConvert, JsonObject, JsonProperty } from "json2typescript";
import { Resp } from "./resp";
import { WidgetType, WidgetTypeConverter } from "./widget-type";

@JsonConverter
export class WidgetArrayConverter implements JsonCustomConvert<Array<Widget>> {
    serialize() {}
    deserialize(data: any): Array<Widget> {
        const jsonConvert: JsonConvert = new JsonConvert();
        return jsonConvert.deserializeArray(data, Widget);
    }
}

@JsonObject("Widget")
export class Widget {
    @JsonProperty("locker_uuid", String, true)
	locker_uuid: string = "";
    @JsonProperty("widget", WidgetTypeConverter, true)
	widget: WidgetType | undefined = undefined;

	dto() {
		return {
			locker_uuid: this.locker_uuid,
			widget: this.widget
		}
	}
}

@JsonObject("WidgetData")
export class WidgetData extends Resp {
    @JsonProperty("data", WidgetArrayConverter, true)
	data: Array<Widget> = [];
}
