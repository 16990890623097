import { JsonObject, JsonProperty } from "json2typescript";
import { Resp } from "./resp";

@JsonObject("BoolResp")
export class BoolResp extends Resp {
    @JsonProperty("data", Boolean, true)
    data: boolean = false;
}

@JsonObject("IntegerResp")
export class IntegerResp extends Resp {
    @JsonProperty("data", Number, true)
    data: number = 0;
}

@JsonObject("StringResp")
export class StringResp extends Resp {
    @JsonProperty("data", String, true)
    data: string = "";
}

@JsonObject("StringListResp")
export class StringListResp extends Resp {
    @JsonProperty("data", [String], true)
    data: Array<string> = [];
}