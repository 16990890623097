import { JsonConvert, JsonConverter, JsonCustomConvert, JsonObject, JsonProperty } from "json2typescript";
import { SerialPort, SerialPortConverter } from "./serial-port";
import { Board, BoardArrayConverter } from "./board";

@JsonConverter
export class VendorSpecificConverter implements JsonCustomConvert<VendorSpecific> {
	serialize() { }

	deserialize(data: any): VendorSpecific {
		const jsonConvert: JsonConvert = new JsonConvert();
		const response = jsonConvert.deserializeObject(data, VendorSpecific);
		return response;
	}
}


@JsonObject('VendorSpecific')
export class VendorSpecific {
	@JsonProperty('serialPort', SerialPortConverter, true)
	serialPort: SerialPort = new SerialPort();
	@JsonProperty('boards', BoardArrayConverter, true)
	boards: Array<Board> = [];
	@JsonProperty('project_name', String, true)
	project_name: string = "";

	dto() {
		return {
			serialPort: this.serialPort.dto(),
			boards: this.boards.map(b => b.dto()),
			project_name: this.project_name,
		}
	}
}