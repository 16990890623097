import { JsonConverter, JsonCustomConvert } from "json2typescript";


@JsonConverter
export class LockerEnvironmentConverter implements JsonCustomConvert<LockerEnvironment> {
	serialize(): any { }
	deserialize(data: any): LockerEnvironment {
		let resp: LockerEnvironment = LockerEnvironment.UNKNOWN;

		switch(data) {
			case "CORPORATE": {
				resp = LockerEnvironment.CORPORATE;
				break;
			}
			case "RESIDENTIAL": {
				resp = LockerEnvironment.RESIDENTIAL;
				break;
			}
			case "RETAIL": {
				resp = LockerEnvironment.RETAIL;
				break;
			}
			default: {
				resp = LockerEnvironment.UNKNOWN;
				break;
			}
		}

		return resp;
	}
}

export enum LockerEnvironment{
	CORPORATE = 'CORPORATE',
	RESIDENTIAL = 'RESIDENTIAL',
	RETAIL = 'RETAIL',
	UNKNOWN = "UNKNOWN"
}