import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { LockerService } from 'src/app/core/services/network/locker.service';

@Component({
	selector: 'app-locker-control',
	templateUrl: './locker-control.component.html',
	styleUrls: ['./locker-control.component.less']
})
export class LockerControlComponent implements OnInit, OnDestroy {
	@Input() showDelete: boolean = false;
	@Input() lockerDescription!: string;
	@Input() lockerUuid!: string;
	@Output() onDelete = new EventEmitter();

	subscriptions: Array<Subscription> = [];
	validityTime: any = null;
	servicePin: string = '';
	isModalVisible: boolean = false;
	isConfirmLoading: boolean = false;
	validateForm!: UntypedFormGroup;
	listOfControl: Array<{ id: number; controlInstance: string }> = [];
	emailAddresses: Array<string> = [];
	showResp: boolean = false;
	sentSuccess: boolean = false;

	constructor(private fb: UntypedFormBuilder,
		private lockerService: LockerService,
		private translate: TranslateService) { }

	ngOnInit(): void {
		this.validateForm = this.fb.group({});
		this.addField();
	}

	//TODO: change with request
	generateServicePin(): void {
		this.servicePin = Math.floor(Math.random() * (90000 - 10000) + 10000).toString();
	}

	showModal(): void {
		this.isModalVisible = true;
	}

	modalOk(): void {
		for (const i in this.validateForm.controls) {
			if (this.validateForm.controls.hasOwnProperty(i)) {
				this.validateForm.controls[i].markAsDirty();
				this.validateForm.controls[i].updateValueAndValidity();
			}
		}
		if (!this.validateForm.invalid) {
			this.isConfirmLoading = true;
			this.setEmailAddresses();
			this.subscriptions.push(
				this.lockerService.sendServicePin(this.lockerUuid, this.servicePin, this.emailAddresses).subscribe(
					{
						next: (resp) => {
							this.sentSuccess = resp;
							this.isConfirmLoading = false;
							this.showResp = true;
						},
						error: (err) => {
							console.error(err);
							this.sentSuccess = false;
							this.isConfirmLoading = false;
							this.showResp = true;
						}
					}
				)
			)
		}

	}

	hideModal(): void {
		this.isModalVisible = false;
		this.resetControls();
		this.showResp = false;
	}

	confirmDelete() {
		this.onDelete.emit();
	}

	onChange(result: Date[]): void {
		// console.log('onChange: ', result);
	}

	addField(event?: MouseEvent): void {
		if (event) {
			event.preventDefault();
		}
		const id = this.listOfControl.length > 0 ? this.listOfControl[this.listOfControl.length - 1].id + 1 : 0;
		const control = { id, controlInstance: `email${id}` }
		const index = this.listOfControl.push(control);
		this.validateForm.addControl(
			this.listOfControl[index - 1].controlInstance,
			new UntypedFormControl(null, Validators.required)
		);
	}

	removeField(control: { id: number, controlInstance: string }, event: MouseEvent): void {
		event.preventDefault();
		if (this.listOfControl.length > 1) {
			const index = this.listOfControl.indexOf(control);
			this.listOfControl.splice(index, 1);
			this.validateForm.removeControl(control.controlInstance);
		}
	}

	getMessage(): string {
		if (this.sentSuccess) {
			if (this.emailAddresses.length === 1) {
				return this.translate.instant('monitor.control.successes.sentNotification');
			} else {
				return this.translate.instant('monitor.control.successes.sentNotifications');
			}
		} else {
			if (this.emailAddresses.length === 1) {
				return this.translate.instant('monitor.control.errors.unableToSendNotification');
			} else {
				return this.translate.instant('monitor.control.errors.unableToSendNotifications');
			}
		}
	}

	private setEmailAddresses(): void {
		if (this.listOfControl && this.listOfControl.length != 0) {
			this.listOfControl.forEach(control => {
				if (this.validateForm.get(control.controlInstance)?.value) {
					this.emailAddresses.push(this.validateForm.get(control.controlInstance)?.value.trim());
				}
			})
		}
	}

	private resetControls(): void {
		if (this.listOfControl && this.listOfControl.length != 0) {
			this.listOfControl.forEach(control => {
				this.validateForm.removeControl(control.controlInstance);
			});
		}
		this.listOfControl = [];
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(s => s.unsubscribe());
	}
}
