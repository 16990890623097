


import { JsonConvert, JsonConverter, JsonCustomConvert, JsonObject, JsonProperty } from 'json2typescript';
import { LockFeedbackType, LockFeedbackTypeConverter } from './lock-feedback-type';

@JsonConverter
export class BaseConverter implements JsonCustomConvert<Base> {
	serialize(): any { }
	deserialize(data: any): Base {
		const jsonConvert: JsonConvert = new JsonConvert();
		return jsonConvert.deserializeObject(data, Base);
	}
}

@JsonObject('Base')
export class Base {
	@JsonProperty('model', String, true)
	model: string = '';

	@JsonProperty('software_version', String, true)
	softwareVersion: string = '';

	@JsonProperty('remote_access_id', String, true)
	remoteAccessId: string = '';

	@JsonProperty('lock_feedback_type', LockFeedbackTypeConverter)
	lockFeedbackType: LockFeedbackType = LockFeedbackType.UNKNOWN;

	@JsonProperty('max_opening_attempts', Number, true)
	maxOpeningAttempts: number = 0;

	@JsonProperty('transactions_expire_days', Number, true)
	transactionsExpireDays: number = 0;

	@JsonProperty('overdue_days', Number, true)
	overdueDays: number = 0;

	dto() {
		return {
			model: this.model,
			software_version: this.softwareVersion,
			remote_access_id: this.remoteAccessId,
			lock_feedback_type: this.lockFeedbackType === LockFeedbackType.UNKNOWN? null: this.lockFeedbackType,
			max_opening_attempts: this.maxOpeningAttempts,
			transactions_expire_days: this.transactionsExpireDays,
			overdue_days: this.overdueDays,
		}
	}
}
