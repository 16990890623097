import { JsonConvert, JsonConverter, JsonCustomConvert, JsonObject, JsonProperty } from "json2typescript";
import { EventType, EventTypeConverter } from "./event-type";
import { MapConverter } from "../helpers/converters/map-converter";
import { Resp } from "./resp";

@JsonConverter
export class OverdueArrayConverter implements JsonCustomConvert<Array<Overdue>> {
    serialize() {}
    deserialize(data: any): Array<Overdue> {
        const convert: JsonConvert = new JsonConvert;
        return convert.deserializeArray(data, Overdue);
    }
}

@JsonObject("Overdue")
export class Overdue {
    @JsonProperty("box_id", String, true)
    box_id: string = "";
    @JsonProperty("type", EventTypeConverter, true)
    type: EventType | undefined = undefined;
    @JsonProperty("user_uuid", String, true)
    user_uuid: string = "";
    @JsonProperty("user_email", String, true)
    user_email: string = "";
    @JsonProperty("timestamp", Number, true)
    timestamp: number = 0;
    @JsonProperty("attributes", MapConverter, true)
    attributes: Map<string, any> = new Map();
}

@JsonObject("OverdueArrayData")
export class OverdueArrayData extends Resp {
    @JsonProperty("data", OverdueArrayConverter, true)
    data: Array<Overdue> = [];
}