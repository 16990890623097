<nz-card widgetLocker [nzTitle]="title" [nzExtra]="extras" class="h-100" *ngIf="showCard">
    <ng-container *ngIf="!noLockerVisibility; else noVisibility" [ngTemplateOutlet]="table"></ng-container>
</nz-card>

<div *ngIf="!showCard">
    <h6>{{'general.users' | translate | uppercase}}</h6>
    <p class="text-muted subtitle-in-locker-status">{{'monitor.lockerUsers.subtitle' | translate}}</p>
    <ng-container [ngTemplateOutlet]="table"></ng-container>
</div>


<ng-template #table>
    <nz-table #expandedTable [nzData]="lockerUsers" [nzLoading]="loading" [nzFrontPagination]="false" nzBordered
        nzSize="small" [nzScroll]="{x: '600px', y: '240px'}">
        <thead>
            <tr>
                <th nzColumnKey="user_email">{{'general.userEmail' | translate}}</th>
                <th nzColumnKey="box" nzWidth="60px" nzRight>{{'general.box' | translate}}</th>
                <th nzColumnKey="type" nzWidth="100px" nzRight>{{'monitor.lockerUsers.type' | translate}}</th>
                <th nzRight nzWidth="60px"></th>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngFor="let operation of lockerUsers">
                <tr >
                    <td>
                        {{operation.user_uuid && operation.user_uuid !== 'UNKNOWN' ? operation.user_email : 'general.otherTenantUser' | translate}}
                    </td>
                    <td nzRight>{{operation.box_id}}</td>
                    <td nzRight>{{operation.type ? (('general.' + operation.type.toLowerCase()) | translate) : ''}}</td>
                    <td nzRight [nzExpand]="expandSet.has(operation.exchange_recipient_email!)" 
                        [nzShowExpand]="operation.exchange_recipient_email"
                        (nzExpandChange)="onExpandChange(operation.exchange_recipient_email!, $event)"></td>
                </tr>
                <tr [nzExpand]="expandSet.has(operation.exchange_recipient_email!)">
                    {{'monitor.lockerUsers.recipientUserForExchange' | translate}}:
                    {{operation.exchange_recipient_email}}
                </tr>
            </ng-container>
           
        </tbody>
    </nz-table>
</ng-template>

<ng-template #title>
	<h5 class="title">{{'general.users' | translate | uppercase}}
		<small *ngIf="lockerDescription">{{lockerDescription}}</small>
	</h5>
    <span class="text-muted subtitle">{{'monitor.lockerUsers.subtitle' | translate}}</span>
</ng-template>

<ng-template #extras>
    <button *ngIf="showDelete" class="ms-2" nz-button
        nz-popconfirm
        nzPopconfirmTitle="{{'widgets.confirmDelete' | translate}}"
        nzPopconfirmPlacement="top"
        (nzOnConfirm)="confirmDelete()"
        ><span nz-icon [nzType]="'delete'"></span></button>
</ng-template>

<ng-template #noVisibility>
    <div class="col-12">{{'general.noVisibilityForThisLocker' | translate}}</div>
</ng-template>