import { JsonConvert, JsonConverter, JsonCustomConvert, JsonObject, JsonProperty } from "json2typescript";
import { EventType, EventTypeConverter } from "./event-type";
import { Resp } from "./resp";

@JsonObject("Log")
export class Log {
    @JsonProperty("start", Number, true)
    start: number = 0;
    @JsonProperty("end", Number, true)
    end: number | null = null;
    @JsonProperty("box_id", String, true)
    boxId: string = '';
    @JsonProperty("type", EventTypeConverter, true)
    type: EventType | undefined = undefined;
}

@JsonConverter
export class LogArrayConverter implements JsonCustomConvert<Array<Log>> {
    serialize() { }
    deserialize(data: any): Array<Log> {
        const jsonConvert: JsonConvert = new JsonConvert();
        return jsonConvert.deserializeArray(data, Log);
    }
}

@JsonObject("LogList")
export class LogList {
    @JsonProperty("count", Number, true)
    count: number = 0;
    @JsonProperty("data",LogArrayConverter, true)
    data: Array<Log> = [];

    reset() {
        this.count = 0;
        this.data = [];
    }
}

@JsonConverter
export class LogListConverter implements JsonCustomConvert<LogList> {
    serialize() { }
    deserialize(data: any): LogList {
        const jsonConvert: JsonConvert = new JsonConvert();
        return jsonConvert.deserializeObject(data, LogList);
    }
}

@JsonObject("LogListResp")
export class LogListResp extends Resp {
    @JsonProperty("data", LogListConverter, true)
    data: LogList = new LogList();
}