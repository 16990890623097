import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Notification } from 'src/app/core/models/notification';
import { Subscription } from 'rxjs';
import { ProblemNotifyMenuConfig } from 'src/app/core/config/notify-menu-config';
import { Helpers } from 'src/app/core/helpers';
import { BackOfficeUser } from 'src/app/core/models/back-office-user';
import { NotifyMenu } from 'src/app/core/models/notify-menu';
import { Problem } from 'src/app/core/models/problem';
import { Role } from 'src/app/core/models/role';
import { Tenant } from 'src/app/core/models/tenant';
import { AdministrationService } from 'src/app/core/services/network/administration.service';
import { LockerService } from 'src/app/core/services/network/locker.service';
import { TenantService } from 'src/app/core/services/tenant.service';

@Component({
	selector: 'app-locker-problems',
	templateUrl: './locker-problems.component.html',
	styleUrls: ['./locker-problems.component.less']
})
export class LockerProblemsComponent implements OnInit {
	@Input() lockerUuid: string = "";
	@Input() lockerDescription!: string;
	@Input() showDelete: boolean = false;
	@Input() showNotification: boolean = true;

	@Output() onDelete = new EventEmitter();

	problems: Array<Problem> = [];
	loading: boolean = false;
	tenant: Tenant | null = new Tenant;
	subscriptions: Array<Subscription> = [];
	notifyMenuItems: Array<NotifyMenu> = ProblemNotifyMenuConfig;
	isModalVisible: boolean = false;
	isConfirmLoading: boolean = false;
	notify: NotifyMenu = new NotifyMenu;
	problem: Problem = new Problem;
	message: string = '';
	users: Array<BackOfficeUser> = [];
	sendResults: Map<string, boolean> = new Map();
	tempUsers: Array<string> = [];
	tempRole: Role | undefined = undefined;
	noLockerVisibility: boolean = false;
	helpers = Helpers;

	constructor(private lockerService: LockerService,
		private notification: NzNotificationService,
		private adminService: AdministrationService,
		private router: Router,
		private translate: TranslateService,
		private tenantService: TenantService) { }

	ngOnInit(): void {
		this.subscriptions.push(
			this.tenantService.tenant$.subscribe((tenant) => {
				this.tenant = tenant;
			})
		);
		this.getProblems();
	}

	/** Get the list of boxes with problem */
	getProblems() {
		this.loading = true;
		this.subscriptions.push(
			this.lockerService.getLockerProblems(this.lockerUuid).subscribe(resp => {
				this.problems = resp;
				this.loading = false;
			}, err => {
				console.error(err);
				this.loading = false;
				if (err.statusCode === 403 || err.status === 403) {
					this.noLockerVisibility = true;
				} else {
					if (this.showNotification) Helpers.errorNotification(this.notification, this.translate, 'monitor.problems.errors.unableToGetProblems');
				}
			})
		);
	}

	/** Get back office users by role and set tempRole with passed role
	 * @param role
	*/
	getBackOfficeUsersByRole(role: Role): void {
		this.subscriptions.push(
			this.adminService.getBackOfficeUsersByRole(role).subscribe(
				{
					next: (resp) => {
						this.users = resp;
						this.tempRole = role;
					},
					error: (err) => {
						console.error(err);
					}
				}
			)
		);
	}

	/** Methods called on user confirmation:
	 * send a notification for each selected back office users
	 * @param {Problem} problem
	*/
	confirm(problem: Problem) {
		this.isConfirmLoading = true;
		if (this.tempRole) {
			this.tempUsers.forEach((user) => {
				const notification: Notification = { box_id: problem.box_id, user_id: user, role: this.tempRole!, message: this.message };
				this.notifyProblem(notification);
			})
		} else {
			console.error("Role not selected");
			Helpers.errorNotification(this.notification, this.translate, 'general.genericError');
		}
		this.isConfirmLoading = false;
	}

	/** Send a problem notification to user matching notification's user_id
	 * @param {Notification} notification
	*/
	private notifyProblem(notification: Notification): void {
		const user = this.users.find(u => u.uuid === notification.user_id);
		const name = `${user?.first_name} ${user?.last_name}`;
		this.subscriptions.push(
			this.lockerService.notifyProblem(this.lockerUuid, notification).subscribe(
				{
					next: (resp) => {
						if (user) {
							this.sendResults.set(name, resp);
						} else if (notification.user_id === this.problem.user_uuid) {
							this.sendResults.set(notification.user_id, resp);
						}
					},
					error: (err) => {
						console.error(err);
						if (user) {
							this.sendResults.set(name, false);
						} else if (notification.user_id === this.problem.user_uuid) {
							this.sendResults.set(notification.user_id, false);
						}
					}
				}
			)
		);
	}

	/** Return success or error message to show to user */
	showResultMessage(result: { key: string, value: boolean }): string {
		if (result.value) {
			return this.translate.instant('general.sentNotifyTo') + ' ' + result.key;
		} else {
			return this.translate.instant('general.notSentNotifyTo') + ' ' + result.key;
		}
	}

	/** Close modal */
	cancel() {
		this.message = '';
		this.users = [];
		this.tempUsers = [];
		this.tempRole = undefined;
		this.isModalVisible = false;
		this.sendResults.clear();
	}

	/** Check rules to notify */
	checkRole(notify: NotifyMenu): boolean {
		return notify.rolesCanNotify?.some(n => n == this.tenant?.role);
	}

	showModal(notify: NotifyMenu, problem: Problem): void {
		if (notify.roleToNotify.includes(Role.CONCIERGE)) {
			this.getBackOfficeUsersByRole(Role.CONCIERGE);
		} else if (notify.roleToNotify.includes(Role.SERVICE)) {
			this.getBackOfficeUsersByRole(Role.SERVICE);
		} else if (notify.roleToNotify.includes(Role.GOD)) {
			this.getBackOfficeUsersByRole(Role.GOD);
		}
		this.notify = notify;
		this.problem = problem;
		this.isModalVisible = true;
	}

	confirmDelete() {
		this.onDelete.emit();
	}

	ngOnDestroy() {
		this.subscriptions.forEach(s => s.unsubscribe());
	}
}
