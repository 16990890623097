import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Subscription } from 'rxjs';
import { Helpers } from 'src/app/core/helpers';
import { UsersOperationsAnalysisObject } from 'src/app/core/models/users-operations-analysis-object';
import { StatisticsService } from 'src/app/core/services/network/statistics.service';

@Component({
	selector: 'app-users-operations-analysis',
	templateUrl: './users-operations-analysis.component.html',
	styleUrls: ['./users-operations-analysis.component.less']
})
export class UsersOperationsAnalysisComponent implements OnInit, OnDestroy {

	@Input() selectedLocker: string | undefined = undefined;
	@Input() selectedLocation: string | undefined = undefined;
	@Input() since: string = '';
	@Input() until: string = '';
	
	private subscriptions: Array<Subscription> = [];
	loading: boolean = false;
	statistics: UsersOperationsAnalysisObject = new UsersOperationsAnalysisObject;

	constructor(private statisticsService: StatisticsService, 
		private notification: NzNotificationService,
		private translate: TranslateService) { }

	ngOnInit(): void {
		this.getStatistics();
	}

	getStatistics(): void {
		this.loading = true;

		let locationUuid = undefined;
		let lockerUuid = undefined;
		if(this.selectedLocation && this.selectedLocation !== Helpers.allLocations){
			locationUuid = this.selectedLocation;
		}
		if(this.selectedLocker && this.selectedLocker !== Helpers.allLockers){
			lockerUuid = this.selectedLocker;
		}

		this.subscriptions.push(
		this.statisticsService.getUsersOperationsStatistics(this.since, this.until, locationUuid, lockerUuid)
			.subscribe(
				{
				next: resp => {
					this.statistics = resp;
					this.loading = false;
				},
				error: err => {
					console.error(err);
					this.statistics = new UsersOperationsAnalysisObject();
					if(err.statusCode === 403 || err.status === 403){
						Helpers.errorNotification(this.notification, this.translate, 'general.getStatisticsUnauthorized');
					}else {
						Helpers.errorNotification(this.notification, this.translate, 'statistics.errors.unableToGetUsersOperationsSatatistics');
					}
					this.loading = false;
				}
				}
			)
		)
	}

	onFiltersChange(since: string, until: string): void {
		this.since = since;
		this.until = until;
		this.getStatistics();
	}

	getDataTableForPdf(): Array<Array<string>> {
		let data: Array<Array<string>> = [];
		data.push(
			[this.translate.instant('statistics.tableHeaders.totalUsers'), this.statistics.active_users != null ? this.statistics.active_users : '-'],
			[this.translate.instant('statistics.tableHeaders.usersMadeOperations'), this.statistics.operations_users != null ? this.statistics.operations_users : '-'],
			[this.translate.instant('statistics.tableHeaders.operationsPerUserAverage'), this.statistics.operations_per_user_average != null ? this.statistics.operations_per_user_average.toFixed(2) : '-'],
			[this.translate.instant('statistics.tableHeaders.storageRangeAverage'), this.statistics.storage_range_average != null ? this.statistics.storage_range_average.toFixed(2) : '-'],
			[this.translate.instant('statistics.tableHeaders.overdueTimes'), this.statistics.overdue_times != null ? this.statistics.overdue_times : '-'],
			[this.translate.instant('statistics.tableHeaders.overdueDurationAverage'), this.statistics.overdue_duration_average != null ? this.statistics.overdue_duration_average : '-'],
			[this.translate.instant('statistics.tableHeaders.operationsTotalNumber'), this.statistics.operations_total_number != null ? this.statistics.operations_total_number : '-'],
			[this.translate.instant('statistics.tableHeaders.conciergeOrTestOperations'), this.statistics.concierge_or_test_operations != null ? this.statistics.concierge_or_test_operations : '-'],
		);
		return data;
	}

	exportToCsv(): void {
		let data = this.getDataTableForPdf();
		let csv = 'data:text/csv;charset=utf-8,';
		data.forEach(row => {
			let rowString = '';
			row.forEach(cell => {
				rowString += cell + ',';
			});
			rowString = rowString.slice(0, -1);
			csv += rowString + '\r\n';
		});
		let encodedUri = encodeURI(csv);
		let link = document.createElement('a');
		link.setAttribute('href', encodedUri);
		link.setAttribute('download', 'users-operations-analysis.csv');
		document.body.appendChild(link);
		link.click();
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(sub => sub.unsubscribe());
	}
}
