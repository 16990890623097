<ng-container *ngIf="tenantsService.tenants$ | async as tenants">
	<ng-container *ngIf="tenants.length > 0">
		<a nz-dropdown [nzDropdownMenu]="menu">
			<span *ngIf="tenantsService.tenant$ | async as tenant">{{tenant.name}}</span>&nbsp;
			<i nz-icon nzType="down"></i>
		</a>
		<nz-dropdown-menu #menu="nzDropdownMenu">
			<ul nz-menu>
				<li *ngFor="let tenant of tenants" nz-menu-item (click)="changeTenant(tenant)">
					{{tenant.name}}
				</li>
			</ul>
		</nz-dropdown-menu>
	</ng-container>
</ng-container>