import { JsonConverter, JsonCustomConvert, JsonConvert, JsonObject, JsonProperty } from "json2typescript";
import { BoxSize, BoxSizeConverter } from "./box-size";
import { BoxStatus, BoxStatusConverter } from "./box-status";
import { Resp } from "./resp";

@JsonConverter
export class BoxArrayConverter implements JsonCustomConvert<Array<Box>> {
    serialize() {}
    deserialize(data: any): Array<Box> {
        const jsonConvert: JsonConvert = new JsonConvert();
        return jsonConvert.deserializeArray(data, Box);
    }
}

@JsonObject("Box")
export class Box {
    @JsonProperty("id", String, true)
	id: string = "";
    @JsonProperty("size", BoxSizeConverter, true)
	size: BoxSize = BoxSize.UNKNOWN;
    @JsonProperty("status", BoxStatusConverter, true)
	status: BoxStatus = BoxStatus.FREE;
}

@JsonObject("BoxListData")
export class BoxListData extends Resp {
    @JsonProperty("data", BoxArrayConverter, true)
    data: Array<Box> = [];
}