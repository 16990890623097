import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalize'
})
/** Capitalize first character of passed string */
export class CapitalizePipe implements PipeTransform {

  transform(value:string): string {
    let first = value.substring(0,1).toUpperCase();
    return first + value.substring(1).toLowerCase(); 
  }

}
