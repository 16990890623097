<nz-card widgetLocker class="h-100" [nzTitle]="title">
    <form nz-form autocomplete="off" (submit)="updateAccount()">
        <!-- Avatar -->
        <nz-form-item>
           <nz-form-control>
               <app-section-cloudinary 
                   [(src)]="backOfficeUser.avatar"
                   [alignmentClass]="'text-center'"></app-section-cloudinary>
           </nz-form-control>
        </nz-form-item>
        <!-- First Name -->
        <nz-form-item>
            <nz-form-label nzRequired>{{'general.firstname' | translate}}</nz-form-label>
            <nz-form-control nzHasFeedback>
                <input nz-input type="text" [(ngModel)]="backOfficeUser.first_name" name="first_name">
            </nz-form-control>
        </nz-form-item>
        <!-- Last Name -->
        <nz-form-item>
            <nz-form-label nzRequired>{{'general.lastname' | translate}}</nz-form-label>
            <nz-form-control nzHasFeedback>
                <input nz-input type="text" [(ngModel)]="backOfficeUser.last_name" name="last_name">
            </nz-form-control>
        </nz-form-item>
        <nz-form-item class="text-center">
            <nz-form-control>
                <button class="mt-3" [disabled]="!backOfficeUser.first_name && !backOfficeUser.last_name" nz-button nzType="primary">{{'general.save' | translate }}</button>
            </nz-form-control>
        </nz-form-item>
    </form>
</nz-card>

<ng-template #title>
	<h5 class="title">{{'account.details' | translate | uppercase}}</h5>
</ng-template>
