import { JsonConverter, JsonCustomConvert, JsonConvert, JsonObject, JsonProperty } from "json2typescript";
import { Base, BaseConverter } from "./base";


@JsonConverter
export class ConfigurationBaseConverter implements JsonCustomConvert<ConfigurationBase> {
  serialize() { }

  deserialize(data: any): ConfigurationBase {
    const jsonConvert: JsonConvert = new JsonConvert();
    const response = jsonConvert.deserializeObject(data, ConfigurationBase);
    return response;
  }
}

@JsonObject('ConfigurationBase')
export class ConfigurationBase {
	@JsonProperty('base', BaseConverter)
	base: Base = new Base();

	@JsonProperty('processes', [String], true)
	processes: Array<string> = [];

	dto() {
		return {
			base: this.base.dto(),
			processes: this.processes
		}
	}
}