import { JsonConverter, JsonCustomConvert } from "json2typescript";

@JsonConverter
export class BoxStatusConverter implements JsonCustomConvert<BoxStatus> {
	serialize() { }
	deserialize(data: any): BoxStatus {
		switch (data) {
			case "BUSY":
				return BoxStatus.BUSY;
			case "OVERDUE":
				return BoxStatus.OVERDUE;
			case "BLOCKED":
				return BoxStatus.BLOCKED;
			case "RESERVED":
				return BoxStatus.RESERVED;
			case "FREE":
			default:
				return BoxStatus.FREE;
		}
	}
}

/**
 * Box statuses
 * - FREE
 * - BUSY
 * - OVERDUE
 * - BLOCKED
 * - RESERVED
 */
export enum BoxStatus {
	FREE = "FREE",
	BUSY = "BUSY",
	OVERDUE = "OVERDUE",
	BLOCKED = "BLOCKED",
	RESERVED = "RESERVED"
}