import { JsonConverter, JsonCustomConvert } from "json2typescript";

@JsonConverter
export class LockerStatusConverter implements JsonCustomConvert<LockerStatus | undefined> {
	serialize() { }
	deserialize(data: any): LockerStatus | undefined {
		switch (data) {
			case "ONLINE":
				return LockerStatus.ONLINE;
            case "OFFLINE":
                return LockerStatus.OFFLINE;
			default:
				return undefined;
		}
	}
}

/**
 * Locker statuses
 * - ONLINE
 * - OFFLINE
 */
export enum LockerStatus {
    ONLINE = "ONLINE",
    OFFLINE = "OFFLINE"
}
