import { JsonConverter, JsonCustomConvert } from "json2typescript";

@JsonConverter
export class LockFeedbackTypeConverter implements JsonCustomConvert<LockFeedbackType> {
	serialize(): any { }
	deserialize(data: any): LockFeedbackType {
		let resp: LockFeedbackType = LockFeedbackType.UNKNOWN;

		switch(data) {
			case "LOCK_OPEN_FEEDBACK_CLOSED": {
				resp = LockFeedbackType.LOCK_OPEN_FEEDBACK_CLOSED;
				break;
			}
			case "LOCK_OPEN_FEEDBACK_OPEN": {
				resp = LockFeedbackType.LOCK_OPEN_FEEDBACK_OPEN;
				break;
			}
			default: {
				resp = LockFeedbackType.UNKNOWN;
				break;
			}
		}

		return resp;
	}
}

export enum LockFeedbackType {
	LOCK_OPEN_FEEDBACK_CLOSED = "LOCK_OPEN_FEEDBACK_CLOSED",
	LOCK_OPEN_FEEDBACK_OPEN = "LOCK_OPEN_FEEDBACK_OPEN",
	UNKNOWN = "UNKNOWN"
}