import { JsonConverter, JsonCustomConvert } from "json2typescript";


@JsonConverter
export class ParityConverter implements JsonCustomConvert<Parity> {
	serialize(): any { }
	deserialize(data: any): Parity {
		let resp: Parity = Parity.No;

		switch(data) {
			case "Even": {
				resp = Parity.Even;
				break;
			}
			case "Odd": {
				resp = Parity.Odd;
				break;
			}
			case "No":
			default: {
				resp = Parity.No;
				break;
			}
		}

		return resp;
	}
}

export enum Parity {
	No = "No",
	Odd = "Odd",
	Even = "Even"
}