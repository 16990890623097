<nz-card widgetLocker 
    [nzTitle]="title" 
    class="h-100">
    <div class="text-end">
        <button nz-button [nzType]="'default'" (click)="exportToPdf()" [nzLoading]="loadingPdf" [disabled]="!deliveriesPerDay || deliveriesPerDay.length === 0">
            {{'statistics.exportToPdf' | translate}}
        </button>
    </div>
    <div class="loading-spin">
        <nz-spin *ngIf="loadingPdf" nzTip="{{'statistics.pdfFileGenerating' | translate}}..."></nz-spin>
    </div>
    <div id="deliveriesPerDayDiv" style="width: 100%; height: 400px" *ngIf="deliveriesPerDay && deliveriesPerDay.length > 0; else noData"></div>
</nz-card>

<ng-template #title>
	<h5 class="title text-truncate">{{'statistics.deliveriesPerDay' | translate | uppercase}}</h5>
    <span class="text-muted subtitle">{{'statistics.deliveriesPerDaySubtitle' | translate}}</span>
</ng-template>

<ng-template #noData>
        <nz-empty id="noData" nzNotFoundImage="simple"></nz-empty>
</ng-template>
