import { Any, JsonConvert, JsonConverter, JsonCustomConvert, JsonObject, JsonProperty } from "json2typescript";
import { Role, RoleConverter } from "./role";

@JsonConverter
export class TenantArrayConverter implements JsonCustomConvert<Array<Tenant>> {
    serialize() {}
    deserialize(data: any): Array<Tenant> {
        const jsonConvert: JsonConvert = new JsonConvert();
        return jsonConvert.deserializeArray(data, Tenant);
    }
}

@JsonObject("Tenant")
export class Tenant {
    @JsonProperty("uuid", String, true)
	uuid: string = "";
    @JsonProperty("name", String, true)
	name: string = "";
    @JsonProperty("logo", String, true)
	logo: string = "";
    @JsonProperty("app_configuration", Any, true)
	app_configuration: any = {};
    @JsonProperty("role", RoleConverter, true)
	role: Role | undefined= undefined;
	@JsonProperty("api_key", String)
	api_key: string = ""

	dto() {
		return {
			uuid: this.uuid,
			name: this.name,
			logo: this.logo,
			app_configuration: this.app_configuration,
			role: this.role,
			api_key: this.api_key
		}
	}

}