import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Subscription } from 'rxjs';
import { Helpers } from 'src/app/core/helpers';
import { LockerUser } from 'src/app/core/models/locker-user';
import { LockerService } from 'src/app/core/services/network/locker.service';

@Component({
	selector: 'app-users-per-locker',
	templateUrl: './users-per-locker.component.html',
	styleUrls: ['./users-per-locker.component.less']
})
export class UsersPerLockerComponent implements OnInit, OnDestroy {
	@Input() lockerUuid = "";
	@Input() lockerDescription!: string;
	@Input() showDelete: boolean = false;
	@Input() showCard: boolean = false;
	@Input() showNotification: boolean = true;

	@Output() onDelete = new EventEmitter();

	subscriptions: Array<Subscription> = [];
	lockerUsers = new Array<LockerUser>();
	loading: boolean = false;
	expandSet = new Set<string>();
	noLockerVisibility: boolean = false;

	constructor(private lockerService: LockerService,
		private router: Router,
		private notification: NzNotificationService,
		private translate: TranslateService,
		private alert: NzModalService) { }

	ngOnInit(): void {
		this.getLockerUsersActions();
	}

	getLockerUsersActions() {
		this.loading = true;
		this.subscriptions.push(
			this.lockerService.getLockerUsersActions(this.lockerUuid).subscribe(resp => {
				this.lockerUsers = resp;
				this.loading = false;
			}, err => {
				console.error(err);
				this.loading = false;
				if (err.statusCode === 403 || err.status === 403) {
					this.noLockerVisibility = true;
				} else {
					if (this.showNotification) {
						Helpers.errorNotification(this.notification, this.translate, 'monitor.lockerUsers.errors.unabletoGetLockerUserOperations');
					}
				}
			})
		);
	}

	confirmDelete() {
		this.onDelete.emit();
	}

	onExpandChange(exchangeRecepientEmail: string, checked: boolean): void {
		if (checked) {
			this.expandSet.add(exchangeRecepientEmail);
		} else {
			this.expandSet.delete(exchangeRecepientEmail);
		}
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(s => s.unsubscribe());
	}
}
