import { JsonConvert, JsonConverter, JsonCustomConvert, JsonObject, JsonProperty } from "json2typescript";
import { MapConverter } from "../helpers/converters/map-converter";
import { Gender, GenderConverter } from "./gender";
import { Resp } from "./resp";
import { UserStatus, UserStatusConverter } from "./user-status";

@JsonConverter
export class PlatformUserArrayConverter implements JsonCustomConvert<Array<PlatformUser>> {
    serialize() {}
    deserialize(data: any): Array<PlatformUser> {
        const jsonConvert: JsonConvert = new JsonConvert();
        return jsonConvert.deserializeArray(data, PlatformUser);
    }
    
}

@JsonConverter
export class PlatformUserConverter implements JsonCustomConvert<PlatformUser> {
    serialize() {}
    deserialize(data: any): PlatformUser {
        const jsonConvert: JsonConvert = new JsonConvert();
        return jsonConvert.deserializeObject(data, PlatformUser);
    }
}

@JsonObject("PlatformUser")
export class PlatformUser {
    @JsonProperty("uuid", String, true)
    uuid: string = "";
    @JsonProperty("avatar", String, true)
    avatar: string | null = null;
    @JsonProperty("email", String, true)
    email: string = "";
    @JsonProperty("email_verified", Boolean, true)
    emailVerified: boolean = false;
    @JsonProperty("first_name", String, true)
    firstName: string = "";
    // @JsonProperty("middle_name", String, true)
    // middleName: string | null = null;
    @JsonProperty("last_name", String, true)
    lastName: string = "";
    @JsonProperty("gender", GenderConverter, true)
    gender: Gender | null = Gender.OTHER;
    @JsonProperty("phone_number", String, true)
    phoneNumber: string | null = null;
    @JsonProperty("preferred_language", String, true)
    preferredLanguage: string = "it";
    @JsonProperty("status", UserStatusConverter, true)
    status: UserStatus = UserStatus.REGISTERED;
    @JsonProperty("metadata", MapConverter, true)
	metadata: Map<string, any> | null = null;

    dto() {
        return {
            id: this.uuid,
			email: this.email,
			email_verifield: this.emailVerified,
			firstName: this.firstName,
			// middleName: this.middleName,
			lastName: this.lastName,
			gender: this.gender,
			phoneNumber: this.phoneNumber,
			preferredLanguage: this.preferredLanguage,
            status: this.status,
            metadata: this.metadata
        }
    }

    /** Return right body for the creation and updating requests */
    requestDto() {
        return {
            avatar: this.avatar ? this.avatar : null,
            email: this.email,
            first_name: this.firstName,
            // middle_name: user.middleName,
            last_name: this.lastName,
            gender: this.gender,
            phone_number: this.phoneNumber,
            preferred_language: this.preferredLanguage,
            //metadata: this.metadata
        }
    }
}

@JsonObject("PlatformUsersArrayData")
export class PlatformUsersArrayData extends Resp {
    @JsonProperty("data", PlatformUserArrayConverter, true)
	data: Array<PlatformUser> = [];
}

@JsonObject("PlatformUserData")
export class PlatformUserData extends Resp {
    @JsonProperty("data", PlatformUserConverter, true)
    data: PlatformUser = new PlatformUser;
}

@JsonConverter
export class UsersListConverter implements JsonCustomConvert<PlatformUsersList> {
    serialize() {}
    deserialize(data: any): PlatformUsersList {
        const jsonConvert: JsonConvert = new JsonConvert();
        return jsonConvert.deserializeObject(data, PlatformUsersList);
    }
}

@JsonObject("PlatformUsersList")
export class PlatformUsersList {
    @JsonProperty("count", Number, true)
    count: number = 0;
    @JsonProperty("data", PlatformUserArrayConverter, true)
    data: Array<PlatformUser> = [];
}

@JsonObject("PlatformUsersListData")
export class PlatformUsersListData extends Resp {
    @JsonProperty("data", UsersListConverter, true)
	data: PlatformUsersList = new PlatformUsersList();
}