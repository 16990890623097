import { JsonConvert, JsonConverter, JsonCustomConvert, JsonObject, JsonProperty } from "json2typescript";
import { Resp } from "./resp";

@JsonObject('AnalysisObject')
export class AnalysisObject {
    @JsonProperty('key', String)
    key: string = '';
    @JsonProperty('value', Number)
    value: number = 0;
}

@JsonConverter
export class AnalysisObjectConverter implements JsonCustomConvert<AnalysisObject> {
    serialize() {}
    deserialize(data: any): AnalysisObject {
        const jsonConvert: JsonConvert = new JsonConvert();
        return jsonConvert.deserializeObject(data, AnalysisObject);
    }
}

@JsonConverter
export class AnalysisObjectArrayConverter implements JsonCustomConvert<Array<AnalysisObject>> {
    serialize() { }
    deserialize(data: any): Array<AnalysisObject> {
        const jsonConvert: JsonConvert = new JsonConvert();
        return jsonConvert.deserializeArray(data, AnalysisObject);
    }
}

@JsonObject("AnalysisObjectArrayData")
export class AnalysisObjectArrayData extends Resp {
    @JsonProperty("data", AnalysisObjectArrayConverter, true)
    data: Array<AnalysisObject> = [];
}