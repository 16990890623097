import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from './loader/loader.component';
import { LanguageSelectorComponent } from './language-selector/language-selector.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { VersionComponent } from './version/version.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgZorroModule } from '../core/modules/ng-zorro/ng-zorro.module';
import { TenantSelectorComponent } from './tenant-selector/tenant-selector.component';
import { LockerMonitorComponent } from './locker-monitor/locker-monitor.component';
import { SafePipe } from '../core/pipes/safe.pipe';
import { ActionsOnLockerStatComponent } from './statistics/actions-on-locker-stat/actions-on-locker-stat.component';
import { AverageBoxOccupancyComponent } from './statistics/average-box-occupancy/average-box-occupancy.component';
import { LockersListTableComponent } from './lockers-list-table/lockers-list-table.component';
import { RouterModule } from '@angular/router';
import { UsersPerLockerComponent } from './users-per-locker/users-per-locker.component';
import { LockerLogsComponent } from './locker-logs/locker-logs.component';
import { LockerOverdueComponent } from './locker-overdue/locker-overdue.component';
import { LockerProblemsComponent } from './locker-problems/locker-problems.component';
import { LockerConnectionStatusComponent } from './locker-connection-status/locker-connection-status.component';
import { CapitalizePipe } from '../core/pipes/capitalize.pipe';
import { ShowBoxesStatusComponent } from './show-boxes-status/show-boxes-status.component';
import { ConnectionStatusComponent } from './connection-status/connection-status.component';
import { WidgetLockerDirective } from '../core/directives/widget-locker.directive';
import { LockerControlComponent } from './locker-control/locker-control.component';
import { CloudinaryComponent } from './cloudinary/cloudinary.component';
import { AccountEditComponent } from './account-edit/account-edit.component';
import { LockerBoxDirective } from '../core/directives/locker-box.directive';
import { BoxSizeAnalysisComponent } from './statistics/box-size-analysis/box-size-analysis.component';
import { UsersOperationsAnalysisComponent } from './statistics/users-operations-analysis/users-operations-analysis.component';
import { DeliveriesPerHourComponent } from './statistics/deliveries-per-hour/deliveries-per-hour.component';
import { ReturnsPerHourComponent } from './statistics/returns-per-hour/returns-per-hour.component';
import { DeliveriesPerDayComponent } from './statistics/deliveries-per-day/deliveries-per-day.component';
import { ReturnsPerDayComponent } from './statistics/returns-per-day/returns-per-day.component';
import { OverduesTrendComponent } from './statistics/overdues-trend/overdues-trend.component';
import { AccountLockersComponent } from './account-lockers/account-lockers.component';
import { RedZoomModule } from 'ngx-red-zoom';



@NgModule({
  declarations: [
    LoaderComponent,
    LanguageSelectorComponent,
    SpinnerComponent,
    VersionComponent,
    TenantSelectorComponent,
    LockerMonitorComponent,
    SafePipe,
    ActionsOnLockerStatComponent,
    AverageBoxOccupancyComponent,
    BoxSizeAnalysisComponent,
    LockersListTableComponent,
    UsersPerLockerComponent,
    LockerLogsComponent,
    LockerOverdueComponent,
    LockerProblemsComponent,
    LockerConnectionStatusComponent,
    CapitalizePipe,
    ShowBoxesStatusComponent,
    ConnectionStatusComponent,
    WidgetLockerDirective,
    LockerControlComponent,
    CloudinaryComponent,
    AccountEditComponent,
    LockerBoxDirective,
    UsersOperationsAnalysisComponent,
    DeliveriesPerHourComponent,
    ReturnsPerHourComponent,
    DeliveriesPerDayComponent,
    ReturnsPerDayComponent,
    OverduesTrendComponent,
    AccountLockersComponent
  ],
  imports: [
    CommonModule,
    NgZorroModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    RedZoomModule
  ],
  exports: [
    LoaderComponent,
    LanguageSelectorComponent,
    SpinnerComponent,
    VersionComponent,
    TenantSelectorComponent,
    LockerMonitorComponent,
    ActionsOnLockerStatComponent,
    AverageBoxOccupancyComponent,
    LockersListTableComponent,
    UsersPerLockerComponent,
    LockerLogsComponent,
    LockerOverdueComponent,
    LockerProblemsComponent,
    LockerConnectionStatusComponent,
    ShowBoxesStatusComponent,
    ConnectionStatusComponent,
    WidgetLockerDirective,
    LockerControlComponent,
    CloudinaryComponent,
    AccountEditComponent,
    AccountLockersComponent,
    SafePipe,
    LockerBoxDirective,
    BoxSizeAnalysisComponent,
    UsersOperationsAnalysisComponent,
    DeliveriesPerHourComponent,
    ReturnsPerHourComponent,
    DeliveriesPerDayComponent,
    ReturnsPerDayComponent,
    OverduesTrendComponent
  ],
})
export class LayoutModule { }
