import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subscription, timer } from 'rxjs';
import { LockerStatus } from "src/app/core/models/locker-status.enum";
import { ConnectionStatusComponent } from '../connection-status/connection-status.component';

@Component({
	selector: 'app-locker-connection-status',
	templateUrl: './locker-connection-status.component.html',
	styleUrls: ['./locker-connection-status.component.less']
})
export class LockerConnectionStatusComponent implements OnInit, OnDestroy {
	@ViewChild(ConnectionStatusComponent) connectionStatusComponent!: ConnectionStatusComponent;
	@Input() lockerUuid: string = "";
	@Input() lockerDescription!: string;
	@Input() showDelete: boolean = false;

	@Output() onDelete = new EventEmitter();

	status: LockerStatus = LockerStatus.OFFLINE;
	loading: boolean = false;
	subscriptions: Array<Subscription> = [];

	constructor() { }

	ngOnInit(): void {
		// Refresh connectivity status every 5 minutes
		this.subscriptions.push(
			timer(300000, 300000).subscribe(() => {
				this.onRefresh();
				console.info("Locker's connectivity status refreshed");
			})
		);
	}

	onRefresh() {
		this.connectionStatusComponent.onRefresh();
	}

	confirmDelete() {
		this.onDelete.emit();
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(s => s.unsubscribe());
	}
}
