import { JsonConverter, JsonCustomConvert } from "json2typescript";

@JsonConverter
export class EventTypeConverter implements JsonCustomConvert<EventType | undefined> {
    serialize(){}
    deserialize(data: any): EventType | undefined {
        switch(data){
            case "DELIVERY":
                return EventType.DELIVERY;
            case "RETURN":
                return EventType.RETURN;
            case "DEPOSIT":
                return EventType.DEPOSIT;
            case "EXCHANGE":
                return EventType.EXCHANGE;
            case "RESERVATION":
                return EventType.RESERVATION;
            case "DELEGATION":
                return EventType.DELEGATION;
            default:
                return undefined;
        }
    }
}

/**
 * Event types
 * - DELIVERY
 * - RETURN
 * - DEPOSIT
 * - EXCHANGE
 * - RESERVATION
 * - DELEGATION
 */
export enum EventType {
    DELIVERY = "DELIVERY", //ritiri
    RETURN = 'RETURN', // resi
    DEPOSIT = 'DEPOSIT',
    EXCHANGE = 'EXCHANGE',
    RESERVATION = 'RESERVATION',
    DELEGATION = 'DELEGATION'
}