import { JsonConverter, JsonCustomConvert, JsonConvert, JsonObject, JsonProperty } from "json2typescript";
import { ConfigurationBase } from "./configuration-base";
import { LockerBox, LockerBoxArrayConverter } from "./locker-box";
import { Appearance, AppearanceConverter } from "./appearance";
import { LockerEnvironment, LockerEnvironmentConverter } from "./environment";
import { VendorSpecific, VendorSpecificConverter } from "./vendor-specific";


@JsonConverter
export class ConfigurationConverter implements JsonCustomConvert<Configuration> {
	serialize() { }

	deserialize(data: any): Configuration {
		const jsonConvert: JsonConvert = new JsonConvert();
		const response = jsonConvert.deserializeObject(data, Configuration);
		return response;
	}
}

@JsonObject('Configuration')
export class Configuration extends ConfigurationBase {
	@JsonProperty('environment', LockerEnvironmentConverter, true)
	environment: LockerEnvironment = LockerEnvironment.CORPORATE;

	@JsonProperty('boxes', LockerBoxArrayConverter, true)
	boxes: Array<LockerBox> = [];

	@JsonProperty('appearance', AppearanceConverter, true)
	appearance: Appearance = new Appearance();

	@JsonProperty('vendor_specific', VendorSpecificConverter, true)
	vendor_specific: VendorSpecific = new VendorSpecific();

	dto() {
		return {
			base: this.base.dto(),
			processes: this.processes,
			environment: this.environment === LockerEnvironment.UNKNOWN? null: this.environment,
			boxes: this.boxes.length > 0 ? this.boxes.map(b => b.dto()) : null,
			appearance: this.appearance.dto() || null,
			vendor_specific: this.vendor_specific.dto()
		}
	}
}