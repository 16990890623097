import { JsonConvert, JsonConverter, JsonCustomConvert, JsonObject, JsonProperty } from "json2typescript";
import { BoxSize, BoxSizeConverter } from "../box-size";
import { BoxType, BoxTypeConverter } from "./box-type";

@JsonConverter
export class LockerBoxArrayConverter implements JsonCustomConvert<Array<LockerBox>> {
  serialize() { }

  deserialize(data: any): Array<LockerBox> {
    const jsonConvert: JsonConvert = new JsonConvert();
    const response = jsonConvert.deserializeArray(data, LockerBox);
    return response;
  }
}

@JsonObject('LockerBox')
export class LockerBox {
	@JsonProperty('number', Number, true)
	number: number = 0;
  
	@JsonProperty('size', BoxSizeConverter, true)
	size: BoxSize = BoxSize.UNKNOWN;
  
	@JsonProperty('type', BoxTypeConverter, true)
	type: BoxType = BoxType.NORMAL;

	dto() {
		return {
			number: this.number,
			size: this.size === BoxSize.UNKNOWN? null: this.size,
			type: this.type
		}
	}
}