<nz-card widgetLocker 
    [nzTitle]="title" 
    [nzExtra]="extraTemplate"
    class="h-100">
    <div class="container" *ngIf="boxes && boxes.size > 0; else empty">
        <div class="row gx-5 gy-5">
            <div class="col-12 col-lg-6" *ngFor="let box of boxes | keyvalue; let i = index">
                <nz-card [appBox]="box.key" [nzTitle]="boxCardTitle">
                    <nz-statistic
                    [nzValue]="box.value"
                    [nzPrefix]="prefixTplOne"
                  ></nz-statistic>
                  <ng-template #prefixTplOne><span nz-icon nzType="field-time" nzTheme="outline"></span></ng-template>
                  <ng-template #boxCardTitle>
                    <p class="mb-0">Box {{'statistics.' + box.key.toUpperCase() | translate}}</p>
                </ng-template>
                </nz-card>
            </div>
        </div>
    </div>
</nz-card>

<ng-template #title>
	<h5 class="title text-truncate">{{'statistics.averageBoxOccupationTime' | translate | uppercase}}</h5>
</ng-template>

<ng-template #extraTemplate>
    <nz-select [(ngModel)]="selectedLocker" (ngModelChange)="onSelectChange()" [nzPlaceHolder]="'home.selectLocker' | translate">
        <nz-option *ngFor="let locker of lockers.data" [nzValue]="locker.uuid" [nzLabel]="locker.name"></nz-option>
    </nz-select>
</ng-template>

<ng-template #empty>
    <nz-empty nzNotFoundImage="simple"></nz-empty>
</ng-template>
