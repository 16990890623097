import { JsonConverter, JsonCustomConvert } from "json2typescript";

@JsonConverter
export class RoleConverter implements JsonCustomConvert<Role | undefined> {
	serialize() { }
	deserialize(data: any): Role | undefined {
		switch (data) {
			case "GOD":
				return Role.GOD;
			case "SUPER_ADMIN":
				return Role.SUPER_ADMIN;
			case "ADMIN":
			return Role.ADMIN;
			case "CONCIERGE":
				return Role.CONCIERGE;
			case "SERVICE":
				return Role.SERVICE;
			case "USER":
				return Role.USER;
			default:
				return undefined;
		}
	}
}

/**
 * Roles
 * - GOD
 * - SUPER_ADMIN
 * - ADMIN
 * - CONCIERGE
 * - SERVICE
 * - USER
 */
export enum Role {
	GOD = "GOD",
	SUPER_ADMIN = "SUPER_ADMIN",
	ADMIN = "ADMIN",
	CONCIERGE = "CONCIERGE",
	SERVICE = "SERVICE",
	USER = "USER"
}