import { AfterViewInit, Directive, ElementRef, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ColorUtils } from '../helpers/color-utils';
import { Tone } from '../models/tone.enum';
import { TenantService } from '../services/tenant.service';

@Directive({
	selector: '[widgetLocker]'
})
export class WidgetLockerDirective implements AfterViewInit, OnDestroy {
	subscriptions: Array<Subscription> = [];

	constructor(private el: ElementRef, private tenantService: TenantService) { }

	ngAfterViewInit(): void {
		this.subscriptions.push(
			this.tenantService.tenant$.subscribe({
				next: () => {
					this.setColors();
				}
			})
		);
	}

	// Set widget customization
	setColors() {
		// Get sidebar logo
		const sideBarLogo: Element | null = document.querySelector('.sidebar-logo');
		if (sideBarLogo) {
			// Get sidebar logo computed background color
			const style: CSSStyleDeclaration = getComputedStyle(sideBarLogo);
			const bgColor: string = style.backgroundColor;
			// Get widget header
			const widgetHeader: Element | null = this.el.nativeElement.querySelector('.ant-card-head');
			if (widgetHeader) {
				// Set same backgound of sidebar logo
				widgetHeader.setAttribute('style', `background-color: ${bgColor} !important`);
				// Get color tone of sidebar logo
				const colorTone: Tone = ColorUtils.colorTone(bgColor);
				// Calculate correct class
				const colorClass: string = colorTone === Tone.DARK ? ColorUtils.lightClass : ColorUtils.darkClass;
				// Get widget title and subtitle
				const widgetTitle: Element | null = widgetHeader.querySelector('.title');
				const widgetSubtitle: Element | null = widgetHeader.querySelector('.subtitle');
				// Add class to title and subtitle
				if (widgetTitle) {
					widgetTitle.classList.remove(ColorUtils.lightClass);
					widgetTitle.classList.remove(ColorUtils.darkClass);
					widgetTitle.classList.add(colorClass);
				}
				if (widgetSubtitle) {
					widgetSubtitle.classList.remove(ColorUtils.lightClass);
					widgetSubtitle.classList.remove(ColorUtils.darkClass);
					widgetSubtitle.classList.add(colorClass);
				}
			}
		}
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(s => s.unsubscribe());
	}
}