import { JsonConvert, JsonConverter, JsonCustomConvert, JsonObject, JsonProperty } from "json2typescript";
import { EventType, EventTypeConverter } from "./event-type";
import { MapConverter } from "../helpers/converters/map-converter";
import { ProblemType, ProblemTypeConverter } from "./problem-type";
import { Resp } from "./resp";

@JsonConverter
export class ProblemArrayConverter implements JsonCustomConvert<Array<Problem>> {
    serialize() {}
    deserialize(data: any): Array<Problem> {
        const convert: JsonConvert = new JsonConvert;
        return convert.deserializeArray(data, Problem);
    }
}

@JsonObject("Problem")
export class Problem {
    @JsonProperty("box_id", String, true)
    box_id: string = "";
    @JsonProperty("user_uuid", String, true)
    user_uuid: string = "";
    @JsonProperty("user_email", String, true)
    user_email: string = "";
    @JsonProperty("type", EventTypeConverter, true)
    type: EventType | undefined = undefined;
    @JsonProperty("problem_type", ProblemTypeConverter, true)
    problem_type: ProblemType | undefined = undefined;
    @JsonProperty("attributes", MapConverter, true)
    attributes: Map<string, any> = new Map();
    @JsonProperty("timestamp", Number, true)
    timestamp: number = 0;
}

@JsonObject("ProblemArrayData")
export class ProblemArrayData extends Resp {
    @JsonProperty("data", ProblemArrayConverter, true)
    data: Array<Problem> = [];
}