import { JsonConverter, JsonCustomConvert } from "json2typescript";

@JsonConverter
export class BoxSizeConverter implements JsonCustomConvert<BoxSize> {
	serialize() { }
	deserialize(data: any): BoxSize {
		switch (data) {
			case "XS":
				return BoxSize.XS;
			case "S":
				return BoxSize.S;
			case "M":
				return BoxSize.M;
			case "L":
				return BoxSize.L;
			case "XL":
				return BoxSize.XL;
			case "UNKNOWN":
			default:
				return BoxSize.UNKNOWN;
		}
	}
}


export enum BoxSize {
	XS = 'XS',
	S = 'S',
	M = 'M',
	L = 'L',
	XL = 'XL',
	UNKNOWN = 'UNKNOWN',
}