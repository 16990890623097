import { Component, Inject, Input, NgZone, OnInit, PLATFORM_ID } from '@angular/core';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5lang_en_US from "@amcharts/amcharts5/locales/en_US";
import am5lang_it_IT from "@amcharts/amcharts5/locales/it_IT";
import { isPlatformBrowser } from '@angular/common';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { TranslateService } from '@ngx-translate/core';
import { Helpers } from 'src/app/core/helpers';
import { MonitorLockerList } from 'src/app/core/models/locker/monitor-locker';
import { Subscription } from 'rxjs';


@Component({
	selector: 'app-actions-on-locker-stat',
	templateUrl: './actions-on-locker-stat.component.html',
	styleUrls: ['./actions-on-locker-stat.component.less']
})
export class ActionsOnLockerStatComponent implements OnInit {

	@Input() lockers: MonitorLockerList = new MonitorLockerList();

	private root!: am5.Root;
	subscriptions: Array<Subscription> = [];
	selectedLocker: string = "";

	constructor(
		@Inject(PLATFORM_ID) private platformId: Object,
		private zone: NgZone,
		private translate: TranslateService) { }

	ngOnInit(): void {
		this.selectedLocker = this.lockers.data[0].uuid;
	}

	// Run the function only in the browser
	private browserOnly(f: () => void) {
		if (isPlatformBrowser(this.platformId)) {
			this.zone.runOutsideAngular(() => {
				f();
			});
		}
	}

	onSelectChange() {
		this.browserOnly(() => {
			if (this.root) {
				this.root.dispose();
			}
		});
		this.createChart();
	}

	private createChart() {
		// Chart code goes in here
		this.browserOnly(() => {
			let root = am5.Root.new("chartdiv");

			root.setThemes([am5themes_Animated.new(root)]);

		 	/* Set the chart language. */
			if (Helpers.getLanguage() === 'it') {
				root.locale = am5lang_it_IT;
			} else {
				root.locale = am5lang_en_US;
			}

		 	/* Setting the date format for the x-axis labels. */
			root.dateFormatter.setAll({
				dateFormat: "yyyy/MM/dd",
				dateFields: ["valueX"]
			});

			let chart = root.container.children.push(
				am5xy.XYChart.new(root, {
					panY: false,
					layout: root.verticalLayout
				})
			);

		 	// Define data
			let lockerIngressoData = [
				{
					//lunedì 17
					date: "2022/10/17",
					value1: 2, //consegne
					value2: 0 //ritiri
				},
				{
					date: "2022/10/18",
					value1: 0, //consegne
					value2: 0 //ritiri
				},
				{
					date: "2022/10/19",
					value1: 5, //consegne
					value2: 0 //ritiri
				},
				{
					date: "2022/10/20",
					value1: 0, //consegne
					value2: 5 //ritiri
				},
				{
					date: "2022/10/21",
					value1: 0, //consegne
					value2: 5 //ritiri
				},
				{
					date: "2022/10/22",
					value1: 0, //consegne
					value2: 0 //ritiri
				},
				{
					date: "2022/10/23",
					value1: 0, //consegne
					value2: 0 //ritiri
				},
			];

			let lockerDevData = [
				{
					//lunedì 17
					date: "2022/10/17",
					value1: 4, //consegne
					value2: 4 //ritiri
				},
				{
					date: "2022/10/18",
					value1: 5, //consegne
					value2: 2 //ritiri
				},
				{
					date: "2022/10/19",
					value1: 0, //consegne
					value2: 0 //ritiri
				},
				{
					date: "2022/10/20",
					value1: 0, //consegne
					value2: 3 //ritiri
				},
				{
					date: "2022/10/21",
					value1: 7, //consegne
					value2: 7 //ritiri
				},
				{
					date: "2022/10/22",
					value1: 1, //consegne
					value2: 0 //ritiri
				},
				{
					date: "2022/10/23",
					value1: 0, //consegne
					value2: 1 //ritiri
				},
			];

			// Create Y-axis
			// let yAxis = chart.yAxes.push(
			//   am5xy.ValueAxis.new(root, {
			//     renderer: am5xy.AxisRendererY.new(root, {})
			//   })
			// );
			const yAxis = chart.yAxes.push(am5xy.DateAxis.new(root, {
				maxDeviation: 0.1,
				baseInterval: {
					timeUnit: "day",
					count: 1
				},
				renderer: am5xy.AxisRendererY.new(root, {}),
				tooltip: am5.Tooltip.new(root, {})
			}));

			// Create X-Axis
			// const xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
			//   maxDeviation: 0.1,
			//   baseInterval: {
			//     timeUnit: "day",
			//     count: 1
			//   },
			//   renderer: am5xy.AxisRendererX.new(root, {}),
			//   tooltip: am5.Tooltip.new(root, {})
			// }));
			const xAxis = chart.xAxes.push(
				am5xy.ValueAxis.new(root, {
					renderer: am5xy.AxisRendererX.new(root, {})
				})
			);

			// if(this.selectedLocker == "locker1"){
			//   xAxis.data.setAll(locker1Data);
			// }else{
			//   xAxis.data.setAll(locker2Data);
			// }

			// Create series
			// DELIVERIES
			// let deliveries = chart.series.push(
			//   am5xy.ColumnSeries.new(root, {
			//     name: this.translate.instant("general.deliveries"),
			//     xAxis: xAxis,
			//     yAxis: yAxis,
			//     valueYField: "value1",
			//     valueXField: "date"
			//   })
			// );
			let deliveries = chart.series.push(
				am5xy.ColumnSeries.new(root, {
					name: this.translate.instant("general.deliveries"),
					xAxis: xAxis,
					yAxis: yAxis,
					valueYField: "date",
					valueXField: "value1"
				})
			);

			deliveries.data.processor = am5.DataProcessor.new(root, {
				dateFormat: "yyyy/MM/dd",
				dateFields: ["date"]
			});

			// if(this.selectedLocker == "locker1"){
			//   deliveries.data.setAll(locker1Data);
			// }else{
			//   deliveries.data.setAll(locker2Data);
			// }

			// COLLECTIONS
			// let collections = chart.series.push(
			//   am5xy.ColumnSeries.new(root, {
			//     name: this.translate.instant("general.returns"),
			//     xAxis: xAxis,
			//     yAxis: yAxis,
			//     valueYField: "value2",
			//     valueXField: "date"
			//   })
			// );
			let collections = chart.series.push(
				am5xy.ColumnSeries.new(root, {
					name: this.translate.instant("general.returns"),
					xAxis: xAxis,
					yAxis: yAxis,
					valueYField: "date",
					valueXField: "value2"
				})
			);
			collections.data.processor = am5.DataProcessor.new(root, {
				dateFormat: "yyyy/MM/dd",
				dateFields: ["date"]
			});

			if (this.selectedLocker == "16ecaf8e-48af-4be9-8e92-3691fcbce7a8") {
				collections.data.setAll(lockerIngressoData);
				deliveries.data.setAll(lockerIngressoData);
			} else {
				collections.data.setAll(lockerDevData);
				deliveries.data.setAll(lockerDevData);
			}

			// Add legend
			let legend = chart.children.push(am5.Legend.new(root, {}));
			legend.data.setAll(chart.series.values);

			// Add cursor
			// chart.set("cursor", am5xy.XYCursor.new(root, {}));

			this.root = root;
		});
	}

	ngAfterViewInit() {
		this.createChart();
	}

	ngOnDestroy() {
		// Clean up chart when the component is removed
		this.browserOnly(() => {
			if (this.root) {
				this.root.dispose();
			}
		});
	}

}
