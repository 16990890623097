import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { Role } from './core/models/role';
import { AuthBaseComponent } from './pages/auth/auth-base/auth-base.component';
import { DashboardBaseComponent } from './pages/dashboard/dashboard-base/dashboard-base.component';
import { PageNotFoundComponent } from './pages/dashboard/page-not-found/page-not-found.component';
import { PageType } from './core/models/pate-type';

const routes: Routes = [
	{
		path: '',
		pathMatch: 'full',
		redirectTo: 'home'
	},
	{
		path: 'auth',
		component: AuthBaseComponent,
		children: [
			{ path: '', pathMatch: 'full', redirectTo: '/auth/login' },
			{ path: 'login', loadChildren: () => import('./pages/auth/login/login.module').then(m => m.LoginModule) },
			{ path: 'forgot-password', loadChildren: () => import('./pages/auth/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule) },
			{ path: 'first-access', loadChildren: () => import('./pages/auth/first-access/first-access.module').then(m => m.FirstAccessModule) }
		]
	},
	{
		path: '',
		component: DashboardBaseComponent,
		children: [
			{ path: 'unauthorized', loadChildren: () => import('./pages/dashboard/unauthorized/unauthorized.module').then(m => m.UnauthorizedModule), canActivate: [AuthGuard] },
			{ path: 'home', loadChildren: () => import('./pages/dashboard/home/home.module').then(m => m.HomeModule), data: {}, canActivate: [AuthGuard] },
			{ path: 'account', loadChildren: () => import('./pages/dashboard/account/account.module').then(m => m.AccountModule), data: { permittedRoles: [Role.GOD, Role.ADMIN, Role.CONCIERGE, Role.SUPER_ADMIN, Role.SERVICE] }, canActivate: [AuthGuard] },
			{ path: 'monitor', loadChildren: () => import('./pages/dashboard/monitor/monitor.module').then(m => m.MonitorModule), data: { permittedRoles: [Role.GOD, Role.ADMIN, Role.CONCIERGE, Role.SUPER_ADMIN, Role.SERVICE] }, canActivate: [AuthGuard] },
			{ path: 'monitor/details/:uuid', loadChildren: () => import('./pages/dashboard/monitor/details/locker-details.module').then(m => m.LockerDetailsModule), data: { permittedRoles: [Role.GOD, , Role.ADMIN, Role.CONCIERGE, Role.SUPER_ADMIN, Role.SERVICE] }, canActivate: [AuthGuard] },
			{ path: 'statistics', loadChildren: () => import('./pages/dashboard/statistics/statistics.module').then(m => m.StatisticsModule), data: { permittedRoles: [Role.GOD, Role.ADMIN, Role.SUPER_ADMIN] }, canActivate: [AuthGuard] },
			{ path: 'administration/users', loadChildren: () => import('./pages/dashboard/administration/users-management/users-management.module').then(m => m.UsersManagementModule), data: { permittedRoles: [Role.GOD, Role.ADMIN, Role.SUPER_ADMIN] }, canActivate: [AuthGuard] },
			{ path: 'administration/backoffice', loadChildren: () => import('./pages/dashboard/administration/backoffice/backoffice.module').then(m => m.BackofficeModule), data: { permittedRoles: [Role.GOD, Role.ADMIN, Role.SUPER_ADMIN] }, canActivate: [AuthGuard] },	
			{ path: 'administration/lockers', loadChildren: () => import('./pages/dashboard/administration/lockers/lockers.module').then(m => m.LockersModule), data: { permittedRoles: [Role.GOD, Role.ADMIN, Role.SUPER_ADMIN] }, canActivate: [AuthGuard] },
			{ path: 'administration/lockers-management', loadChildren: () => import('./pages/dashboard/administration/lockers-management/lockers-management.module').then(m => m.LockersManagementModule), data: { permittedRoles: [Role.GOD] }, canActivate: [AuthGuard] },
			{ path: 'administration/lockers-management/new', loadChildren: () => import('./pages/dashboard/administration/lockers-management/alter/alter.module').then(m => m.AlterModule), data: { permittedRoles: [Role.GOD], pageType: PageType.NEW }, canActivate: [AuthGuard] },
			{ path: 'administration/lockers-management/edit/:uuid', loadChildren: () => import('./pages/dashboard/administration/lockers-management/alter/alter.module').then(m => m.AlterModule), data: { permittedRoles: [Role.GOD], pageType: PageType.EDIT }, canActivate: [AuthGuard] },
			{ path: 'administration/locations', loadChildren: () => import('./pages/dashboard/administration/locations/locations.module').then(m => m.LocationsModule), data: { permittedRoles: [Role.GOD, Role.ADMIN, Role.SUPER_ADMIN] }, canActivate: [AuthGuard] },
			{ path: 'administration/locations-management', loadChildren: () => import('./pages/dashboard/administration/locations-management/locations-management.module').then(m => m.LocationsManagementModule), data: { permittedRoles: [Role.GOD] }, canActivate: [AuthGuard] },
			{ path: 'functions', loadChildren: () => import('./pages/dashboard/functions/functions.module').then(m => m.FunctionsModule), data: { permittedRoles: [Role.GOD, Role.ADMIN, Role.SUPER_ADMIN] }, canActivate: [AuthGuard] },
		],
		canActivate: [AuthGuard]
	},
	{
		path: 'page-not-found',
		component: DashboardBaseComponent,
		children: [{
			path: '',
			component: PageNotFoundComponent
		}],
		canActivate: [AuthGuard]
	}, {
		path: '**',
		pathMatch: 'full',
		redirectTo: 'page-not-found'
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
