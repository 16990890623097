import { JsonConvert, JsonConverter, JsonCustomConvert, JsonObject, JsonProperty } from "json2typescript";
import { LocationConverter, Location } from "../location";
import { MapConverter } from "../../helpers/converters/map-converter";
import { Resp } from "../resp";


@JsonConverter
export class MonitorLockerArrayConverter implements JsonCustomConvert<Array<MonitorLocker>> {
    serialize() { }
    deserialize(data: any): Array<MonitorLocker> {
        const jsonConvert: JsonConvert = new JsonConvert();
        return jsonConvert.deserializeArray(data, MonitorLocker);
    }
}

@JsonObject("MonitorLocker")
export class MonitorLocker {
    @JsonProperty("uuid", String, true)
    uuid: string = "";
    @JsonProperty("name", String, true)
    name: string = "";
    @JsonProperty("location", LocationConverter, true)
    location: Location = new Location;
    @JsonProperty("vendor", String, true)
    vendor: string = "";
    @JsonProperty("metadata", MapConverter, true)
    metadata: Map<string, any> = new Map<string, any>();

    _metadata: Array<{ key: string, value: any }> = []; 

    init() {
        this._metadata = Array.from(this.metadata.entries()).map(([key, value]) => ({ key, value }));
    }

    /**
     * Build locker description
     */
    public get description(): string {
        return this.name && this.location && this.location.name ? `${this.name} - ${this.location.name}` : '';
    }

    getMetadataDto() {
        this.metadata.clear();
        this._metadata.forEach(({ key, value }) => {
            if (!this.metadata.has(key)) {
                this.metadata.set(key, value);
            }
        });
        const metadata: any = {};
        this.metadata.forEach((v, k) => {
            metadata[k] = v;
        });
        return metadata;
    }
}

@JsonObject("MonitorLockerList")
export class MonitorLockerList {
    @JsonProperty("count", Number, true)
    count: number = 0;
    @JsonProperty("data", MonitorLockerArrayConverter, true)
    data: Array<MonitorLocker> = [];
}

@JsonConverter
export class MonitorLockerListConverter implements JsonCustomConvert<MonitorLockerList> {
    serialize() { }
    deserialize(data: any): MonitorLockerList {
        const jsonConvert: JsonConvert = new JsonConvert();
        return jsonConvert.deserializeObject(data, MonitorLockerList);
    }
}

@JsonObject("MonitorLockerListData")
export class MonitorLockerListData extends Resp {
    @JsonProperty("data", MonitorLockerListConverter, true)
    data: MonitorLockerList = new MonitorLockerList();
}

@JsonObject("MonitorLockerArrayData")
export class MonitorLockerArrayData extends Resp {
    @JsonProperty("data", MonitorLockerArrayConverter, true)
    data: Array<MonitorLocker> = [];
}