import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Subscription } from 'rxjs';
import { Helpers } from 'src/app/core/helpers';
import { Account } from 'src/app/core/models/account';
import { BackOfficeUser } from 'src/app/core/models/back-office-user';
import { IdentityService } from 'src/app/core/services/identity.service';
import { AdministrationService } from 'src/app/core/services/network/administration.service';
import { TenantService } from 'src/app/core/services/tenant.service';

@Component({
	selector: 'app-account-edit',
	templateUrl: './account-edit.component.html',
	styleUrls: ['./account-edit.component.less']
})
export class AccountEditComponent implements OnInit, OnDestroy {

	subscriptions: Array<Subscription> = [];
	account: Account | null = new Account();
	backOfficeUser: BackOfficeUser = new BackOfficeUser();

	constructor(
		private notification: NzNotificationService,
		private translate: TranslateService,
		private adminService: AdministrationService,
		private identityService: IdentityService,
		private tenantService: TenantService) { }

	ngOnInit(): void {
		this.identityService.account$.subscribe((identity) => {
			this.account = identity;
			this.getBackOfficeUser();
		});
	}

	getBackOfficeUser(): void {
		if (this.account) {
			this.subscriptions.push(
				this.adminService.getBackOfficeUser(this.account.user.id).subscribe(
					{
						next: (resp) => {
							this.backOfficeUser = resp;
						},
						error: (err) => {
							console.error(err);
						}
					}
				)
			);
		}
	}

	updateAccount(): void {
		if (this.account) {
			if (this.account.user.id === this.backOfficeUser.uuid) {
				this.subscriptions.push(
					this.adminService.updateBackOfficeUser(this.backOfficeUser).subscribe(
						{
							next: (resp) => {
								this.account!.user.avatar = resp.avatar;
								this.account!.user.first_name = resp.first_name;
								this.account!.user.last_name = resp.last_name;
								this.identityService.update(this.account);
								Helpers.successNotification(this.notification, this.translate, 'account.successes.updateAccount')
							},
							error: (err) => {
								console.error(err);
								Helpers.errorNotification(this.notification, this.translate, 'account.errors.unableToUpdateAccount');
							}
						}
					)
				);
			} else {
				console.error("Account and back office user do not match");
				Helpers.errorNotification(this.notification, this.translate, 'general.genericError');
			}
		}
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(s => s.unsubscribe());
	}

}
