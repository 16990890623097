import { AfterViewInit, Component, Inject, Input, NgZone, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { Root, color, percent, Label } from '@amcharts/amcharts5';
import { isPlatformBrowser } from '@angular/common';
import { PieChart, PieSeries } from "@amcharts/amcharts5/percent";
import html2canvas from 'html2canvas';
import { StatisticsService } from 'src/app/core/services/network/statistics.service';
import { BoxSizeAnalysisObject } from 'src/app/core/models/box-size-analysis-object';
import { Subscription } from 'rxjs';
import { Helpers } from 'src/app/core/helpers';
import { TranslateService } from '@ngx-translate/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
	selector: 'app-box-size-analysis',
	templateUrl: './box-size-analysis.component.html',
	styleUrls: ['./box-size-analysis.component.less']
})
export class BoxSizeAnalysisComponent implements OnInit, OnDestroy, AfterViewInit {

	@Input() selectedLocker: string | undefined = undefined;
	@Input() selectedLocation: string | undefined = undefined;
	@Input() since: string = '';
	@Input() until: string = '';
	@Input() base64data: any;

	private root!: Root;
	private subscriptions: Array<Subscription> = [];
	boxSizeAnalisis: BoxSizeAnalysisObject = new BoxSizeAnalysisObject();
	loading: boolean = false;
	chart!: PieChart;
	docDefinition: any;
	loadingPdf: boolean = false;
	pdfmaker: any;
	pdffonts: any;
	
	constructor(
		@Inject(PLATFORM_ID) private platformId: Object,
		private zone: NgZone,
		private statisticsService: StatisticsService, 
		private notification: NzNotificationService,
		private translate: TranslateService) { }

	ngOnInit(): void {
		this.getStatistics();
	}

	async loadPdfmaker() {
		if(!this.pdfmaker){
			this.pdfmaker = await import('pdfmake/build/pdfmake');
			this.pdffonts = await import('pdfmake/build/vfs_fonts');
		}
	}

	getRoot(): Root {
		return this.root;
	}

	getStatistics(): void {
		this.loading = true;
		let locationUuid = undefined;
		let lockerUuid = undefined;
		if(this.selectedLocation && this.selectedLocation !== Helpers.allLocations){
			locationUuid = this.selectedLocation;
		}
		if(this.selectedLocker && this.selectedLocker !== Helpers.allLockers){
			lockerUuid = this.selectedLocker;
		}
		// Disposing the chart and the root of the chart. 
		// This is necessary because if we don't dispose the chart, the chart will not be updated when the data changes.
		if(this.chart) {
			this.root.dispose();
			this.chart.dispose();
		}
		this.subscriptions.push(
			this.statisticsService.getBoxSizeStatistics(this.since!, this.until!, locationUuid, lockerUuid)
				.subscribe(
					{
					next: resp => {
						this.boxSizeAnalisis = resp;
						if(this.root && this.root.isDisposed()) {
							this.createChartWithCheck();
						};
						this.loading = false;
					},
					error: err => {
						console.error(err);
						if(err.statusCode === 403 || err.status === 403){
							Helpers.errorNotification(this.notification, this.translate, 'general.getStatisticsUnauthorized');
						}else {
							Helpers.errorNotification(this.notification, this.translate, 'statistics.errors.unableToGetBoxSizeSatatistics');
						}
						this.loading = false;
					}
					}
				)
		)
	}

	getDataTableForPdf(): any {
		let data = [];
		data.push([
			{ text: this.translate.instant('statistics.boxSize'), bold: true },
			{ text: this.translate.instant('statistics.rateOfUsage'), bold: true },
			{ text: this.translate.instant('statistics.operationsNumber'), bold: true },
		]);
		this.boxSizeAnalisis.data.forEach(item => data.push([item.key, this.getUsageRate(item.key), item.value]));
		return data;
	}

	onFiltersChange(since: string, until: string): void {
		this.since = since;
		this.until = until;
		this.getStatistics();
	}

	// Run the function only in the browser
	private browserOnly(f: () => void) {
		if (isPlatformBrowser(this.platformId)) {
			this.zone.runOutsideAngular(() => {
				f();
			});
		}
	}

	/**
	 * It creates a pie chart
	 */
	private createChart(): void {
		this.browserOnly(() => {
			this.root = Root.new('boxSizeChartDiv');

			this.root.interfaceColors.set('background', color(0xffffff));
			this.root.interfaceColors.set('alternativeBackground', color(0xffffff));

			this.chart = this.root.container.children.push(PieChart.new(this.root, {
				innerRadius: percent(50)
			}));
			
			let series = this.chart?.series.push(PieSeries.new(this.root, {
				name: "Series",
				categoryField: "key",
				valueField: "value"
			}));

			series.data.setAll(this.boxSizeAnalisis.data);

			let label = series.children.push(Label.new(this.root, {
				text: this.boxSizeAnalisis.operations.toString(),
				fontSize: 50,
				centerX: percent(50),
				centerY: percent(50),
				textAlign: "center",
				
			}));

			label.children.push(Label.new(this.root, {
				text: this.translate.instant('statistics.totalOperations'),
				fontSize: 14,
				centerX: percent(50),
				centerY: percent(100),
				textAlign: "center",
			}));
		});
	}

	async exportToPdf(): Promise<void> {
		this.loadingPdf = true;
		const isDone = await this.generatePdf();
		if(isDone){
			this.loadPdfmaker().then(() => {
				try{
					const win = window.open('', '_blank');
					this.pdfmaker.createPdf(this.docDefinition, null, null, this.pdffonts.pdfMake.vfs).open({}, win);
					this.loadingPdf = false;
				}catch(err){
					console.error(err);
					Helpers.errorNotification(this.notification, this.translate, 'statistics.errors.unableToExportStatistics');
					this.loadingPdf = false;
				}
			}).catch(err => {
				console.error(err);
				Helpers.errorNotification(this.notification, this.translate, 'statistics.errors.unableToLoadPdfMaker');
				this.loadingPdf = false;
			});
		} else {
			Helpers.errorNotification(this.notification, this.translate, 'statistics.errors.unableToGeneratePdf');
			this.loadingPdf = false;
		}
	}

	private async generatePdf(): Promise<boolean> {
		const pieChart = document.getElementById('boxSizeChartDiv');
		const title = document.getElementById('statistics-title');
		const subtitle = document.getElementsByClassName("statistics-subtitle")[0];
		const chartTitle = this.translate.instant('statistics.sizeBoxUsedAnalysis');
		return new Promise((resolve, reject) => {
			html2canvas(pieChart!, {
				allowTaint: true,
				backgroundColor: '#ffffff',
			}).then(canvas => {
				const dataUrl = canvas.toDataURL();
				this.docDefinition = {
					pageSize: "A4",
					pageOrientation: "portrait",
					marging: [20, 20, 20, 100],
					header: {
						image: this.base64data,
						width: 120,
						margin: [20, 10, 0, 0],
					},
					content: [],
					footer: {
						text: this.translate.instant('statistics.exportFileFooter'),
						margin: [20, 0, 0, 20],
						fontSize: 8,
						color: 'gray'
					}
				};
				this.docDefinition.content.push({
					text: title?.innerHTML,
					fontSize: 20,
					bold: true,
					margin: [0, 25, 0, 3]
				});
				this.docDefinition.content.push({
					text: subtitle?.innerHTML,
					fontSize: 18,
					bold: true,
					margin: [0, 0, 0, 15]
				});
				this.docDefinition.content.push({
					text: chartTitle,
					fontSize: 14,
					bold: true,
					margin: [0, 0, 0, 20]
				});
				this.docDefinition.content.push({
					text: this.translate.instant('statistics.totalOperationsRecorded') + " " + this.boxSizeAnalisis.operations,
					fontSize: 12,
					bold: false,
					margin: [0, 0, 0, 15]
				});
				this.docDefinition.content.push({
					with: 500,
					table: {
						headerRows: 1,
						widths: [ "*", "*", "*", "*" ],
						body: this.getDataTableForPdf()
					},
					margin: [0, 0, 0, 15]
				});
				this.docDefinition.content.push({
					image: dataUrl,
					width: 300,
				});	
				resolve(this.docDefinition);		
			}).catch(err => {
				console.error(err);
				reject(false);
			});
		});
	};

	/**
	 * It creates a csv file with the data from the chart and downloads it
	*/
	exportToCsv(): void {
		let csv = 'data:text/csv;charset=utf-8,';
		csv += this.translate.instant('statistics.boxSize') + ',' 
			+ this.translate.instant('statistics.rateOfUsage') + ',' 
			+ this.translate.instant('statistics.operationsNumber') + '\n';
		this.boxSizeAnalisis.data.forEach(item => csv += item.key + ',' + this.getUsageRate(item.key) + ',' + item.value + '\n');
		let encodedUri = encodeURI(csv);
		let link = document.createElement("a");
		link.setAttribute("href", encodedUri);
		link.setAttribute("download", "box_size_analysis.csv");
		document.body.appendChild(link); // Required for FF
		link.click();
	}

	/**
	 * It returns the usage rate of a box size in percentage
	 * @param {string} boxSize - string - The box size to get the usage rate for.
	 * @returns The percentage of the box size used in the operations.
	 */
	getUsageRate(boxSize: string): number | string {
		let object = this.boxSizeAnalisis.data.find(o => o.key === boxSize);
		if(object){
			return parseFloat(((object.value / this.boxSizeAnalisis.operations) * 100).toFixed(2));
		}else {
			return '-';
		}
	}

	ngAfterViewInit() {
		this.createChartWithCheck();
	}

	/**
	 * If the element with the id 'boxSizeChartDiv' exists, create the chart. If it doesn't exist, check
	 * again in 100 milliseconds. If it still doesn't exist after 3 tries, give up
	 */
	private createChartWithCheck(): void {
		let i = 0;
		const check = () => {
			if(document.getElementById('boxSizeChartDiv')){
				this.createChart();
			}else {
				if(i < 3){
					setTimeout(() => {
						check();
						i++;
					}, 100);
				}
			}
		}
		check();
	}

	ngOnDestroy() {
		this.subscriptions.forEach(s => s.unsubscribe());
		// Clean up chart when the component is removed
		this.browserOnly(() => {
			if (this.root) {
				this.root.dispose();
			}
		}
		);
	}
	
}
