<a nz-dropdown [nzDropdownMenu]="menu">
    <ng-container *ngTemplateOutlet="selectedLang == 'it'? itTEmplate: enTEmplate"></ng-container>&nbsp;
    <i nz-icon nzType="down"></i>
</a>
<nz-dropdown-menu #menu="nzDropdownMenu">
    <ul nz-menu>
        <li nz-menu-item (click)="changeLang('it')">
            <ng-container *ngTemplateOutlet="itTEmplate"></ng-container>
        </li>
        <li nz-menu-item (click)="changeLang('en')">
            <ng-container *ngTemplateOutlet="enTEmplate"></ng-container>
        </li>
    </ul>
</nz-dropdown-menu>

<ng-template #itTEmplate><img class="flag" src="./assets/flags/it.svg" />&nbsp;&nbsp;&nbsp;Italiano</ng-template>
<ng-template #enTEmplate><img class="flag" src="./assets/flags/en.svg" />&nbsp;&nbsp;&nbsp;English</ng-template>