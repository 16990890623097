export const locale = {
	lang: 'en',
	data: {
		login: {
			forgotPassword: 'Forgot password?',
			firstAccess: 'First access? Click here',
			errors: {
				email: 'Please input a valid email',
				password: 'Please input your Password'
			}
		},
		firstAccess: {
			title: 'Create your password',
			description: 'Enter your email address. We\'ll send you an email to create your password.',
			email: 'Enter your email',
			button: 'Send',
			back: 'Back to login page',
		},
		forgotPassword: {
			title: 'Restore your password',
			description: 'Enter your email address. We\'ll send you an email to rest your password.',
			email: 'Enter your email',
			button: 'Send',
			back: 'Back to login page',
		},
		authentication: {
			sign_in: 'Sign in',
			register: 'Register',
			signing_in: 'Signing in',
			forgot_password: 'Forgot password?',
			registration: 'Registration',
			reset_password: 'Reset e-mail address to password',
			registration_completed: 'Registration completed',
			emailConfirmationText: 'We have send a verification email, check your inbox and click on the link and confirm your e-mail address.',
			emailConfirmationTitle: 'Send a confirmation e-mail',
			resetSuccess: 'An email with a reset password link is sent successfully. Follow the instructions for reset the password',
			confirm_password: 'Confirm password',
			tos: 'I agree to the Terms of Service and Conditions',
			privacy: 'I have read and agree to the Privacy',
			error: 'Error',
			google_sign_in: 'Sign in with Google',
			firstAccess: 'First access? Click here',
			firstAccessTitle: 'Set your password',
			new_password: 'Enter your email',
			emailSent: 'Email sent successfully',
			newUserSuccess: 'An email with a setup password link is sent successfully. Follow the instructions for setup the password',
			errors: {
				generic: "An error has occurred, please try again later",
				user_not_found: "There is no user record corresponding to this identifier or the user may have been deleted.",
				user_disabled: 'The given email has been disabled.',
				invalid_email: 'The email address is not valid.',
				wrong_password: 'The password is invalid for the given email, or the account corresponding to the email does not have a password set.',
				account_exists_with_different_credential: 'Already exists an account with the given email address',
				popup_blocked: 'The popup was blocked by the browser, please enable popups for this website.',
				email_already_in_use: 'Already exists an account with the given email address',
				weak_password: 'The password is not strong enough',
				tos: 'You must first agree to the Terms of Service and Conditions',
				privacy: 'You must first agree to the Privacy',
				// email_not_verified: 'It seems you have not verified your email address. Please check your inbox.'
				email_not_verified: 'Your email address has not yet been verified. Click on the verification link in the email that has been sent to your address.',
				unauthorized: 'Access is unauthorized',
				missingTenants: 'Missing tenants configuration'
			}
		},
		general: {
			it: 'Italian',
			en: 'English',
			male: 'Male',
			female: 'Female',
			other: 'Other',
			pageNotFound: 'Page not found',
			unauthorized: 'You are unauthorized',
			createUserUnauthorized: 'You are unauthorized to create the user',
			importUsersUnauthorized: 'You are unauthorized to import users',
			createLockerUnauthorized: 'You are unauthorized to create the locker',
			createLocationUnauthorized: 'You are unauthorized to create the location',
			updateUserUnauthorized: 'You are unauthorized to update the user',
			updateLockerUnauthorized: 'You are unauthorized to update the locker',
			updateLocationUnauthorized: 'You are unauthorized to update the location',
			deleteUserUnauthorized: 'You are unauthorized to delete the user',
			deleteLockerUnauthorized: 'You are unauthorized to delete the locker',
			getLockerIdentifiersUnauthorized: 'You are unauthorized to generate QR code',
			deleteLocationUnauthorized: 'You are unauthorized to delete the location',
			associateUsersLockersUnauthorized: 'You are unauthorized to associate users and lockers',
			deleteAssociateUsersLockersUnauthorized: 'You are unauthorized to delete associations between users and lockers',
			getStatisticsUnauthorized: 'You are unauthorized to get the statistics',
			spinnerMessage: 'Pagin loading',
			loaderMessage: 'Loading data',
			warning: 'Warning',
			error: 'Error',
			save: 'Save',
			cancel: 'Cancel',
			edit: 'Edit',
			update: 'Update',
			show: 'Show',
			delete: 'Delete',
			back: 'Back',
			confirm: 'Confirm',
			done: 'Done',
			add: 'Add',
			reset: 'Reset',
			report: 'Report',
			lastLogin: 'Last access',
			emailAddress: 'Email address',
			emailVerifield: 'Verified email',
			firstname: 'First name',
			name: 'Name',
			middlename: 'Middle name',
			lastname: 'Last name',
			gender: 'Gender',
			type: 'Type',
			box: 'Box',
			date: 'Date',
			startDate: 'Start',
			endDate: 'End',
			inProgress: 'In progress',
			userUuid: 'User identifier',
			userEmail: 'User email',
			exchangeRecepientEmail: 'Recipient email',
			otherTenantUser: 'Other tenant user',
			roles: {
				role: 'Role',
				admin: 'Admin',
				super_admin: 'Super admin',
				god: 'God',
				concierge: 'Concierge',
				service: 'Service'
			},
			phoneNumber: 'Phone number',
			preferredLanguage: 'Preferred language',
			registrationSource: "Registration source",
			sources: {
				selfsignin: "Self Sign-In",
				admindashboard: "Saved by admin",
			},
			status: {
				status: 'Status',
				REGISTERED: 'Registered',
				SUSPENDED: 'Suspended',
				DELETED: 'Deleted',
				ACTIVE: 'Active',
				actived: 'Actived',
				deactivated: 'Deactivated',
				online: 'Online',
				offline: 'Offline',
				undefined: 'Undefined',
				free: 'Free',
				busy: 'Busy',
				overdue: 'Overdue',
				BLOCKED: 'Blocked',
				reserved: 'Reserved',
				overdueDescription: 'box occupied for longer than allowed by the system',
				blockedDescription: 'box blocked because of a technical problem',
				reservedDescription: 'box reserved by an user',
			},
			assignedLockers: 'Assigned lockers',
			visibilityLockers: 'Visible lockers',
			returns: 'returns',
			return: 'Return',
			deliveries: 'deliveries',
			delivery: 'Delivery',
			selfDeposits: 'self deposits',
			deposit: 'Self deposit',
			exchange: 'Exchange',
			exchanges: 'exchanges',
			reservations: 'reservations',
			reservation: 'Reservation',
			delegations: 'delegations',
			delegation: 'delegation',
			export: 'Export',
			exportToPdf: 'Export to PDF',
			notify: 'Notify',
			notifyTechnician: 'Notifies to technician',
			notifyUser: 'Notifies to user',
			notifyConfirm: 'Are you sure to send notification?',
			notifyService: 'Notifies to service',
			notifyConcierge: 'Notifies to concierge',
			notifyAdmin: 'Notifies admin',
			notifyConfirmUser: 'Are you sure to notify user?',
			notifyConfirmService: 'Are you sure to notify service?',
			notifyConfirmConcierge: 'Are you sure to notify concierge?',
			notifyConfirmAdmin: 'Are you sure to notify admin?',
			sentNotifyTo: 'Notification sent to ',
			notSentNotifyTo: 'Notification not sent to ',
			event: 'Event',
			attributes: 'Attributes',
			actions: 'Actions',
			multipleActions: 'Multiple actions',
			address: 'Address',
			street: 'Street',
			city: 'City',
			zip: 'Zip code',
			province: 'Province',
			region: 'Region',
			state: 'State',
			selectAll: 'Select all',
			fillFormCorrectly: 'Fill out the form correctly',
			vendor: 'Vendor',
			model: 'Model',
			location: 'Location',
			locations: 'Locations',
			user: 'User',
			users: 'Users',
			noDataAvailable: 'Data not available',
			addMessage: 'Add any message',
			selectUser: "Select user",
			genericError: 'An error has occurred',
			filters: 'Filters',
			noLockersAssigned: 'Unable to find lockers assigned to your account.',
			noVisibilityForThisLocker: 'You do not have visibility for this locker.',
			pleaseContactAdmin: 'Please contact your administrator.',
		},
		cloudinary: {
			howToLoad: 'Select how to upload image',
			addImage: 'Add%20an%20image',
			replaceImage: 'Replace image',
			addNewImage: 'Add an image',
			fromUrlText: 'Image from URL',
			fromFolderText: 'Image from Folder',
			remoteUrl: 'Enter the remote URL',
			chooseFile: 'Choose a file',
			removeImage: 'Remove image',
			selectImage: 'Choose an image...',
			selctFromList: 'Or select from list',
			useThisImage: 'Use this image',
			deleteImage: 'Delete this image',
			email: 'Enter an email address',
			limits: {
				msg1: 'Image with must be ',
				msg2: ' and height must be ',
			},
			errors: {
				emptyURL: 'Please enter an valid URL',
				upload: 'An error has occurred, please try again',
				limits: `Image size is wrong, width: %%%px, height: ###px`,
			}
		},
		menu: {
			account: 'Account',
			monitor: 'Monitor',
			statistics: 'Statistics',
			administration: 'Administration',
			platformUsers: 'Platform users',
			backofficeUsers: 'Back office users',
			functions: 'Functions management',
			lockers: 'Lockers'
		},
		home: {
			title: 'Smart Locker dashboard',
			subtitleNoWidget: 'You haven\'t added any widgets to your home yet.',
			subtitleNoWidgetSpan: 'Click the + button to add them.',
			subtitleWidget: 'Click the + button to add more widgets.',
			message: 'Select one of the menu items',
			addWidgetTip: 'Or add a new widgets',
			addWidgetTitle: 'Add a widget',
			selectLocker: 'Select a locker',
			selectWidget: 'Select a widget',
			errors: {
				unableGetHomeWidgets: 'Unable to get widgets list',
				unableUpdateWidgets: 'Unable to update widgets list',
			},
		},
		widgets: {
			MATH: 'Locker status',
			OVERDUE: 'Overdue',
			USERS: 'Users',
			PROBLEMS: 'Problems',
			LOGS: 'Logbook',
			CONNECTIVITY: 'Connectivity status',
			CONTROL: 'Control',
			confirmDelete: 'Do you want to delete this widget?',
		},
		account: {
			details: 'Your account details',
			associatedLockers: 'Associated lockers',
			noLockers: 'You don\'t have any associated locker',
			successes: {
				updateAccount: 'Account updated successfully'
			},
			errors: {
				unableToUpdateAccount: 'Unable to update the account',
				unableToGetAccountLockers: 'Unable to get your account lockers list'
			}
		},
		monitor: {
			description: 'Description',
			boxesStatus: 'Boxes status',
			showDetails: 'Show details',
			connectivity: 'Connectivity',
			details: {
				details: 'Locker details',
				title: 'Details',
				noAvailableWidget: 'No available widget for this locker.',
				errors: {
					unableGetLocker: 'Unable to get locker',
					unableToGetAvailableWidgets: 'Unable to get locker\'s available widgets list'
				}
			},
			errors: {
				unableToGetLockers: 'Unable to get lockers list'
			},
			math: {
				title: 'Locker\'s status',
				subtitle: 'Automatic update every 5 minutes, alternatively update manually',
				errors: {
					unableToGetMath: 'Unable to get locker\'s math',
					unableToGetSvg: 'Unable to get SVG locker\'s image'
				}
			},
			overdue: {
				title: 'Overdue',
				subtitle: 'Boxes occupied for longer than allowed by the system',
				successNotify: 'Overdue notification sent successfully',
				errorNotify: 'Unable to notify the overdue',
				errors: {
					unableToGetOverdues: 'Unable to get overdues'
				}
			},
			lockerUsers: {
				type: 'Operation',
				subtitle: 'Users who have operations on the locker',
				recipientUserForExchange: 'Recipient user email',
				errors: {
					unabletoGetLockerUserOperations: 'Unable to get users who have operations on locker',
					unableToGetLockerUsers: 'Unable to get locker\'s users'
				}
			},
			logs: {
				title: "Logbook",
				subtitle: 'Logbook by user',
				errors: {
					unableToGetLogs: 'Unable to get logs',
					unableToCreateSvgFile: 'Unable to get logs to create SVG file'
				}
			},
			problems: {
				title: "Problems",
				subtitle: 'Problems encountered on the boxes',
				problemType: 'Problem',
				successNotify: 'Problem notification sent successfully',
				errorNotify: 'Unable to notify the problem',
				errors: {
					unableToGetProblems: 'Unable to get locker\'s problems'
				}
			},
			connectivityStatus: {
				title: 'Connectivity status',
				lastSync: 'Last sync',
				errors: {
					unableToGetStatus: 'Unable to get connectivity status'
				}
			},
			control: {
				title: 'Control',
				subtitle: 'Generate and send control pin',
				servicePin: 'Service pin',
				generateServicePin: 'Generate service pin',
				selectValidityPeriod: 'Select validity period',
				addEmailAddress: 'Add email address',
				formInstructions: 'Enter one or more email addresses to send the notification with the service pin',
				successes: {
					sentNotification: 'Notification sent successfully',
					sentNotifications: 'Notifications sent successfully'
				},
				errors: {
					requiredEmailAddress: 'Input an email address',
					unableToSendNotification: 'Unable to send notification',
					unableToSendNotifications: 'Unable to send notifications'
				}
			},
		},
		teamviewer_id: 'Teamviewer id',
		project_name: 'Project name',
		administration: {
			locationLockers: 'Location\'s lockers:',
			users: {
				platformUsers: 'Platform users',
				backofficeUsers: 'Back office users',
				deleteUserConfirm: 'Are you sure to delete this user?',
				importUsers: 'Import users',
				multipleActions: 'Multiple actions',
				associateToLockers: 'Associate lockers',
				associationLockersToUser: 'User association with lockers',
				associationLockersToUsers: 'Users association with lockers',
				removeAssociationLockers: 'Remove associated lockers',
				activeUsers: 'Active users',
				deactivateUsers: 'Deactivate users',
				activeUserConfirm: 'Are you sure to active selected users?',
				deactivateUserConfirm: 'Are you sure to deactivate selected users?',
				associationUsersLockersConfirmTitle: 'Select lockers to associate',
				singleAssociationUserLockersConfirmTitle: 'Select lockers to associate to user',
				associationUsersLockersConfirm: 'Are you sure to associate selected lockers to selected users?',
				singleAssociationUserLockersConfirm: 'Are you sure to associate selected lockers to user?',
				removeAssociationUsersLockersConfirmTitle: 'Select lockers to remove from selected users',
				removeAssociationUsersLockersConfirm: 'Are you sure to delete association between selected users and selected lockers?',
				removeSingleAssociationUserLockersConfirmTitle: 'Select lockers to remove from the user',
				removeSingleAssociationUserLockersConfirm: 'Are you sure to delete association between user and selected lockers?',
				previouslyAssociatedLockers: 'Currently associated lockers:',
				noAssociatedLockers: 'User has no currently associated lockers',
				noLockersForAssociation: 'There are no lockers available for association',
				addUser: 'Add user',
				editUser: 'Edit user',
				deleteUser: 'Delete user',
				singleUserAssociateToLockers: 'Associate user to one or more lockers',
				singleUserSelectAccociationsLockers: 'Delete the association between user and one or more lockers',
				newUserInstructions: 'Fill out the form with the new user\'s data',
				editUserInstructions: 'Edit user\'s data',
				importUsersInstructions: 'Import users from a csv file.',
				// importUsersInstructionsDetails: 'The fields: "email", "first_name" and "last_name" are mandatory. The others fields can be left blank. The field "gender" accepts only "MALE", "FEMALE" and "OTHER" as values.',
				importUsersInstructionsDetails: 'The fields: "email", "first_name" and "last_name" are mandatory. The others fields can be left blank',
				importButton: 'Import from csv',
				downloadExample: 'Download a CSV example file',
				selectCsv: 'Select a CSV file',
				separator: 'Select a separator',
				comma: 'Use comma ,',
				semicolon: 'Use semicolon ;',
				selectLanguage: 'Select language',
				selectGender: 'Select gender',
				associateTo: 'Associate to:',
				selectAllLockers: 'Select all lockers',
				lockerAssociation: 'Locker association',
				orSelectBasedOnLocation: 'or select lockers based on location',
				oneUserSelectToBeActivated: 'A user has been selected to be activated.',
				haveBeenSelectedToBeActivated: 'users have been selected to be activated.',
				oneUserHasRegisteredStatusForActivation: 'The user has "Registered" status and cannot be activated.',
				moreUsersHasRegisteredStatusForActivation: 'of these users have "Registered" status and cannot be activated.',
				oneUserSelectToBeDeactivated: 'A user has been selected to be deactivated.',
				haveBeenSelectedToBeDeactivated: 'users have been selected to be deactivated.',
				oneUserHasRegisteredStatusForDeactivation: 'The user has "Registered" status and cannot be deactivated.',
				moreUsersHasRegisteredStatusForDeactivation: 'of these users have "Registered" status and cannot be deactivated.',
				successes: {
					deleteUser: 'User deleted successfully',
					associateToLockers: 'Association was successful',
					deleteAssociations: 'Associations deleted successfully',
					activeUser: 'User has been activated',
					activeUsers: 'Users have been activated',
					deactivateUsers: 'Users have been deactivated',
					deactivateUser: 'User has been deactivated',
					createUser: 'User created successfully',
					updateUser: 'User updated successfully',
					importUsers: 'New users have been imported successfully'
				},
				errors: {
					unableToGetUsers: 'Unable to get users list',
					unableToDeleteUser: 'Unable to delete user',
					unableToAssociateToLockers: 'Unable to associate users to lockers',
					unableToDeleteAssociations: 'Unable to delete the associations',
					unableToActiveUsers: 'Unable to active users',
					unableToDeactivateUsers: 'Unable to deactivate users',
					selectUsers: 'Select at least one user',
					selectUserAndLocker: 'Select at least one user and one locker',
					unableToCreateUser: 'Unable to create new user',
					unableToUpdateUser: 'Unable to update user',
					unableReadCsv: 'Unable to read CSV file',
					notCsvFile: 'Please select a CSV file',
					unableToUploadFile: 'Unable to upload users',
					noEmailField: 'The "email" field was not found in the imported data',
					noFirstNameField: 'The "first_name" field was not found in the imported data',
					noMiddleNameField: 'The "middle_name" field was not found in the imported data',
					noLastNameField: 'The "last_name" field was not found in the imported data',
					noGenderField: 'The "gender" field was not found in the imported data',
					noPhoneNumberNameField: 'The "phone_number" field was not found in the imported data',
					noPreferredLanguageField: 'The "preferred_language" field was not found in the imported data',
					nullEmailField: 'The "email" field can\'t be empty',
					nullFirstNameField: 'The "first_name" field can\'t be empty',
					nullLastNameField: 'The "last_name" field can\'t be empty',
					unableToImportUsers: 'Unable to import new users',
					noUsersImported: 'No users have been imported'
				}
			},
			backoffice: {
				selectRole: 'Select role',
				cantSeeUserLockers: 'You cannot see lockers that are visible by user.',
				cantSeeUserVisibility: 'You cannot see lockers that are visible by user. Please contact your administrator',
				allTenantLockers: "All lockers associated with the tenant",
				noLockersAssigned: 'No lockers visible by user',
				makeVisibile: 'Make one or more lockers visible to the user',
				removeVisibility: 'Remove lockers visibility',
				addVisibility: 'Add lockers visibility',
				previouslyVisibleLockers: 'Previously visible lockers:',
				noVisibleLockers: 'The user has no currently visible lockers',
				noLockers: 'There are no lockers available',
				confirm: 'Are you sure to make the selected lockers visible to the user?',
				confirmRemove: 'Are you sure to remove visibility of selected lockers from the user?',
				successes: {
					createBackOfficeUser: 'Back office user created successfully',
					updateBackOfficeUser: 'Back office user updated successfully',
					deleteBackOfficeUser: 'Back office user deleted successfully',
				},
				errors: {
					unableToGetBackOfficeUsers: 'Unable to get back office users list',
					unableToCreateBackOfficeUser: 'Unable to create new back office user',
					unableToUpdateBackOfficeUser: 'Unable to update back office user',
					unableToDeleteBackOfficeUser: 'Unable to delete back office user',
					unableToAssociateUserToLockersAfterItsCreation: 'Unable to give visibility of lockers to user after his creation. Plase try again from the user\'s list',
					unableToAssociateUserToLockers: 'Unable to give visibility of lockers to user',
					unableToDeleteAssociationToLockers: 'Unable to remove visibility of lockers from user',
				}
			},
			lockers: {
				title: 'Lockers',
				deleteLockerConfirm: 'Are you sure to delete this locker?',
				associateToLocation: 'Associate to location',
				addLocker: 'Add locker',
				addLockerInstructions: 'Fill out the form with the new locker',
				editLocker: 'Edit locker',
				deleteLocker: 'Delete locker',
				createQrCode: 'Create QR code',
				editLockerInstructions: 'Edit locker\'s data',
				image: 'Image',
				model: 'Model',
				metadata: 'Metadata',
				vendorLockerUuid: 'Vendor locker id',
				softwareVersion: 'Software version',
				processes: 'Processes',
				selectLocation: 'Select a location',
				selectVendor: 'Select a vendor',
				addProcess: 'Add a process',
				noLockerData: 'No data available for this locker',
				steps: {
					1: "General settings",
					2: "Appearance",
					3: "Vendor Settings",
					4: "Conclusion",
				},
				next: 'Next',
				previous: 'Previous',
				done: 'Done',
				metadataKey: 'Key',
				metadataValue: 'Value',
				addMetadata: 'Add a metadata',
				environment: 'Environment',
				appDownloadUrl: 'App download URL',
				userEnabled: 'User enabled',
				courierEnabled: 'Courier enabled',
				conciergeEnabled: 'Concierge enabled',
				userReturnEnabled: 'Return enabled',
				userDelegationEnabled: 'Delegation enabled',
				userExchangeEnabled: 'Exchange enabled',
				userSelectionEnabled: 'User selection enabled',
				noAppearance: 'No graphic customizations are foreseen for this vendor',
				noVendorSpecific: 'There are no vendor specific settings',
				serialPort: 'Serial port',
				boards: 'Boards',
				boxes: 'Boxes',
				address: 'Address',
				numberOfBoxManaged: 'No. of managed boxes',
				boxNumberOffset: 'Box number offset',
				addBoard: 'Add a board',
				boxNumber: 'Box number',
				boxType: 'Box type',
				addBox: 'Add a box',
				remoteAccessId: 'Id for remote access',
				lockFeedbackType: 'Lock feedback type',
				maxOpeningAttempts: 'Maximum number of opening attempts',
				transactionsExpireDays: 'Transactions expire (in days)',
				overdueDays: 'Overdue days',
				lockerEnvironment: {
					CORPORATE: 'Corporate',
					RESIDENTIAL: 'Residential',
					RETAIL: 'Retail'
				},
				lockFeedbackTypeEnum: {
					LOCK_OPEN_FEEDBACK_OPEN: 'Feedback when opening',
					LOCK_OPEN_FEEDBACK_CLOSED: 'Feedback on closing',
					UNKNOWN: 'Unknown',
				},
				parityEnum: {
					No: 'None',
					Odd: 'Odd',
					Even: 'Even',
				},
				stopBitsEnum: {
					ONE_STOP_BIT: 'One stop bit',
					ONE_POINT_FIVE: 'One point five',
					TWO: 'Two',
				},
				boxTypeEnum: {
					NORMAL: 'Normal',
					RESERVED: 'Reserved',
				},
				lockerWizardEnd: {
					title: 'All data entered successfully',
					subtitle: 'Press the \'Finish\' button to save',
				},
				successes: {
					deleteLocker: 'Locker deleted successfully',
					updateLocker: 'Locker updated successfully',
					createLocker: 'Locker created successfully'
				},
				errors: {
					unableToGetLockers: 'Unable to get lockers list',
					unableToGetLocker: 'Unable to get locker data',
					unableToGetLockerImage: 'Unable to get locker\'s image',
					unableToDeleteLocker: 'Unable to delete locker',
					unableToUpdateLocker: 'Unable to update locker',
					unableToCreateLocker: 'Unable to create new locker',
					unableToGetVendors: 'Unable to get vendors',
					unableToGetLockerIdentifier: 'Unable to get locker\'s identifier and to create QR code',
					form: {
						name: 'The name is required',
						vendor: 'Select a vendor',
						location: 'Select a location',
						image: 'Load a locker image',
						environment: 'Select environment',
						appDownloadUrl: 'Enter the app download URL',
						appDownloadUrlFormat: 'Enter a valid URL',
						project_name: 'Enter the project name',
						baudRate: 'Enter the baud rate',
						dataBits: 'Enter data bits',
						parity: 'Select parity',
						stopBits: 'Select stop bits',
						boards: 'Enter at least one board',
						boardsFields: 'All fields are required',
						boxes: 'Enter at least one box',
						boxesFields: 'All fields are required',
						model: 'Enter model',
						softwareVersion: 'Enter the software version',
					}
				}
			},
			locations: {
				deleteLocationConfirm: 'Are you sure to delete this location?',
				addLocation: 'Add location',
				newLocationInstructions: 'Fill out the form with the new location',
				editLocation: 'Edit location',
				deleteLocation: 'Delete location',
				editLocationInstructions: 'Edit location\'s data',
				successes: {
					deleteLocation: 'Location deleted successfully',
					updateLocation: 'Location updated successfully',
					createLocation: 'Location created successfully'
				},
				errors: {
					unableToGetLocations: 'Unable to get locations',
					unableToDeleteLocation: 'Unable to delete location',
					unableToUpdateLocation: 'Unable to update location',
					unableToCreateLocation: 'Unable to create new location'
				}
			}
		},
		statistics: {
			labels: {
				locker: 'Locker',
				location: 'Location',
				granularity: 'Granularity',
				range: 'Range'
			},
			placeHolders: {
				selectLocker: 'Select locker',
				selectLocation: 'Select location',
				selectGranularity: 'Select granularity'
			},
			hour: 'Hour',
			deliveries: 'Deliveries',
			overdues: 'Overdues',
			returns: "Returns",
			selectLocker: 'Select locker',
			selectLocation: 'Select location',
			selectGranularity: 'Select granularity',
			allLockers: 'All lockers',
			allLockerOfLocation: 'All locker of location ',
			allLocations: 'All locations',
			allLocationsSelectedDescription: 'Selecting \'All locations\', the statistics report the aggregated data of all the lockers of all the locations',
			allLockersSelectedDescription: 'Selecting \'All lockers\', lthe statistics report the aggregated data of all the lockers of selected location',
			selectRangeError: 'Select a range of time',
			chart: 'Chart',
			table: 'Table',
			averageBoxOccupationTime: 'Average boxes occupation time',
			sizeBoxUsedAnalysisSubtitle: 'Percentage of operations based on boxe\'s size',
			deliveriesAndReturns: 'Deliveries and returns',
			totalOperations: 'Operations total',
			XS: 'Extra Small',
			S: 'Small',
			M: 'Medium',
			L: 'Large',
			XL: 'Extra Large',
			DAY: 'Day',
			WEEK: 'Week',
			MONTH: 'Month',
			YEAR: 'Year',
			sizeBoxUsedAnalysis: 'Analysis of used box size',
			totalOperationsRecorded: 'Total operations detected: ',
			boxSize: 'Box size',
			rateOfUsage: 'Rate of usage',
			operationsNumber: 'Operations number',
			usersOperationsAnalysis: 'Analysis of user data and usage data',
			usersOperationsAnalysisSubtitle: 'Satistics about the use of the locker by users',
			tableHeaders: {
				totalUsers: 'Total registered and active users',
				usersMadeOperations: 'Users who made operations',
				operationsPerUserAverage: 'Average number of operations per user',
				storageRangeAverage: 'Average package storage time (days)',
				overdueTimes: 'Overdues number',
				operationsTotalNumber: 'Total number of operations detected as reliable',
				conciergeOrTestOperations: 'Total number of concierge or test operations',
				overdueDurationAverage: 'Average overdue duration (days)',
			},
			deliveriesPerHour: 'Time slot for deliveries',
			deliveriesPerHourSubtitle: 'Statistics about package deliveries hours',
			returnsPerHour: 'Time slot for returns',
			returnsPerHourSubtitle: 'Statistics about package returns hours',
			deliveriesPerDay: 'Delivery trend by date',
			deliveriesPerDaySubtitle: 'Statistics about package deliveries dates',
			returnsPerDay: 'Return trend by date',
			returnsPerDaySubtitle: 'Statistics about package returns dates',
			overduesPerDay: 'Overdue trend by date',
			overduesPerDaySubtitle: 'Statistics about overdues dates',
			statisticsFrom: 'Statistics since',
			to: 'until',
			exportToCsv: 'Export to CSV',
			exportToPdf: 'Export to PDF',
			pdfFileGenerating: 'Generating PDF file...',
			exportFileFooter: 'Smart Locker S.r.l.\nLegal head office: Viale Mentana, 41 43121 Parma\n' +
				'Headquarters: Via Don Minzoni, 112 - 42043 - Taneto di Gattatico - (RE)\n' +
				'P.I: 02878640347 Phone Num.: +39 0522 674170 - info@samartlocker.it - www.smartlocker.it',
			errors: {
				unableToGetBoxSizeSatatistics: 'Unable to get statistics about the analysis of the dimensions of the used boxes',
				unableToGetUsersOperationsSatatistics: 'Unable to get statistics about locker\'s usage by users',
				unableToGetDeliveriesPerHourSatatistics: 'Unable to get statistics about deliveries per hour',
				unableToGetReturnsPerHourSatatistics: 'Unable to get statistics about returns per hour',
				unableToGetDeliveriesPerDaySatatistics: 'Unable to get statistics about deliveries per day',
				unableToGetReturnsPerDaySatatistics: 'Unable to get statistics about returns per day',
				unableToGetOverduesPerDayStatistics: 'Unable to get statistics about overdues per day',
				selectARange: 'Select a range of time',
				unableToExportStatistics: 'Unable to export statistics',
				unableToLoadPdfMaker: 'Unable to load PDF maker',
				unableToGeneratePdf: 'Unable to generate PDF file'
			}
		}
	}
}