import { HttpClient } from '@angular/common/http';
import { environment } from "src/environments/environment";
import { Router } from '@angular/router';
import { HttpUtilsService } from '../http-utils.service';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { JsonConvert } from 'json2typescript';
import { PlatformUsersList, PlatformUsersListData } from '../../models/platform-user';
import { Injectable } from '@angular/core';
import { Paging } from '../../models/paged-data';
import { Observable } from 'rxjs';
import { PlatformUser, PlatformUserData } from '../../models/platform-user';
import { Location, LocationData, LocationsList, LocationsListData } from '../../models/location';
import { BoolResp, IntegerResp, StringListResp, StringResp } from '../../models/primitive-resp';
import { MonitorLocker, MonitorLockerArrayData } from '../../models/locker/monitor-locker';
import { AdminLocker, AdminLockerData, AdminLockerList, AdminLockerListData } from '../../models/locker/admin-locker';
import { BackOfficeUser, BackOfficeUserData, BackOfficeUsersArrayData, BackOfficeUsersList, BackOfficeUsersListData } from '../../models/back-office-user';
import { Role } from '../../models/role';
import { AdminLockerBaseList, AdminLockerBaseListData } from '../../models/locker/admin-locker-base';

@Injectable({
    providedIn: 'root'
})
export class AdministrationService {

    readonly endpoint: string = environment.baseUrl;

    constructor(private http: HttpClient, private httpUtils: HttpUtilsService, private router: Router) { }

    // USERS
    /** Get platform user list
     * @param {Paging | null} paging
    */
    getUsers(paging?: Paging): Observable<PlatformUsersList | undefined> {
        try {
            return this.httpUtils.getHTTPHeadersAsync().pipe(
                mergeMap(httpHeaders => {
                    let url: string = paging ? `${this.endpoint}/administration/users${paging.getQs()}` : `${this.endpoint}/administration/users`;
                    return this.http.get(url, { headers: httpHeaders })
                        .pipe(
                            map((res: any) => {
                                const jsonConvert: JsonConvert = new JsonConvert();
                                const result: PlatformUsersListData = jsonConvert.deserializeObject(res, PlatformUsersListData);
                                if (result.success) {
                                    return result.data;
                                } else {
                                    this.httpUtils.handleError(result);
                                    return;
                                }
                            }, catchError(err => {
                                if (err.status === 401) { // Unauthorized
                                    this.httpUtils.logout();
                                }
                                throw err;
                            }))
                        );
                })
            );
        } catch (error) {
            throw error;
        }
    }

    /** Get platform user 
     * @param userUuid
    */
    getUser(userUuid: string): Observable<PlatformUser | undefined> {
        try {
            return this.httpUtils.getHTTPHeadersAsync().pipe(
                mergeMap(httpHeaders => {
                    return this.http.get(`${this.endpoint}/administration/users/${userUuid}`, { headers: httpHeaders })
                        .pipe(
                            map((res: any) => {
                                const jsonConvert: JsonConvert = new JsonConvert();
                                const result: PlatformUserData = jsonConvert.deserializeObject(res, PlatformUserData);
                                if (result.success) {
                                    return result.data;
                                } else {
                                    this.httpUtils.handleError(result);
                                    return;
                                }
                            }, catchError(err => {
                                if (err.status === 401) { // Unauthorized
                                    this.httpUtils.logout();
                                }
                                throw err;
                            }))
                        );
                })
            );
        } catch (error) {
            throw error;
        }
    }

    /** Create a new platform user
     * @param newUser (PlatformUser)
    */
    createUser(newUser: PlatformUser): Observable<PlatformUser | undefined> {
        const body: any = newUser.requestDto();
        try {
            return this.httpUtils.getHTTPHeadersAsync().pipe(
                mergeMap(httpHeaders => {
                    return this.http.post(`${this.endpoint}/administration/users`, JSON.stringify(body), { headers: httpHeaders })
                        .pipe(
                            map((res: any) => {
                                const jsonConvert: JsonConvert = new JsonConvert();
                                const result: PlatformUserData = jsonConvert.deserializeObject(res, PlatformUserData);
                                if (result.success) {
                                    return result.data;
                                } else {
                                    this.httpUtils.handleError(result);
                                    return;
                                }
                            }, catchError(err => {
                                if (err.status === 401) { // Unauthorized
                                    this.httpUtils.logout();
                                }
                                throw err;
                            }))
                        );
                })
            );
        } catch (error) {
            throw error;
        }
    }

    /** Update a platform user
     * @param userToUpdate (PlatformUser)
    */
    updateUser(userToUpdate: PlatformUser): Observable<PlatformUser | undefined> {
        const body: any = userToUpdate.requestDto();
        try {
            return this.httpUtils.getHTTPHeadersAsync().pipe(
                mergeMap(httpHeaders => {
                    return this.http.put(`${this.endpoint}/administration/users/${userToUpdate.uuid}`, JSON.stringify(body), { headers: httpHeaders })
                        .pipe(
                            map((res: any) => {
                                const jsonConvert: JsonConvert = new JsonConvert();
                                const result: PlatformUserData = jsonConvert.deserializeObject(res, PlatformUserData);
                                if (result.success) {
                                    return result.data;
                                } else {
                                    this.httpUtils.handleError(result);
                                    return;
                                }
                            }, catchError(err => {
                                if (err.status === 401) { // Unauthorized
                                    this.httpUtils.logout();
                                }
                                throw err;
                            }))
                        );
                })
            );
        } catch (error) {
            throw error;
        }
    }

    /** Delete a platform user
     * @param userUuid
    */
    deleteUser(userUuid: string): Observable<boolean | undefined> {
        try {
            return this.httpUtils.getHTTPHeadersAsync().pipe(
                mergeMap(httpHeaders => {
                    return this.http.delete(`${this.endpoint}/administration/users/${userUuid}`, { headers: httpHeaders })
                        .pipe(
                            map((res: any) => {
                                const jsonConvert: JsonConvert = new JsonConvert();
                                const result: BoolResp = jsonConvert.deserializeObject(res, BoolResp);
                                if (result.success) {
                                    return result.data;
                                } else {
                                    this.httpUtils.handleError(result);
                                    return;
                                }
                            }, catchError(err => {
                                if (err.status === 401) { // Unauthorized
                                    this.httpUtils.logout();
                                }
                                throw err;
                            }))
                        );
                })
            );
        } catch (error) {
            throw error;
        }
    }

    /** Get the list of lockers connected to the user
     * @param userUuid
    */
    getUserLockers(userUuid: string): Observable<Array<MonitorLocker> | undefined> {
        try {
            return this.httpUtils.getHTTPHeadersAsync().pipe(
                mergeMap(httpHeaders => {
                    return this.http.get(`${this.endpoint}/administration/users/${userUuid}/lockers`, { headers: httpHeaders })
                        .pipe(
                            map((res: any) => {
                                const jsonConvert: JsonConvert = new JsonConvert();
                                const result: MonitorLockerArrayData = jsonConvert.deserializeObject(res, MonitorLockerArrayData);
                                if (result.success) {
                                    return result.data;
                                } else {
                                    this.httpUtils.handleError(result);
                                    return;
                                }
                            }, catchError(err => {
                                if (err.status === 401) { // Unauthorized
                                    this.httpUtils.logout();
                                }
                                throw err;
                            }))
                        );
                })
            );
        } catch (error) {
            throw error;
        }
    }

    /** Import a list of users
     * @param users
    */
    importUsers(users: Array<PlatformUser>): Observable<Array<string> | undefined> {
        const body: any[] = [];
        users.forEach(user => {
            body.push(user.requestDto());
        });
        try {
            return this.httpUtils.getHTTPHeadersAsync().pipe(
                mergeMap(httpHeaders => {
                    return this.http.post(`${this.endpoint}/administration/users/import`, JSON.stringify(body), { headers: httpHeaders })
                        .pipe(
                            map((res: any) => {
                                const jsonConvert: JsonConvert = new JsonConvert();
                                const result: StringListResp = jsonConvert.deserializeObject(res, StringListResp);
                                if (result.success) {
                                    return result.data;
                                } else {
                                    this.httpUtils.handleError(result);
                                    return;
                                }
                            }, catchError(err => {
                                if (err.status === 401) { // Unauthorized
                                    this.httpUtils.logout();
                                }
                                throw err;
                            }))
                        );
                })
            );
        } catch (error) {
            throw error;
        }
    }

    /** Enable users matching passed uuid (change their status)
     * @param userUuids
    */
    enableUsers(userUuids: Array<string>): Observable<number | undefined> {
        let body: Array<{}> = [];
        userUuids.forEach(uuid => body.push({ "uuid": uuid }));
        try {
            return this.httpUtils.getHTTPHeadersAsync().pipe(
                mergeMap(httpHeaders => {
                    return this.http.put(`${this.endpoint}/administration/users/enable`, JSON.stringify(body), { headers: httpHeaders })
                        .pipe(
                            map((res: any) => {
                                const jsonConvert: JsonConvert = new JsonConvert();
                                const result: IntegerResp = jsonConvert.deserializeObject(res, IntegerResp);
                                if (result.success) {
                                    return result.data;
                                } else {
                                    this.httpUtils.handleError(result);
                                    return;
                                }
                            }, catchError(err => {
                                if (err.status === 401) { // Unauthorized
                                    this.httpUtils.logout();
                                }
                                throw err;
                            }))
                        );
                })
            );
        } catch (error) {
            throw error;
        }
    }

    /** Disable users matching passed uuid (change their status)
     * @param userUuids
    */
    disableUsers(userUuids: Array<string>): Observable<number | undefined> {
        let body: Array<{}> = [];
        userUuids.forEach(uuid => body.push({ "uuid": uuid }));
        try {
            return this.httpUtils.getHTTPHeadersAsync().pipe(
                mergeMap(httpHeaders => {
                    return this.http.put(`${this.endpoint}/administration/users/disable`, JSON.stringify(body), { headers: httpHeaders })
                        .pipe(
                            map((res: any) => {
                                const jsonConvert: JsonConvert = new JsonConvert();
                                const result: IntegerResp = jsonConvert.deserializeObject(res, IntegerResp);
                                if (result.success) {
                                    return result.data;
                                } else {
                                    this.httpUtils.handleError(result);
                                    return;
                                }
                            }, catchError(err => {
                                if (err.status === 401) { // Unauthorized
                                    this.httpUtils.logout();
                                }
                                throw err;
                            }))
                        );
                })
            );
        } catch (error) {
            throw error;
        }
    }

    /** Associate users matching passed uuids to lockers matching passed uuids
     * @param userUuids
     * @param lockerUuids
    */
    associateUsersLockers(userUuids: Array<string>, lockerUuids: Array<string>): Observable<boolean | undefined> {
        let body = { "users": userUuids, "lockers": lockerUuids };
        try {
            return this.httpUtils.getHTTPHeadersAsync().pipe(
                mergeMap(httpHeaders => {
                    return this.http.post(`${this.endpoint}/administration/users/lockers`, JSON.stringify(body), { headers: httpHeaders })
                        .pipe(
                            map((res: any) => {
                                const jsonConvert: JsonConvert = new JsonConvert();
                                const result: BoolResp = jsonConvert.deserializeObject(res, BoolResp);
                                if (result.success) {
                                    return result.data;
                                } else {
                                    this.httpUtils.handleError(result);
                                    return;
                                }
                            }, catchError(err => {
                                if (err.status === 401) { // Unauthorized
                                    this.httpUtils.logout();
                                }
                                throw err;
                            }))
                        );
                })
            );
        } catch (error) {
            throw error;
        }
    }

    /** Delete the associations between users matching passed uuids and lockers matching passed uuids
     * @param userUuids
     * @param lockerUuids
    */
    deleteAssociationUsersLockers(userUuids: Array<string>, lockerUuids: Array<string>): Observable<boolean | undefined> {
        let body = { "users": userUuids, "lockers": lockerUuids };
        try {
            return this.httpUtils.getHTTPHeadersAsync().pipe(
                mergeMap(httpHeaders => {
                    return this.http.delete(`${this.endpoint}/administration/users/lockers`, { headers: httpHeaders, body: JSON.stringify(body) })
                        .pipe(
                            map((res: any) => {
                                const jsonConvert: JsonConvert = new JsonConvert();
                                const result: BoolResp = jsonConvert.deserializeObject(res, BoolResp);
                                if (result.success) {
                                    return result.data;
                                } else {
                                    this.httpUtils.handleError(result);
                                    return;
                                }
                            }, catchError(err => {
                                if (err.status === 401) { // Unauthorized
                                    this.httpUtils.logout();
                                }
                                throw err;
                            }))
                        );
                })
            );
        } catch (error) {
            throw error;
        }
    }

    // BACKOFFICE USERS
    /** Get back office users by role
     * @param role
    */
    getBackOfficeUsersByRole(role: Role): Observable<Array<BackOfficeUser>> {
        try {
            return this.httpUtils.getHTTPHeadersAsync().pipe(
                mergeMap(httpHeaders => {
                    return this.http.get(`${this.endpoint}/administration/back-office-users/role/${role}`, { headers: httpHeaders })
                        .pipe(
                            map((res: any) => {
                                const jsonConvert: JsonConvert = new JsonConvert();
                                const result: BackOfficeUsersArrayData = jsonConvert.deserializeObject(res, BackOfficeUsersArrayData);
                                if (result.success) {
                                    return result.data;
                                } else {
                                    this.httpUtils.handleError(result);
                                    return [];
                                }
                            }, catchError(err => {
                                if (err.status === 401) { // Unauthorized
                                    this.httpUtils.logout();
                                }
                                throw err;
                            }))
                        );
                })
            );
        } catch (error) {
            throw error;
        }
    }

    /** Get back office user list
     * @param {Paging | null} paging
    */
    getBackOfficeUsers(paging?: Paging): Observable<BackOfficeUsersList> {
        try {
            return this.httpUtils.getHTTPHeadersAsync().pipe(
                mergeMap(httpHeaders => {
                    const url = paging ? `${this.endpoint}/administration/back-office-users${paging.getQs()}` : `${this.endpoint}/administration/back-office-users`;
                    return this.http.get(url, { headers: httpHeaders })
                        .pipe(
                            map((res: any) => {
                                const jsonConvert: JsonConvert = new JsonConvert();
                                const result: BackOfficeUsersListData = jsonConvert.deserializeObject(res, BackOfficeUsersListData);
                                if (result.success) {
                                    return result.data;
                                } else {
                                    this.httpUtils.handleError(result);
                                    return new BackOfficeUsersList();
                                }
                            }, catchError(err => {
                                if (err.status === 401) { // Unauthorized
                                    this.httpUtils.logout();
                                }
                                throw err;
                            }))
                        );
                })
            );
        } catch (error) {
            throw error;
        }
    }

    /** Get back office user 
     * @param userUuid
    */
    getBackOfficeUser(userUuid: string): Observable<BackOfficeUser> {
        try {
            return this.httpUtils.getHTTPHeadersAsync().pipe(
                mergeMap(httpHeaders => {
                    return this.http.get(`${this.endpoint}/administration/back-office-users/${userUuid}`, { headers: httpHeaders })
                        .pipe(
                            map((res: any) => {
                                const jsonConvert: JsonConvert = new JsonConvert();
                                const result: BackOfficeUserData = jsonConvert.deserializeObject(res, BackOfficeUserData);
                                if (result.success) {
                                    return result.data;
                                } else {
                                    this.httpUtils.handleError(result);
                                    return new BackOfficeUser();
                                }
                            }, catchError(err => {
                                if (err.status === 401) { // Unauthorized
                                    this.httpUtils.logout();
                                }
                                throw err;
                            }))
                        );
                })
            );
        } catch (error) {
            throw error;
        }
    }

    /** Create a new back office user
     * @param newUser (BackOfficeUser)
    */
    createBackOfficeUser(newUser: BackOfficeUser): Observable<BackOfficeUser> {
        const body: any = newUser.dto();
        try {
            return this.httpUtils.getHTTPHeadersAsync().pipe(
                mergeMap(httpHeaders => {
                    return this.http.post(`${this.endpoint}/administration/back-office-users`, JSON.stringify(body), { headers: httpHeaders })
                        .pipe(
                            map((res: any) => {
                                const jsonConvert: JsonConvert = new JsonConvert();
                                const result: BackOfficeUserData = jsonConvert.deserializeObject(res, BackOfficeUserData);
                                if (result.success) {
                                    return result.data;
                                } else {
                                    this.httpUtils.handleError(result);
                                    return new BackOfficeUser();
                                }
                            }, catchError(err => {
                                if (err.status === 401) { // Unauthorized
                                    this.httpUtils.logout();
                                }
                                throw err;
                            }))
                        );
                })
            );
        } catch (error) {
            throw error;
        }
    }

    /** Update a back office user
     * @param userToUpdate (BackOfficeUser)
    */
    updateBackOfficeUser(userToUpdate: BackOfficeUser): Observable<BackOfficeUser> {
        const body: any = userToUpdate.dto();
        try {
            return this.httpUtils.getHTTPHeadersAsync().pipe(
                mergeMap(httpHeaders => {
                    return this.http.put(`${this.endpoint}/administration/back-office-users/${userToUpdate.uuid}`, JSON.stringify(body), { headers: httpHeaders })
                        .pipe(
                            map((res: any) => {
                                const jsonConvert: JsonConvert = new JsonConvert();
                                const result: BackOfficeUserData = jsonConvert.deserializeObject(res, BackOfficeUserData);
                                if (result.success) {
                                    return result.data;
                                } else {
                                    this.httpUtils.handleError(result);
                                    return new BackOfficeUser();
                                }
                            }, catchError(err => {
                                if (err.status === 401) { // Unauthorized
                                    this.httpUtils.logout();
                                }
                                throw err;
                            }))
                        );
                })
            );
        } catch (error) {
            throw error;
        }
    }

    /** Delete a back office user
     * @param userUuid
    */
    deleteBackOfficeUser(userUuid: string): Observable<boolean> {
        try {
            return this.httpUtils.getHTTPHeadersAsync().pipe(
                mergeMap(httpHeaders => {
                    return this.http.delete(`${this.endpoint}/administration/back-office-users/${userUuid}`, { headers: httpHeaders })
                        .pipe(
                            map((res: any) => {
                                const jsonConvert: JsonConvert = new JsonConvert();
                                const result: BoolResp = jsonConvert.deserializeObject(res, BoolResp);
                                if (result.success) {
                                    return result.data;
                                } else {
                                    this.httpUtils.handleError(result);
                                    return false;
                                }
                            }, catchError(err => {
                                if (err.status === 401) { // Unauthorized
                                    this.httpUtils.logout();
                                }
                                throw err;
                            }))
                        );
                })
            );
        } catch (error) {
            throw error;
        }
    }

    /**
     * This function retrieves an array of lockers associated with a user UUID through an HTTP request.
     * @param {string} userUuid - The userUuid parameter is a string that represents the unique
     * identifier of a user. This method retrieves an array of lockers associated with the user
     * identified by this userUuid.
     * @returns An Observable that emits an array of Locker objects.
     */
    getBackOfficeUserAssociatedLockers(userUuid: string): Observable<Array<MonitorLocker>> {
        try {
            return this.httpUtils.getHTTPHeadersAsync().pipe(
                mergeMap(httpHeaders => {
                    return this.http.get(`${this.endpoint}/administration/back-office-users/lockers/${userUuid}`, { headers: httpHeaders })
                        .pipe(
                            map((res: any) => {
                                const jsonConvert: JsonConvert = new JsonConvert();
                                const result: MonitorLockerArrayData = jsonConvert.deserializeObject(res, MonitorLockerArrayData);
                                if (result.success) {
                                    return result.data;
                                } else {
                                    this.httpUtils.handleError(result);
                                    return [];
                                }
                            }, catchError(err => {
                                if (err.status === 401) { // Unauthorized
                                    this.httpUtils.logout();
                                }
                                throw err;
                            }))
                        );
                })
            );
        } catch (error) {
            throw error;
        }
    }

   /**
    * This function associates lockers to a back-office user and returns an observable boolean value
    * indicating success or failure.
    * @param {string} userUuid - a string representing the unique identifier of a back-office user.
    * @param {boolean} allLockers - A boolean value indicating whether all lockers should be associated
    * with the back-office user or not.
    * @param {Array<string> | null} lockers - An optional array of strings representing the lockers to
    * be associated with the back-office user. If null, all lockers will be associated.
    * @returns An Observable of type boolean is being returned.
    */
    associateLockersToBackOfficeUser(userUuid: string, allLockers: boolean, lockers: Array<string> | null): Observable<boolean> {
        try {
            return this.httpUtils.getHTTPHeadersAsync().pipe(
                mergeMap(httpHeaders => {
                    const body: {all_lockers: boolean, lockers: Array<string> | null} = { all_lockers: allLockers, lockers: lockers };
                    return this.http.post(`${this.endpoint}/administration/back-office-users/lockers/${userUuid}`, JSON.stringify(body), { headers: httpHeaders })
                        .pipe(
                            map((res: any) => {
                                const jsonConvert: JsonConvert = new JsonConvert();
                                const result: BoolResp = jsonConvert.deserializeObject(res, BoolResp);
                                if (result.success) {
                                    return result.data;
                                } else {
                                    this.httpUtils.handleError(result);
                                    return false;
                                }
                            }, catchError(err => {
                                if (err.status === 401) { // Unauthorized
                                    this.httpUtils.logout();
                                }
                                throw err;
                            }))
                        );
                })
            );
        } catch (error) {
            throw error;
        }
    }

    // deleteAssociationLockersToBackOfficeUser(userUuid: string, lockers: Array<string>): Observable<boolean> {
    //     try {
    //         return this.httpUtils.getHTTPHeadersAsync().pipe(
    //             mergeMap(httpHeaders => {
    //                 const body: {lockers: Array<string>} = { lockers: lockers };
    //                 return this.http.delete(`${this.endpoint}/administration/back-office-users/lockers/${userUuid}`, { headers: httpHeaders, body: JSON.stringify(body) })
    //                     .pipe(
    //                         map((res: any) => {
    //                             const jsonConvert: JsonConvert = new JsonConvert();
    //                             const result: BoolResp = jsonConvert.deserializeObject(res, BoolResp);
    //                             if (result.success) {
    //                                 return result.data;
    //                             } else {
    //                                 this.httpUtils.handleError(result);
    //                                 return false;
    //                             }
    //                         }, catchError(err => {
    //                             if (err.status === 401) { // Unauthorized
    //                                 this.httpUtils.logout();
    //                             }
    //                             throw err;
    //                         }))
    //                     );
    //             })
    //         );
    //     } catch (error) {
    //         throw error;
    //     }
    // }


    // LOCATIONS
    /** Get location list
     * @param {Paging | null} paging
    */
    getLocations(paging?: Paging): Observable<LocationsList> {
        try {
            return this.httpUtils.getHTTPHeadersAsync().pipe(
                mergeMap(httpHeaders => {
                    const url = paging ? `${this.endpoint}/administration/locations${paging.getQs()}` : `${this.endpoint}/administration/locations`;
                    return this.http.get(url, { headers: httpHeaders })
                        .pipe(
                            map((res: any) => {
                                const jsonConvert: JsonConvert = new JsonConvert();
                                const result: LocationsListData = jsonConvert.deserializeObject(res, LocationsListData);
                                if (result.success) {
                                    return result.data;
                                } else {
                                    this.httpUtils.handleError(result);
                                    return new LocationsList();
                                }
                            }, catchError(err => {
                                if (err.status === 401) { // Unauthorized
                                    this.httpUtils.logout();
                                }
                                throw err;
                            }))
                        );
                })
            );
        } catch (error) {
            throw error;
        }
    }

    /** Get location
     * @param locationUuid
    */
    getLocation(locationUuid: string): Observable<Location | undefined> {
        try {
            return this.httpUtils.getHTTPHeadersAsync().pipe(
                mergeMap(httpHeaders => {
                    return this.http.get(`${this.endpoint}/administration/locations/${locationUuid}`, { headers: httpHeaders })
                        .pipe(
                            map((res: any) => {
                                const jsonConvert: JsonConvert = new JsonConvert();
                                const result: LocationData = jsonConvert.deserializeObject(res, LocationData);
                                if (result.success) {
                                    return result.data;
                                } else {
                                    this.httpUtils.handleError(result);
                                    return;
                                }
                            }, catchError(err => {
                                if (err.status === 401) { // Unauthorized
                                    this.httpUtils.logout();
                                }
                                throw err;
                            }))
                        );
                })
            );
        } catch (error) {
            throw error;
        }
    }

    /** Create a new location
     * @param newLocation
    */
    createLocation(newLocation: Location): Observable<Location> {
        const body = newLocation.dto();
        try {
            return this.httpUtils.getHTTPHeadersAsync().pipe(
                mergeMap(httpHeaders => {
                    return this.http.post(`${this.endpoint}/administration/locations`, JSON.stringify(body), { headers: httpHeaders })
                        .pipe(
                            map((res: any) => {
                                const jsonConvert: JsonConvert = new JsonConvert();
                                const result: LocationData = jsonConvert.deserializeObject(res, LocationData);
                                if (result.success) {
                                    return result.data;
                                } else {
                                    this.httpUtils.handleError(result);
                                    return new Location;
                                }
                            }, catchError(err => {
                                if (err.status === 401) { // Unauthorized
                                    this.httpUtils.logout();
                                }
                                throw err;
                            }))
                        );
                })
            );
        } catch (error) {
            throw error;
        }
    }

    /** Update a location
     * @param locationToUpdate
    */
    updateLocation(locationToUpdate: Location): Observable<Location | undefined> {
        const body = locationToUpdate.dto();
        try {
            return this.httpUtils.getHTTPHeadersAsync().pipe(
                mergeMap(httpHeaders => {
                    return this.http.put(`${this.endpoint}/administration/locations/${locationToUpdate.uuid}`, JSON.stringify(body), { headers: httpHeaders })
                        .pipe(
                            map((res: any) => {
                                const jsonConvert: JsonConvert = new JsonConvert();
                                const result: LocationData = jsonConvert.deserializeObject(res, LocationData);
                                if (result.success) {
                                    return result.data;
                                } else {
                                    this.httpUtils.handleError(result);
                                    return;
                                }
                            }, catchError(err => {
                                if (err.status === 401) { // Unauthorized
                                    this.httpUtils.logout();
                                }
                                throw err;
                            }))
                        );
                })
            );
        } catch (error) {
            throw error;
        }
    }

    /** Delete a location
     * @param locationUuid
    */
    deleteLocation(locationUuid: string): Observable<boolean | undefined> {
        try {
            return this.httpUtils.getHTTPHeadersAsync().pipe(
                mergeMap(httpHeaders => {
                    return this.http.delete(`${this.endpoint}/administration/locations/${locationUuid}`, { headers: httpHeaders })
                        .pipe(
                            map((res: any) => {
                                const jsonConvert: JsonConvert = new JsonConvert();
                                const result: BoolResp = jsonConvert.deserializeObject(res, BoolResp);
                                if (result.success) {
                                    return result.data;
                                } else {
                                    this.httpUtils.handleError(result);
                                    return;
                                }
                            }, catchError(err => {
                                if (err.status === 401) { // Unauthorized
                                    this.httpUtils.logout();
                                }
                                throw err;
                            }))
                        );
                })
            );
        } catch (error) {
            throw error;
        }
    }

    /** Retrieve the list of lockers (MonitorLocker) associated with the location
     * @param locationUuid
    */
    getLocationlockers(locationUuid: string): Observable<Array<MonitorLocker>> {
        try {
            return this.httpUtils.getHTTPHeadersAsync().pipe(
                mergeMap(httpHeaders => {
                    return this.http.get(`${this.endpoint}/administration/locations/${locationUuid}/lockers`, { headers: httpHeaders })
                        .pipe(
                            map((res: any) => {
                                const jsonConvert: JsonConvert = new JsonConvert();
                                const result: MonitorLockerArrayData = jsonConvert.deserializeObject(res, MonitorLockerArrayData);
                                if (result.success) {
                                    return result.data;
                                } else {
                                    this.httpUtils.handleError(result);
                                    return [];
                                }
                            }, catchError(err => {
                                if (err.status === 401) { // Unauthorized
                                    this.httpUtils.logout();
                                }
                                throw err;
                            }))
                        );
                })
            );
        } catch (error) {
            throw error;
        }
    }

    // LOCKERS
    /** Get locker list (AdminLocker)
     * @param paging
    */
    getLockers(paging: Paging): Observable<AdminLockerBaseList> {
        try {
            return this.httpUtils.getHTTPHeadersAsync().pipe(
                mergeMap(httpHeaders => {
                    return this.http.get(`${this.endpoint}/administration/lockers${paging.getQs()}`, { headers: httpHeaders })
                        .pipe(
                            map((res: any) => {
                                const jsonConvert: JsonConvert = new JsonConvert();
                                const result: AdminLockerBaseListData = jsonConvert.deserializeObject(res, AdminLockerBaseListData);
                                if (result.success) {
                                    return result.data;
                                } else {
                                    this.httpUtils.handleError(result);
                                    return new AdminLockerBaseList();
                                }
                            }, catchError(err => {
                                if (err.status === 401) { // Unauthorized
                                    this.httpUtils.logout();
                                }
                                throw err;
                            }))
                        );
                })
            );
        } catch (error) {
            throw error;
        }
    }

    /** Get locker (AdminLocker)
     * @param lockerUuid
    */
    getLocker(lockerUuid: string): Observable<AdminLocker> {
        try {
            return this.httpUtils.getHTTPHeadersAsync().pipe(
                mergeMap(httpHeaders => {
                    return this.http.get(`${this.endpoint}/administration/lockers/${lockerUuid}`, { headers: httpHeaders })
                        .pipe(
                            map((res: any) => {
                                const jsonConvert: JsonConvert = new JsonConvert();
                                const result: AdminLockerData = jsonConvert.deserializeObject(res, AdminLockerData);
                                if (result.success) {
                                    result.data.init();
                                    return result.data;
                                } else {
                                    this.httpUtils.handleError(result);
                                    return new AdminLocker();
                                }
                            }, catchError(err => {
                                if (err.status === 401) { // Unauthorized
                                    this.httpUtils.logout();
                                }
                                throw err;
                            }))
                        );
                })
            );
        } catch (error) {
            throw error;
        }
    }

    /** Create a new locker (AdminLocker)
     * @param newAdminLocker
    */
    createLocker(newAdminLocker: AdminLocker): Observable<AdminLocker> {
        const body = newAdminLocker.dto();
        try {
            return this.httpUtils.getHTTPHeadersAsync().pipe(
                mergeMap(httpHeaders => {
                    return this.http.post(`${this.endpoint}/administration/lockers`, JSON.stringify(body), { headers: httpHeaders })
                        .pipe(
                            map((res: any) => {
                                const jsonConvert: JsonConvert = new JsonConvert();
                                const result: AdminLockerData = jsonConvert.deserializeObject(res, AdminLockerData);
                                if (result.success) {
                                    return result.data;
                                } else {
                                    this.httpUtils.handleError(result);
                                    return new AdminLocker();
                                }
                            }, catchError(err => {
                                if (err.status === 401) { // Unauthorized
                                    this.httpUtils.logout();
                                }
                                throw err;
                            }))
                        );
                })
            );
        } catch (error) {
            throw error;
        }
    }

    /** Update a locker (AdminLocker)
     * @param adminLockerToUpdate
    */
    updateLocker(adminLockerToUpdate: AdminLocker): Observable<AdminLocker> {
        const body = adminLockerToUpdate.dto();
        try {
            return this.httpUtils.getHTTPHeadersAsync().pipe(
                mergeMap(httpHeaders => {
                    return this.http.put(`${this.endpoint}/administration/lockers/${adminLockerToUpdate.uuid}`, JSON.stringify(body), { headers: httpHeaders })
                        .pipe(
                            map((res: any) => {
                                const jsonConvert: JsonConvert = new JsonConvert();
                                const result: AdminLockerData = jsonConvert.deserializeObject(res, AdminLockerData);
                                if (result.success) {
                                    return result.data;
                                } else {
                                    this.httpUtils.handleError(result);
                                    return new AdminLocker();
                                }
                            }, catchError(err => {
                                if (err.status === 401) { // Unauthorized
                                    this.httpUtils.logout();
                                }
                                throw err;
                            }))
                        );
                })
            );
        } catch (error) {
            throw error;
        }
    }

    /** Delete a locker (AdminLocker)
     * @param lockerUuid
    */
    deleteLocker(lockerUuid: string): Observable<boolean | undefined> {
        try {
            return this.httpUtils.getHTTPHeadersAsync().pipe(
                mergeMap(httpHeaders => {
                    return this.http.delete(`${this.endpoint}/administration/lockers/${lockerUuid}`, { headers: httpHeaders })
                        .pipe(
                            map((res: any) => {
                                const jsonConvert: JsonConvert = new JsonConvert();
                                const result: BoolResp = jsonConvert.deserializeObject(res, BoolResp);
                                if (result.success) {
                                    return result.data;
                                } else {
                                    this.httpUtils.handleError(result);
                                    return;
                                }
                            }, catchError(err => {
                                if (err.status === 401) { // Unauthorized
                                    this.httpUtils.logout();
                                }
                                throw err;
                            }))
                        );
                })
            );
        } catch (error) {
            throw error;
        }
    }

    getLockerIdentifier(lockerUuid: string): Observable<string> {
        try {
            return this.httpUtils.getHTTPHeadersAsync().pipe(
                mergeMap(httpHeaders => {
                    return this.http.get(`${this.endpoint}/administration/lockers/${lockerUuid}/identifier`, { headers: httpHeaders })
                        .pipe(
                            map((res: any) => {
                                const jsonConvert: JsonConvert = new JsonConvert();
                                const result: StringResp = jsonConvert.deserializeObject(res, StringResp);
                                if (result.success) {
                                    return result.data;
                                } else {
                                    this.httpUtils.handleError(result);
                                    return '';
                                }
                            }, catchError(err => {
                                if (err.status === 401) { // Unauthorized
                                    this.httpUtils.logout();
                                }
                                throw err;
                            }))
                        );
                })
            );
        } catch (error) {
            throw error;
        }
        
    }
}