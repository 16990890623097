import { JsonConverter, JsonCustomConvert, JsonConvert } from "json2typescript";

@JsonConverter
export class MapConverter implements JsonCustomConvert<Map<string, any>> {

    serialize(map: Map<string, any>) {
    }

    deserialize(data: any): Map<string, any> {
        const result: Map<string, any> = new Map<string, any>();
        if(data){
            const keys: Array<string> = Object.keys(data);
            const values: Array<string> = Object.values(data);
            keys.forEach((k, i) => {
                result.set(k, values[i]);
            });
        }
        return result;
    }
}