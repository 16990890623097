import { JsonConvert, JsonConverter, JsonCustomConvert, JsonObject, JsonProperty } from "json2typescript";
import { Resp } from "../resp";
import { ConfigurationBase, ConfigurationBaseConverter } from "./configuration-base";
import { MonitorLocker } from "./monitor-locker";


@JsonConverter
export class AdminLockerBaseListConverter implements JsonCustomConvert<AdminLockerBaseList> {
    serialize() {}
    deserialize(data: any): AdminLockerBaseList {
        const jsonConvert: JsonConvert = new JsonConvert();
        return jsonConvert.deserializeObject(data, AdminLockerBaseList);
    }
}

@JsonConverter
export class AdminLockerBaseArrayConverter implements JsonCustomConvert<Array<AdminLockerBase>> {
    serialize() { }
    deserialize(data: any): Array<AdminLockerBase> {
        const jsonConvert: JsonConvert = new JsonConvert();
        return jsonConvert.deserializeArray(data, AdminLockerBase);
    }
}


@JsonObject("AdminLockerBase")
export class AdminLockerBase extends MonitorLocker {
    @JsonProperty("vendor_uuid", String, true)
	vendor_uuid: string = "";
    @JsonProperty("vendor_locker_id", String, true)
	vendor_locker_id: string = "";
    @JsonProperty("configuration", ConfigurationBaseConverter, true)
	configuration: ConfigurationBase = new ConfigurationBase();
}


@JsonObject("AdminLockerBaseList")
export class AdminLockerBaseList {
    @JsonProperty("count", Number, true)
	count: number = 0;
    @JsonProperty("data", AdminLockerBaseArrayConverter, true)
	data: Array<AdminLockerBase> = [];
}

@JsonObject("AdminLockerBaseListData")
export class AdminLockerBaseListData extends Resp{
    @JsonProperty("data", AdminLockerBaseListConverter, true)
	data: AdminLockerBaseList = new AdminLockerBaseList();
}