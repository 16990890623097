import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JsonConvert } from 'json2typescript';
import { Observable } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AnalysisObject, AnalysisObjectArrayData } from '../../models/analysis-object';
import { BoxSizeAnalysisObject, BoxSizeAnalysisObjectData } from '../../models/box-size-analysis-object';
import { DateAnalysisObject, DateAnalysisObjectArrayData } from '../../models/date-analysis-object';
import { UsersOperationsAnalysisObject, UsersOperationsAnalysisObjectData } from '../../models/users-operations-analysis-object';
import { HttpUtilsService } from '../http-utils.service';

@Injectable({
  providedIn: 'root'
})
export class StatisticsService {

	readonly endpoint: string = environment.baseUrl;
  
  	constructor(private http: HttpClient, private httpUtils: HttpUtilsService) { }

	/** Get statistics about locker usage related to box sizes
	 * @param since
	 * @param until
	 * @param granularity
	 * @param locationUuid
	 * @param lockerUuid
	 */
	getBoxSizeStatistics(since: string, until: string, locationUuid?: string, lockerUuid?: string): Observable<BoxSizeAnalysisObject> {
		try {
		return this.httpUtils.getHTTPHeadersAsync().pipe(
			mergeMap(httpHeaders => {
				let url = `${this.endpoint}/statistics/boxSize?since=${since}&until=${until}`;
				if(locationUuid){
					url = url + `&location=${locationUuid}`;
				}
				if(lockerUuid){
					url = url + `&locker=${lockerUuid}`;
				}
				return this.http.get(url, { headers: httpHeaders })
					.pipe(
						map((res: any) => {
							const jsonConvert: JsonConvert = new JsonConvert();
							const result: BoxSizeAnalysisObjectData = jsonConvert.deserializeObject(res, BoxSizeAnalysisObjectData);
							if (result.success) {
								return result.data;
							} else {
								this.httpUtils.handleError(result);
								return new BoxSizeAnalysisObject();
							}
						}, catchError(err => {
							if (err.status === 401) { // Unauthorized
								this.httpUtils.logout();
							}
							throw err;
						}))
					);
			})
		);
	} catch (error) {
		throw error;
	}
	}

	/** Get statistics about locker usage by users
	 * @param since
	 * @param until
	 * @param granularity
	 * @param locationUuid
	 * @param lockerUuid
	 */
	getUsersOperationsStatistics(since: string, until: string, locationUuid?:string, lockerUuid?: string): Observable<UsersOperationsAnalysisObject>{
		try {
			return this.httpUtils.getHTTPHeadersAsync().pipe(
				mergeMap(httpHeaders => {
				let url = `${this.endpoint}/statistics/operations?since=${since}&until=${until}`;
				if(locationUuid){
					url = url + `&location=${locationUuid}`;
				}
				if(lockerUuid){
					url = url + `&locker=${lockerUuid}`;
				}
				return this.http.get(url, { headers: httpHeaders })
					.pipe(
						map((res: any) => {
							const jsonConvert: JsonConvert = new JsonConvert();
							const result: UsersOperationsAnalysisObjectData = jsonConvert.deserializeObject(res, UsersOperationsAnalysisObjectData);
							if (result.success) {
								return result.data;
							} else {
								this.httpUtils.handleError(result);
								return new UsersOperationsAnalysisObject();
							}
						}, catchError(err => {
							if (err.status === 401) { // Unauthorized
								this.httpUtils.logout();
							}
							throw err;
						}))
					);
				})
			);
		} catch (error) {
			throw error;
		}
	}

	/** Get statistics about number of deliveries per hour
	 * @param since
	 * @param until
	 * @param granularity
	 * @param locationUuid
	 * @param lockerUuid
	*/
	getDeliveriesPerHourStatistics(since: string, until: string, locationUuid?: string, lockerUuid?: string): Observable<Array<AnalysisObject>> {
		try {
			return this.httpUtils.getHTTPHeadersAsync().pipe(
				mergeMap(httpHeaders => {
				let url = `${this.endpoint}/statistics/deliveriesPerHour?since=${since}&until=${until}`;
				if(locationUuid){
					url = url + `&location=${locationUuid}`;
				}
				if(lockerUuid){
					url = url + `&locker=${lockerUuid}`;
				}
				return this.http.get(url, { headers: httpHeaders })
					.pipe(
						map((res: any) => {
							const jsonConvert: JsonConvert = new JsonConvert();
							const result: AnalysisObjectArrayData = jsonConvert.deserializeObject(res, AnalysisObjectArrayData);
							if (result.success) {
								return result.data.sort((a, b) => parseInt(a.key) - parseInt(b.key));
							} else {
								this.httpUtils.handleError(result);
								return [];
							}
						}, catchError(err => {
							if (err.status === 401) { // Unauthorized
								this.httpUtils.logout();
							}
							throw err;
						}))
					);
				})
			);
		} catch (error) {
			throw error;
		}
	}

	/** Get statistics about number of returns per hour
	 * @param since
	 * @param until
	 * @param granularity
	 * @param locationUuid
	 * @param lockerUuid
	*/
	getReturnsPerHourStatistics(since: string, until: string, locationUuid?: string, lockerUuid?: string): Observable<Array<AnalysisObject>> {
		try {
			return this.httpUtils.getHTTPHeadersAsync().pipe(
				mergeMap(httpHeaders => {
				let url = `${this.endpoint}/statistics/returnsPerHour?since=${since}&until=${until}`;
				if(locationUuid){
					url = url + `&location=${locationUuid}`;
				}
				if(lockerUuid){
					url = url + `&locker=${lockerUuid}`;
				}
				return this.http.get(url, { headers: httpHeaders })
					.pipe(
						map((res: any) => {
							const jsonConvert: JsonConvert = new JsonConvert();
							const result: AnalysisObjectArrayData = jsonConvert.deserializeObject(res, AnalysisObjectArrayData);
							if (result.success) {
								return result.data.sort((a, b) => parseInt(a.key) - parseInt(b.key));
							} else {
								this.httpUtils.handleError(result);
								return [];
							}
						}, catchError(err => {
							if (err.status === 401) { // Unauthorized
								this.httpUtils.logout();
							}
							throw err;
						}))
					);
				})
			);
		} catch (error) {
			throw error;
		}
	}

	/** Get statistics about days in which deliveries were made and their number
	 * @param since
	 * @param until
	 * @param granularity
	 * @param locationUuid
	 * @param lockerUuid
	*/
	getDeliveriesPerDayStatistics(since: string, until: string, locationUuid?: string, lockerUuid?: string): Observable<Array<DateAnalysisObject>> {
		try {
			return this.httpUtils.getHTTPHeadersAsync().pipe(
				mergeMap(httpHeaders => {
				let url = `${this.endpoint}/statistics/deliveriesPerDay?since=${since}&until=${until}`;
				if(locationUuid){
					url = url + `&location=${locationUuid}`;
				}
				if(lockerUuid){
					url = url + `&locker=${lockerUuid}`;
				}
				return this.http.get(url, { headers: httpHeaders })
					.pipe(
						map((res: any) => {
							const jsonConvert: JsonConvert = new JsonConvert();
							const result: DateAnalysisObjectArrayData = jsonConvert.deserializeObject(res, DateAnalysisObjectArrayData);
							if (result.success) {
								return result.data.sort((a, b) => a.key - b.key);
							} else {
								this.httpUtils.handleError(result);
								return [];
							}
						}, catchError(err => {
							if (err.status === 401) { // Unauthorized
								this.httpUtils.logout();
							}
							throw err;
						}))
					);
				})
			);
		} catch (error) {
			throw error;
		}
	}

	/** Get statistics about days in which returns were made and their number
	 * @param since
	 * @param until
	 * @param granularity
	 * @param locationUuid
	 * @param lockerUuid
	*/
	getReturnsPerDayStatistics(since: string, until: string, locationUuid?: string, lockerUuid?: string): Observable<Array<DateAnalysisObject>> {
		try {
			return this.httpUtils.getHTTPHeadersAsync().pipe(
				mergeMap(httpHeaders => {
				let url = `${this.endpoint}/statistics/returnsPerDay?since=${since}&until=${until}`;
				if(locationUuid){
					url = url + `&location=${locationUuid}`;
				}
				if(lockerUuid){
					url = url + `&locker=${lockerUuid}`;
				}
				return this.http.get(url, { headers: httpHeaders })
					.pipe(
						map((res: any) => {
							const jsonConvert: JsonConvert = new JsonConvert();
							const result: DateAnalysisObjectArrayData = jsonConvert.deserializeObject(res, DateAnalysisObjectArrayData);
							if (result.success) {
								return result.data.sort((a, b) => a.key - b.key);
							} else {
								this.httpUtils.handleError(result);
								return [];
							}
						}, catchError(err => {
							if (err.status === 401) { // Unauthorized
								this.httpUtils.logout();
							}
							throw err;
						}))
					);
				})
			);
		} catch (error) {
			throw error;
		}
	}

	/**
	 * Get statistics about days with active overdues and their number
	 * @param {string} since - The start date of the period to be analyzed.
	 * @param {string} until - The end date of the period to be analyzed.
	 * @param {string} [locationUuid] - The UUID of the location to filter the statistics by.
	 * @param {string} [lockerUuid] - The UUID of the locker to filter the statistics by.
	 * @returns An array of DateAnalysisObjects
	 */
	getOverduesPerDayStatistics(since: string, until: string, locationUuid?: string, lockerUuid?: string): Observable<Array<DateAnalysisObject>> {
		try {
			return this.httpUtils.getHTTPHeadersAsync().pipe(
				mergeMap(httpHeaders => {
				let url = `${this.endpoint}/statistics/overduesPerDay?since=${since}&until=${until}`;
				if(locationUuid){
					url = url + `&location=${locationUuid}`;
				}
				if(lockerUuid){
					url = url + `&locker=${lockerUuid}`;
				}
				return this.http.get(url, { headers: httpHeaders })
					.pipe(
						map((res: any) => {
							const jsonConvert: JsonConvert = new JsonConvert();
							const result: DateAnalysisObjectArrayData = jsonConvert.deserializeObject(res, DateAnalysisObjectArrayData);
							if (result.success) {
								return result.data.sort((a, b) => a.key - b.key);
							} else {
								this.httpUtils.handleError(result);
								return [];
							}
						}, catchError(err => {
							if (err.status === 401) { // Unauthorized
								this.httpUtils.logout();
							}
							throw err;
						}))
					);
				})
			);
		} catch (error) {
			throw error;
		}
	}
}