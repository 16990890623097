<nz-card widgetLocker 
    [nzTitle]="title" 
    class="h-100">
    <ng-container *ngIf="loading;else loading">
        <div class="d-flex justify-content-end mb-3">
            <button nz-button nzType="default" (click)="exportToCsv()" [disabled]="!statistics">{{'statistics.exportToCsv' | translate}}</button>
        </div>
        <nz-descriptions nzBordered *ngIf="statistics;else noData" [nzColumn]="1">
            <nz-descriptions-item [nzTitle]="'statistics.tableHeaders.totalUsers' | translate">
                {{statistics.active_users != null ? statistics.active_users : '---'}}
            </nz-descriptions-item>
            <nz-descriptions-item [nzTitle]="'statistics.tableHeaders.usersMadeOperations' | translate">
                {{statistics.operations_users != null ? statistics.operations_users : '---'}}
            </nz-descriptions-item>
            <nz-descriptions-item [nzTitle]="'statistics.tableHeaders.operationsPerUserAverage' | translate">
                {{statistics.operations_per_user_average != null ? (statistics.operations_per_user_average | number : '1.0-2') : '---' }}
            </nz-descriptions-item>
            <nz-descriptions-item [nzTitle]="'statistics.tableHeaders.storageRangeAverage' | translate">
                {{statistics.storage_range_average != null ? (statistics.storage_range_average | number : '1.0-2') : '---'}}
            </nz-descriptions-item>
            <nz-descriptions-item [nzTitle]="'statistics.tableHeaders.overdueTimes' | translate">
                {{statistics.overdue_times != null ? statistics.overdue_times : '---'}}
            </nz-descriptions-item>
            <nz-descriptions-item [nzTitle]="'statistics.tableHeaders.overdueDurationAverage' | translate">
                {{statistics.overdue_duration_average != null ? (statistics.overdue_duration_average | number : '1.0-2') : '---'}}
            </nz-descriptions-item>
            <nz-descriptions-item [nzTitle]="'statistics.tableHeaders.operationsTotalNumber' | translate">
                {{statistics.operations_total_number != null ? statistics.operations_total_number : '---'}}
            </nz-descriptions-item>
            <nz-descriptions-item [nzTitle]="'statistics.tableHeaders.conciergeOrTestOperations' | translate">
                {{statistics.concierge_or_test_operations != null ? statistics.concierge_or_test_operations : '---'}}
            </nz-descriptions-item>
        </nz-descriptions>
    </ng-container>
</nz-card>

<ng-template #title>
	<h5 class="title text-truncate">{{'statistics.usersOperationsAnalysis' | translate | uppercase}}</h5>
    <span class="text-muted subtitle">{{'statistics.usersOperationsAnalysisSubtitle' | translate}}</span>
</ng-template>

<ng-template #loading>
    <div class="full-page-loader">
        <nz-spin nzTip="{{'general.loaderMessage' | translate}}..."></nz-spin>
    </div>
</ng-template>

<ng-template #noData>
    <nz-empty nzNotFoundImage="simple"></nz-empty>
</ng-template>