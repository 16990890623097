import { JsonConvert, JsonConverter, JsonCustomConvert, JsonObject, JsonProperty } from "json2typescript";
import { DateConverter } from "../helpers/converters/date-converter";

@JsonConverter
export class DashboardUserConverter implements JsonCustomConvert<DashboardUser> {
    serialize() {}
    deserialize(data: any): DashboardUser {
        const jsonConvert: JsonConvert = new JsonConvert();
        return jsonConvert.deserializeObject(data, DashboardUser);
    }
}

@JsonObject("DashboardUser")
export class DashboardUser {
    @JsonProperty("id", String, true)
	id: string = "";
    @JsonProperty("first_name", String, true)
	first_name: string = "";
	@JsonProperty("last_name", String, true)
	last_name: string = "";
    @JsonProperty("avatar", String, true)
	avatar: string = "";
    @JsonProperty("email", String, true)
	email: string = "";
    @JsonProperty("created_at", DateConverter, true)
	created_at: Date = new Date();
    @JsonProperty("updated_at", DateConverter, true)
	updated_at: Date = new Date();

	dto() {
		return {
			id: this.id,
			first_name: this.first_name,
			last_name: this.last_name,
			avatar: this.avatar,
			email: this.email,
			created_at: this.created_at,
			updated_at: this.updated_at,
		}
	}
}