/**
 * Image shapes
 * - Square
 * - Horizontal
 * - Vertical
 */
export enum ImageShape {
    Square = 'Square',
    Horizontal = 'Horizontal',
    Vertical = 'Vertical'
}