import { JsonConvert, JsonConverter, JsonCustomConvert, JsonObject, JsonProperty } from "json2typescript";
import { AnalysisObject, AnalysisObjectArrayConverter } from "./analysis-object";
import { Resp } from "./resp";

@JsonObject("BoxSizeAnalysisObject")
export class BoxSizeAnalysisObject {
    @JsonProperty("operations", Number, true)
    operations: number = 0;
    @JsonProperty("data", AnalysisObjectArrayConverter, true)
    data: Array<AnalysisObject> = [];
}

@JsonConverter
export class BoxSizeAnalysisObjectConverter implements JsonCustomConvert<BoxSizeAnalysisObject> {
    serialize() {}
    deserialize(data: any): BoxSizeAnalysisObject {
        const jsonConvert: JsonConvert = new JsonConvert();
        return jsonConvert.deserializeObject(data, BoxSizeAnalysisObject);
    }
}

@JsonObject("BoxSizeAnalysisObjectData")
export class BoxSizeAnalysisObjectData extends Resp{
    @JsonProperty("data", BoxSizeAnalysisObjectConverter, true)
	data: BoxSizeAnalysisObject = new BoxSizeAnalysisObject();
}