import { JsonConverter, JsonCustomConvert } from "json2typescript";

@JsonConverter
export class StopBitsConverter implements JsonCustomConvert<StopBits> {
	serialize(): any { }
	deserialize(data: any): StopBits {
		let resp: StopBits = StopBits.UNKNOWN;

		switch(data) {
			case "ONE_STOP_BIT": {
				resp = StopBits.ONE_STOP_BIT;
				break;
			}
			case "ONE_POINT_FIVE": {
				resp = StopBits.ONE_POINT_FIVE;
				break;
			}
			case "TWO": {
				resp = StopBits.TWO;
				break;
			}
			default: {
				resp = StopBits.UNKNOWN;
				break;
			}
		}

		return resp;
	}
}

export enum StopBits {
	UNKNOWN = "UNKNOWN",
	ONE_STOP_BIT = "ONE_STOP_BIT",
	ONE_POINT_FIVE = "ONE_POINT_FIVE",
	TWO = "TWO"
}