import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Helpers } from '../helpers';
import { Account } from '../models/account';

@Injectable({
	providedIn: 'root'
})
export class IdentityService {

	account$: BehaviorSubject<Account | null> = new BehaviorSubject<Account | null>(null);

	constructor() {
		const account: Account | null = Helpers.getIdentity();
		this.update(account);
	}

	/** Save account (identity) in local storage and emit new identity update event */
	update(account: Account | null): void {
		Helpers.setIdenity(account);
		this.account$.next(account);
	}
}
