import { JsonConvert, JsonConverter, JsonCustomConvert, JsonObject, JsonProperty } from 'json2typescript';


@JsonConverter
export class AppearanceConverter implements JsonCustomConvert<Appearance> {
  serialize() { }

  deserialize(data: any): Appearance {
    const jsonConvert: JsonConvert = new JsonConvert();
    const response = jsonConvert.deserializeObject(data, Appearance);
    return response;
  }
}

@JsonObject('Appearance')
export class Appearance {
	@JsonProperty('app_download_url', String, true)
	appDownloadUrl: string = '';

	@JsonProperty('user_enabled', Boolean, true)
	userEnabled: boolean = false;

	@JsonProperty('courier_enabled', Boolean, true)
	courierEnabled: boolean = false;

	@JsonProperty('concierge_enabled', Boolean, true)
	conciergeEnabled: boolean = false;

	@JsonProperty('user_return_enabled', Boolean, true)
	userReturnEnabled: boolean = false;

	@JsonProperty('user_delegation_enabled', Boolean, true)
	userDelegationEnabled: boolean = false;

	@JsonProperty('user_exchange_enabled', Boolean, true)
	userExchangeEnabled: boolean = false;

	@JsonProperty('user_selection_enabled', Boolean, true)
	userSelectionEnabled: boolean = false;

	dto() {
		return {
			app_download_url: this.appDownloadUrl,
			user_enabled: this.userEnabled,
			courier_enabled: this.courierEnabled,
			concierge_enabled: this.conciergeEnabled,
			user_return_enabled: this.userReturnEnabled,
			user_delegation_enabled: this.userDelegationEnabled,
			user_exchange_enabled: this.userExchangeEnabled,
			user_selection_enabled: this.userSelectionEnabled			
		}
	}
}
