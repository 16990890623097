import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.less']
})
export class VersionComponent implements OnInit {
	version: string = environment.version;
	today = new Date();

	constructor() { }

	ngOnInit(): void {
	}

}
