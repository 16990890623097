import { ElementRef, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ElementCreatedService {

  elementCreated: BehaviorSubject<ElementRef | null> = new BehaviorSubject<ElementRef | null>(null);
  
  constructor() { }

  isCreated(element: ElementRef): void {
    this.elementCreated.next(element);
  }
}
