import { JsonConvert, JsonConverter, JsonCustomConvert, JsonObject, JsonProperty } from "json2typescript";
import { LockerStatus, LockerStatusConverter } from "./locker-status.enum";
import { Resp } from "./resp";

@JsonConverter
export class LockerConnectivityStatusConverter implements JsonCustomConvert<LockerConnectivityStatus> {
    serialize() {}
    deserialize(data: any): LockerConnectivityStatus {
        const convert: JsonConvert = new JsonConvert;
        return convert.deserializeObject(data, LockerConnectivityStatus);
    }
}

@JsonObject("LockerConnectivityStatus")
export class LockerConnectivityStatus {
    @JsonProperty("status", LockerStatusConverter, true)
    status: LockerStatus | undefined = undefined;
    @JsonProperty("last_seen", Number, true)
    last_seen: number = 0;
}

@JsonObject("LockerConnectivityStatusData")
export class LockerConnectivityStatusData extends Resp {
    @JsonProperty("data", LockerConnectivityStatusConverter, true)
    data: LockerConnectivityStatus = new LockerConnectivityStatus();
}