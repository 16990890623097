export const it_IT: any = {
    locale: 'it',
    Pagination: {
        items_per_page: '/ pagina',
        jump_to: 'vai a',
        jump_to_confirm: 'Conferma',
        page: '',
        prev_page: 'Pagina precedente',
        next_page: 'Pagina successiva',
        prev_5: 'Precedente 5 pagine',
        next_5: 'Prossime 5 pagine',
        prev_3: 'Precedente 3 pagine',
        next_3: 'Prossime 3 pagine'
    },
    DatePicker: {
        lang: {
            placeholder: 'Selezionare la data',
            rangePlaceholder: ["Data d'inizio", 'Data di fine'],
            locale: 'it_IT',
            today: 'Oggi',
            now: 'Adesso',
            backToToday: 'Torna ad oggi',
            ok: 'Ok',
            clear: 'Cancella',
            month: 'Mese',
            year: 'Anno',
            timeSelect: "Seleziona l'ora",
            dateSelect: 'Seleziona la data',
            monthSelect: 'Seleziona il mese',
            yearSelect: "Seleziona l'anno",
            decadeSelect: 'Seleziona il decennio',
            yearFormat: 'YYYY',
            dateFormat: 'D/M/YYYY',
            dayFormat: 'D',
            dateTimeFormat: 'D/M/YYYY HH:mm:ss',
            monthBeforeYear: true,
            previousMonth: 'Il mese scorso (PageUp)',
            nextMonth: 'Il prossimo mese (PageDown)',
            previousYear: "L'anno scorso (Control + sinistra)",
            nextYear: "L'anno prossimo (Control + destra)",
            previousDecade: 'Ultimo decennio',
            nextDecade: 'Prossimo decennio',
            previousCentury: 'Secolo precedente',
            nextCentury: 'Prossimo secolo',
            yearPlaceholder: 'Seelziona un anno',
            monthPlaceholder: 'Seleziona un mese',
            weekPlaceholder: 'Seleziona una settimana',
            rangeYearPlaceholder: ["Data d'inizio", 'Data di fine'],
            rangeMonthPlaceholder: ["Mese d'inizio",  "Mese di fine"],
            rangeWeekPlaceholder: ["Settimana d'inizio",  "Settimana di fine"],
        },
        timePickerLocale: {
            placeholder: "Selezionare l'orario"
        }
    },
    TimePicker: {
        placeholder: "Selezionare l'orario"
    },
    Calendar: {
        lang: {
            placeholder: 'Selezionare la data',
            rangePlaceholder: ["Data d'inizio", 'Data di fine'],
            locale: 'it_IT',
            today: 'Oggi',
            now: 'Adesso',
            backToToday: 'Torna ad oggi',
            ok: 'Ok',
            clear: 'Cancella',
            month: 'Mese',
            year: 'Anno',
            timeSelect: "Seleziona l'ora",
            dateSelect: 'Seleziona la data',
            monthSelect: 'Seleziona il mese',
            yearSelect: "Seleziona l'anno",
            decadeSelect: 'Seleziona il decennio',
            yearFormat: 'YYYY',
            dateFormat: 'D/M/YYYY',
            dayFormat: 'D',
            dateTimeFormat: 'D/M/YYYY HH:mm:ss',
            monthBeforeYear: true,
            previousMonth: 'Il mese scorso (PageUp)',
            nextMonth: 'Il prossimo mese (PageDown)',
            previousYear: "L'anno scorso (Control + sinistra)",
            nextYear: "L'anno prossimo (Control + destra)",
            previousDecade: 'Ultimo decennio',
            nextDecade: 'Prossimo decennio',
            previousCentury: 'Secolo precedente',
            nextCentury: 'Prossimo secolo'
        },
        timePickerLocale: {
            placeholder: "Selezionare l'orario"
        }
    },
    global: {
        placeholder: 'Selezionare'
    },
    Table: {
        filterTitle: 'Menù Filtro',
        filterConfirm: 'OK',
        filterReset: 'Reset',
        selectAll: 'Seleziona pagina corrente',
        selectInvert: 'Inverti selezione nella pagina corrente',
        sortTitle: 'Ordina',
        triggerDesc: 'Clicca per ordinare in modo discendente',
        triggerAsc: ' Clicca per ordinare in modo ascendente',
        cancelSort: 'Clicca per eliminare i filtri'
    },
    Modal: {
        okText: 'OK',
        cancelText: 'Annulla',
        justOkText: 'OK'
    },
    Popconfirm: {
        okText: 'OK',
        cancelText: 'Annulla'
    },
    Transfer: {
        searchPlaceholder: 'Cerca qui',
        itemUnit: 'elemento',
        itemsUnit: 'elementi'
    },
    Upload: {
        uploading: 'Caricamento...',
        removeFile: 'Rimuovi il file',
        uploadError: 'Errore di caricamento',
        previewFile: 'Anteprima file',
        downloadFile: 'Download file'
    },
    Empty: {
        description: 'Nessun dato'
    },
    Icon: {
        icon: 'icona'
    },
    Text: {
        edit: 'modifica',
        copy: 'copia',
        copied: 'copia effettuata',
        expand: 'espandi'
    }
};