import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { Subscription } from 'rxjs';
import { Helpers } from 'src/app/core/helpers';
import { LogList } from 'src/app/core/models/log';
import { Paging } from 'src/app/core/models/paged-data';
import { PlatformUsersList } from 'src/app/core/models/platform-user';
import { LockerService } from 'src/app/core/services/network/locker.service';

@Component({
	selector: 'app-locker-logs',
	templateUrl: './locker-logs.component.html',
	styleUrls: ['./locker-logs.component.less']
})
export class LockerLogsComponent implements OnInit, OnDestroy {
	@Input() lockerUuid: string = "";
	@Input() lockerDescription!: string;
	@Input() showDelete: boolean = false;
	@Input() showNotification: boolean = true;

	@Output() onDelete = new EventEmitter();

	subscriptions: Array<Subscription> = [];
	users: PlatformUsersList = new PlatformUsersList();
	loading: boolean = false;
	userId: string = "";
	logs: LogList = new LogList();
	noLockerVisibility: boolean = false;
	paging: Paging = new Paging();
	isFirst: boolean = true;
	csvLoading: boolean = false;
	helpers = Helpers;

	constructor(private lockerService: LockerService,
		private router: Router,
		private notification: NzNotificationService,
		private translate: TranslateService) { }

	ngOnInit(): void {
		this.getLockerUsers(this.lockerUuid);
	}

	getLockerUsers(lockerUuid: string): void {
		this.subscriptions.push(
			this.lockerService.getLockerUsers(lockerUuid).subscribe(resp => {
				this.users = resp;
				this.loading = false;
			}, err => {
				console.error("GET Lorker logs", err);
				this.loading = false;
				if (err.statusCode === 403 || err.status === 403) {
					this.noLockerVisibility = true;
				} else {
					if (this.showNotification) {
						Helpers.errorNotification(this.notification, this.translate, 'monitor.lockerUsers.errors.unableToGetLockerUsers');
					}
				}
			})
		);
	}

	getLogs(): void {
		this.subscriptions.push(			
			this.lockerService.getLockerUserLogs(this.lockerUuid, this.userId, this.paging).subscribe(resp => {
				this.logs = resp;
				this.loading = false;
			}, err => {
				console.error(err);
				this.loading = false;
				if (err.statusCode === 403 || err.status === 403) {
					this.noLockerVisibility = true;
				} else {
					if (this.showNotification) {
						Helpers.errorNotification(this.notification, this.translate, 'monitor.logs.errors.unableToGetLogs');
					}
				}
			})
		);
	}

	// userEmail: string | undefined = "";
	onSelectChange() {
		this.logs.reset();
		if (this.userId) {
			this.getLogs();
		}
	}

	onQueryParamsChange(paramas: NzTableQueryParams) {
		const { pageSize, pageIndex } = paramas;
		this.paging.pageIndex = pageIndex;
		this.paging.pageSize = pageSize;
		if(!this.isFirst) {
			this.getLogs();
		}
		this.isFirst = false;
	}

	exportToCsv() {
		let paging: Paging = new Paging();
		paging.pageSize = this.logs.count;
		this.csvLoading = true;
		this.subscriptions.push(			
			this.lockerService.getLockerUserLogs(this.lockerUuid, this.userId, paging).subscribe(logs => {
				let csv = 'data:text/csv;charset=utf-8,';
				csv += this.translate.instant('general.startDate') + ';'
					+ this.translate.instant('general.endDate') + ';'
					+ this.translate.instant('general.box') + ';'
					+ this.translate.instant('monitor.lockerUsers.type') + '\n';
				logs.data.forEach(item => {
					let row = '';
					row += `${this.formatDate(item.start)};${this.formatDate(item.end)};${item.boxId};${item.type ? (this.translate.instant('general.' + item.type!.toLowerCase())) :  '-'}\n`;
					csv += row;
				});
				let encodedUri = encodeURI(csv);
				let link = document.createElement("a");
				link.setAttribute("href", encodedUri);
				let name: string | undefined = this.users.data.find(u => u.uuid === this.userId)?.firstName;
				let lastname: string | undefined = this.users.data.find(u => u.uuid === this.userId)?.lastName;
				link.setAttribute("download", `${name && lastname ? name + ' ' + lastname : 'logsbook'}.csv`);
				document.body.appendChild(link); // Required for FF
				link.click();
				this.csvLoading = false;
			}, err => {
				console.error(err);
				this.csvLoading = false;
				Helpers.errorNotification(this.notification, this.translate, 'monitor.logs.errors.unableToCreateSvgFile');
			})
		);
	}

	private formatDate(timestamp: number | null): string {
		if(timestamp){
			let lang = this.helpers.getLanguage();
			return moment(timestamp).locale(lang).format('lll');
		}else {
			return this.translate.instant('general.inProgress');
		}
	}

	confirmDelete() {
		this.onDelete.emit();
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(s => s.unsubscribe());
	}
}
