import { Menu } from "../models/menu";
import { Role } from "../models/role";

/** Creating an array of objects that will be used to create the sidebar menu. */
export const MenuConfig: Array<Menu> = [
	{
		icon: 'home',
		translate: 'Home',
		link: '/home',
		level: 1,
		roles: [Role.GOD, Role.SUPER_ADMIN, Role.ADMIN, Role.CONCIERGE, Role.SERVICE]
	},
	{
		icon: 'user',
		translate: 'menu.account',
		link: '/account',
		level: 1,
		roles: [Role.GOD, Role.SUPER_ADMIN, Role.ADMIN, Role.CONCIERGE, Role.SERVICE]
	},
	{
		icon: 'monitor',
		translate: 'menu.monitor',
		link: '/monitor',
		level: 1,
		roles: [Role.GOD, Role.SUPER_ADMIN, Role.ADMIN, Role.CONCIERGE, Role.SERVICE]
	},
	{
		icon: 'line-chart',
		translate: 'menu.statistics',
		link: '/statistics',
		level: 1,
		roles: [Role.GOD, Role.SUPER_ADMIN, Role.ADMIN]
	},
	{
		icon: 'appstore-add',
		translate: 'menu.administration',
		level: 1,
		roles: [Role.GOD, Role.SUPER_ADMIN, Role.ADMIN],
		subMenu: [
			{
				icon: 'user',
				translate: 'menu.platformUsers',
				link: '/administration/users',
				level: 2,
				roles: [Role.GOD, Role.SUPER_ADMIN, Role.ADMIN],
			},
			{
				icon: 'team',
				translate: 'menu.backofficeUsers',
				link: '/administration/backoffice',
				level: 2,
				roles: [Role.GOD, Role.SUPER_ADMIN, Role.ADMIN],
			},
			{
				icon: 'hdd',
				translate: 'menu.lockers',
				link: '/administration/lockers',
				level: 2,
				roles: [Role.SUPER_ADMIN, Role.ADMIN],
			},
			{
				icon: 'hdd',
				translate: 'menu.lockers',
				link: 'administration/lockers-management',
				level: 2,
				roles: [Role.GOD],
			},
			{
				icon: 'environment',
				translate: 'general.locations',
				link: '/administration/locations',
				level: 2,
				roles: [Role.SUPER_ADMIN, Role.ADMIN],
			},
			{
				icon: 'environment',
				translate: 'general.locations',
				link: 'administration/locations-management',
				level: 2,
				roles: [Role.GOD],
			}
		]
	},
	// {
	// 	icon: 'setting',
	// 	translate: 'menu.functions',
	// 	link: '/functions',
	// 	level: 1,
	// 	roles: [Role.GOD, Role.SUPER_ADMIN, Role.ADMIN]
	// },
];

