import { JsonConvert, JsonConverter, JsonCustomConvert, JsonObject, JsonProperty } from "json2typescript";
import { Address, AddressConverter } from "./address";
import { Resp } from "./resp";

@JsonConverter
export class LocationConverter implements JsonCustomConvert<Location> {
    serialize() { }
    deserialize(data: any): Location {
        const jsonConvert: JsonConvert = new JsonConvert();
        return jsonConvert.deserializeObject(data, Location);
    }
}

@JsonConverter
export class LocationsListConverter implements JsonCustomConvert<LocationsList> {
    serialize() { }
    deserialize(data: any): LocationsList {
        const jsonConvert: JsonConvert = new JsonConvert();
        return jsonConvert.deserializeObject(data, LocationsList);
    }
}

@JsonConverter
export class LocationsArrayConverter implements JsonCustomConvert<Array<Location>> {
    serialize() { }
    deserialize(data: any): Array<Location> {
        const jsonConvert: JsonConvert = new JsonConvert();
        return jsonConvert.deserializeArray(data, Location);
    }
}

@JsonObject("Location")
export class Location {
    @JsonProperty("uuid", String, true)
    uuid: string = "";
    @JsonProperty("name", String, true)
    name: string = "";
    @JsonProperty("address", AddressConverter, true)
    address: Address = new Address();

    /** Return right body for the creation and updating requests */
    dto() {
        return {
            name: this.name,
            address: this.address
        }
    }

    getAddress(): string {
		let arrayName: Array<string> = [
		(this.address.address ? this.address.address : ''),
		(this.address.city ? this.address.city : ''),
		(this.address.province ? `(${this.address.province})` : '')];
		arrayName = arrayName.filter(item => item != null && item != '');
		return arrayName.join(" ");
	}
}

@JsonObject("LocationData")
export class LocationData extends Resp {
    @JsonProperty("data", LocationConverter, true)
    data: Location = new Location();
}

@JsonObject("LocationsList")
export class LocationsList {
    @JsonProperty("count", Number, true)
    count: number = 0;
    @JsonProperty("data", LocationsArrayConverter, true)
    data: Array<Location> = [];
}

@JsonObject("LocationsListData")
export class LocationsListData extends Resp {
    @JsonProperty("data", LocationsListConverter, true)
    data: LocationsList = new LocationsList();
}