import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { LockerService } from 'src/app/core/services/network/locker.service';
import { Box } from 'src/app/core/models/box';
import { BoxStatus } from 'src/app/core/models/box-status';
import { Helpers } from 'src/app/core/helpers';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-show-boxes-status',
	templateUrl: './show-boxes-status.component.html',
	styleUrls: ['./show-boxes-status.component.less']
})
export class ShowBoxesStatusComponent implements OnInit, OnDestroy {

	@Input() lockerUuid = '';
	@Input() fixedWidth: boolean = false;

	subscriptions: Array<Subscription> = [];
	boxesStatus: Map<BoxStatus, number> = new Map;
	boxStatus = BoxStatus;
	noLockerVisibility: boolean = false;

	constructor(private service: LockerService,
		private notification: NzNotificationService,
		private translate: TranslateService) { }

	ngOnInit(): void {
		this.getBoxesStatus(this.lockerUuid);
	}

	/** Get locker math and its boxes status
	  * @param lockerUuid
	*/
	getBoxesStatus(lockerUuid: string) {
		let boxes: Array<Box> = [];
		this.subscriptions.push(
			this.service.getLockerMath(lockerUuid).subscribe(resp => {
				if (resp) {
					boxes = resp;
					this.getSumAllStatus(boxes);
				} else {
					Helpers.errorNotification(this.notification, this.translate, 'monitor.math.errors.unableToGetMath');
				}
			}, err => {
				console.error(err);
				if (err.statusCode === 403 || err.status === 403) {
					this.noLockerVisibility = true;
				}
			})
		);
	}

	/** Return the number of boxes for each status  */
	private getSumAllStatus(boxes: Array<Box>): Map<BoxStatus, number> {
		boxes.forEach(b => {
			if (this.boxesStatus.has(b.status)) {
				this.boxesStatus.set(b.status, this.boxesStatus.get(b.status)! + 1)
			} else {
				this.boxesStatus.set(b.status, 1);
			}
		});
		Object.keys(BoxStatus).forEach(status => {
			if (!this.boxesStatus.has(status as BoxStatus)) {
				this.boxesStatus.set(status as BoxStatus, 0)
			}
		});
		return this.boxesStatus;
	}

	/** Return the number of boxes in the given status 
	 * @param status
	*/
	getSumByStatus(status: BoxStatus): number | undefined {
		return this.boxesStatus.get(status);
	}

	/** Refresh boxes status */
	onRefresh(): void {
		this.getBoxesStatus(this.lockerUuid);
		console.info("Boxes status refreshed");
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(s => s.unsubscribe());
	}

}
