<nz-card widgetLocker [nzTitle]="connectionTitle" [nzExtra]="showDelete? extras: extrasRefresh" class="h-100">
    <app-connection-status [lockerUuid]="lockerUuid"></app-connection-status>
</nz-card>

<ng-template #connectionTitle>
    <h5 class="title">
        {{'monitor.connectivityStatus.title' | translate | uppercase}}
        <small *ngIf="lockerDescription">{{lockerDescription}}</small>
    </h5>
    <span class="text-muted subtitle">{{'monitor.math.subtitle' | translate}}</span>
</ng-template>

<ng-template #extrasRefresh>
    <button nz-button (click)="onRefresh()"><span nz-icon [nzType]="'sync'"></span></button>
</ng-template>

<ng-template #extras>
    <button nz-button nz-dropdown [nzDropdownMenu]="menu" nzPlacement="bottomRight"><span nz-icon nzType="ellipsis"></span></button>
    <nz-dropdown-menu #menu="nzDropdownMenu">
        <ul nz-menu>
            <li nz-menu-item (click)="onRefresh()"><span nz-icon nzType="sync" class="me-2"></span> {{'general.update' | translate}}</li>
            <li nz-menu-item nz-popconfirm
                nzPopconfirmTitle="{{'widgets.confirmDelete' | translate}}"
                nzPopconfirmPlacement="top"
                (nzOnConfirm)="confirmDelete()">
                <span nz-icon nzType="delete" class="me-2"></span> {{'general.delete' | translate}}</li>
        </ul>
    </nz-dropdown-menu>
</ng-template>