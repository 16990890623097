import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Subscription, timer } from 'rxjs';
import { Helpers } from 'src/app/core/helpers';
import { AdminLocker } from 'src/app/core/models/locker/admin-locker';
import { Box } from 'src/app/core/models/box';
import { BoxStatus } from 'src/app/core/models/box-status';
import { WidgetType } from 'src/app/core/models/widget-type';
import { AdministrationService } from 'src/app/core/services/network/administration.service';
import { LockerService } from 'src/app/core/services/network/locker.service';

@Component({
	selector: 'app-locker-monitor',
	templateUrl: './locker-monitor.component.html',
	styleUrls: ['./locker-monitor.component.less']
})
export class LockerMonitorComponent implements OnInit, OnDestroy {
	@Input() lockerUuid: string = "";
	@Input() lockerDescription!: string;
	@Input() showDelete: boolean = false;
	@Input() showNotification: boolean = true;

	@Output() onDelete = new EventEmitter();

	readonly freeColor: string = '#218a57';
	readonly busyColor: string = '#e41115';
	readonly overdueColor: string = '#efad02';
	readonly blockedColor: string = '#a9a9a9';
	readonly reservedColor: string = '#faff06';

	showLoader: boolean = true;
	svg: string = "";
	boxes: Array<Box> = [];
	isError: boolean = false;
	subscriptions: Array<Subscription> = [];
	showConnectivityStatus: boolean = false;
	locker: AdminLocker | undefined = new AdminLocker();
	noLockerVisibility: boolean = false;

	constructor(private http: HttpClient,
		private router: Router,
		private notification: NzNotificationService,
		private translate: TranslateService,
		private lockerService: LockerService, 
		private adminService: AdministrationService) { }

	ngOnInit(): void {
		// Refresh connectivity status every 5 minutes
		this.subscriptions.push(
			timer(300000, 300000).subscribe(() => {
				this.onRefresh();
				console.info("Locker's connectivity status refreshed");
			})
		);
		if (this.lockerUuid) {
			this.getLockerAndIfImageGetMath(); // get locker and locker math if locker has image
		} else {
			this.showLoader = false;
			Helpers.errorNotification(this.notification, this.translate, 'lockerMonitor.missingLockerId');
		}
	}

	/** Get and set locker and get locker's math if locker has image */
	getLockerAndIfImageGetMath(): void {
		this.subscriptions.push(
			this.adminService.getLocker(this.lockerUuid).subscribe(
				{
					next: (resp) => {
						this.locker = resp;
						if(resp && resp.image){
							this.getLockerMath(this.locker!.uuid)
						}else {
							this.showLoader = false;
							if(this.showNotification){
								Helpers.errorNotification(this.notification, this.translate, 'administration.lockers.errors.unableToGetLockerImage');
							}
							this.isError = true;
						}
					},
					error: (err) => {
						console.error(err);
						this.isError = true;
						if(err.status === 403){
							this.noLockerVisibility = true;
						}else {
							if(this.showNotification){
								Helpers.errorNotification(this.notification, this.translate, 'administration.lockers.errors.unableToGetLockerImage');
							}
						}
						this.showLoader = false;
					}
				}
			)
		);
	}

	/** Get svg file of locker, started by locker's image url */
	private getSvgLocker() {
		this.http.get(this.locker!.image, { headers: { 'Accept': 'image/svg+xml' }, responseType: 'text' }).subscribe(
			{
				next: res => {
					this.showLoader = false;
					const div = document.createElement('div');
					div.innerHTML = `${res}`;
					this.boxes.forEach(box => {
						const elements = div.getElementsByTagName('*');
						for (let i = 0; i < elements.length; i++) {
							const element: any = elements[i];
							if (element.id === `box_${box.id}`) {
								element.style.fill = this.getColorByStatus(box.status);
							}
						}
					});
					this.svg = this.getImageDataURL(div.innerHTML);
				},
				error: (err: any) => {
					console.error(err);
					this.isError = true;
					if(this.showNotification){
						Helpers.errorNotification(this.notification, this.translate, 'monitor.math.errors.unableToGetSvg');
					}
				},
			}
		);
	}

	/** Get locker's math, and if success call getSvgLocker and get availabled widgets
	 * @param lockerUuid
	*/
	getLockerMath(lockerUuid: string) {
		this.showLoader = true;
		this.subscriptions.push(
			this.lockerService.getLockerMath(lockerUuid).subscribe(resp => {
				if (resp) {
					this.boxes = resp;
					this.isError = false;
					this.getSvgLocker();
					this.getAvailableWidgets(lockerUuid);
					this.showLoader = false;
				} else {
					if(this.showNotification){
						Helpers.errorNotification(this.notification, this.translate, 'monitor.math.errors.unableToGetMath');
					}
					this.isError = true;
					this.showLoader = false;
				}
			}, err => {
				console.error(err);
				this.isError = true;
				this.showLoader = false;
				if (err.statusCode === 403 || err.status === 403) {
					//TODO: far vedere messaggio di errore al posto di nodata non hai visibilità su questo locker
					Helpers.goToUnauthorized(this.router);
				} else {
					if(this.showNotification){
						Helpers.errorNotification(this.notification, this.translate, 'monitor.math.errors.unableToGetMath');
					}
				}
			})
		);
	}

	/** Get widget list availabled for the locker
     * @param lockerUuid
	 * 
	 * set showConnectivityStatus variable
    */
	private getAvailableWidgets(lockerUuid: string): void {
		this.subscriptions.push(
			this.lockerService.getAvailableWidgets(lockerUuid).subscribe(
				{
				  next: (resp) => {
					this.showConnectivityStatus = resp.includes(WidgetType.CONNECTIVITY);
				  },
				  error: (err) => {
					console.error(err);
					Helpers.errorNotification(this.notification, this.translate, 'monitor.details.errors.unableToGetAvailableWidgets');
				  }
				}
			)
		);
	}

	getColorByStatus(status: BoxStatus) {
		switch (status) {
			case BoxStatus.BLOCKED: {
				return this.blockedColor;
			}
			case BoxStatus.BUSY: {
				return this.busyColor;
			}
			case BoxStatus.FREE: {
				return this.freeColor;
			}
			case BoxStatus.OVERDUE: {
				return this.overdueColor;
			}
			case BoxStatus.RESERVED: {
				return this.reservedColor;
			}
			default: {
				return this.blockedColor;
			}
		}
	}

	onRefresh() {
		if(this.locker && this.locker.image) this.getLockerMath(this.lockerUuid);
	}

	/**
	 * Convert SVG xml to png base64 url
	 * @param {any} svgXml
	 */
	getImageDataURL(svgXml: any) {
		return "data:image/svg+xml;base64," + btoa(Helpers.unescape(encodeURIComponent(svgXml)));
	}

	confirmDelete() {
		this.onDelete.emit();
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(s => s.unsubscribe());
	}
}
