import { Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Helpers } from 'src/app/core/helpers';
import { environment } from 'src/environments/environment';
import { Account } from 'src/app/core/models/account';
import { Menu } from 'src/app/core/models/menu';
import { MenuConfig } from 'src/app/core/config/menu-config';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import { LoaderService } from 'src/app/core/services/loader.service';
import { TenantService } from 'src/app/core/services/tenant.service';
import { Tenant } from 'src/app/core/models/tenant';
import { IdentityService } from 'src/app/core/services/identity.service';
import { ElementCreatedService } from 'src/app/core/services/element-created.service';
import { Auth, getAuth } from 'firebase/auth';

@Component({
	encapsulation: ViewEncapsulation.None,
	selector: 'dashboard-dashboard-base',
	templateUrl: './dashboard-base.component.html',
	styleUrls: ['./dashboard-base.component.less']
})
export class DashboardBaseComponent implements OnInit, OnDestroy {

	@ViewChild('routerOutletContent') routerOutletContent!: ElementRef;

	sticky: boolean = false;
	statisticsHeaderPosition: any;
	statisticsHeader: any;
	statisticsContent: any;
	statisticsContentPosition: any;

	subscriptions: Array<Subscription> = [];
	isCollapsed = false;
	today = new Date();
	menuConfig: Array<Menu> = MenuConfig;
	helpers = Helpers;
	tenant: Tenant | null = new Tenant();
	identity: Account | null = new Account();

	constructor(private router: Router, 
		private spinnerService: SpinnerService, 
		private loaderService: LoaderService, 
		private tenantsService: TenantService, 
		private identityService: IdentityService,
		private createdElementService: ElementCreatedService) {
		this.subscriptions.push(
			this.router.events.subscribe(event => {
				/* It's a switch statement that checks the type of the event, in order to show spinner on module change. */
				switch (true) {
					case event instanceof NavigationStart: {
						/* It's a module when navigationTrigger is equal to imperative, otherwhise is equal to popstate */
						if ((event as NavigationStart).navigationTrigger && (event as NavigationStart).navigationTrigger === 'imperative') {
							this.spinnerService.update(true);
						}
						break;
					}
					case event instanceof NavigationEnd:
					case event instanceof NavigationCancel:
					case event instanceof NavigationError: {
						setTimeout(() => {
							this.spinnerService.update(false);
						}, 500);
						break;
					}
					default: {
						break;
					}
				}
			})
		);
	}

	ngOnInit(): void {
		this.subscriptions.push(
			this.tenantsService.tenant$.subscribe(tenant => {
				this.tenant = tenant;
			})
		);
		
		this.subscriptions.push(
			this.identityService.account$.subscribe((account) => {
				this.identity = account;
			})
		);

		this.subscriptions.push(
			this.createdElementService.elementCreated.subscribe((element: ElementRef | null) => {
				if (element && element.nativeElement.id === "statistics-header") {
					this.statisticsHeader = element;
					this.statisticsHeaderPosition = this.statisticsHeader.nativeElement.offsetTop;
				}
				if(element && element.nativeElement.id === "statistics-content") {
					this.statisticsContent = element;
				}
			})
		);
	}

	handleScroll(event: any): void {
		event.preventDefault();
		let scroll = this.statisticsHeaderPosition + document.getElementById('router-outlet-content')!.scrollTop;
		if(this.statisticsHeaderPosition){
			if (scroll > this.statisticsHeaderPosition) {
				this.statisticsHeader.nativeElement.classList.add('sticky');
			} else {
				this.statisticsHeader.nativeElement.classList.remove('sticky');
			}
		}
	}

	getAvatar() {
		if (this.identity) {
			return this.identity.user.avatar;
		} else {
			return '';
		}
	}

	getUserName() {
		if (this.identity) {
			return `${this.identity.user.first_name} ${this.identity.user.last_name}`;
		} else {
			return '';
		}
	}

	getUserInitals() {
		if (this.getUserName()) {
			const splitted: Array<string> = this.getUserName().split(' ');
			return splitted.map(e => e[0].toUpperCase()).join('');
		} else {
			return '';
		}
	}

	getLastLogin() {
		const firebaseUser = Helpers.getFirebaseUser();
		if (firebaseUser) {
			return firebaseUser.lastLoginAt || null;
		} else {
			return null;
		}
	}

	/**
	 * Logs out the user and redirects to the login page
	 */
	logout() {
		this.loaderService.update(true);
		Helpers.cleanStorage();
		localStorage.setItem('isLogout', "1");
		const auth: Auth = getAuth();
		auth.signOut().then(() => {
			this.tenantsService.updateTenant(null);
			this.tenantsService.updateTenants([]);
			localStorage.removeItem('isLogout');
			this.router.navigate([environment.firebase.params.loginPath]);
			this.loaderService.update(false);
		});
	}

	ngOnDestroy() {
		// Unsubscribe all subscriptions
		this.subscriptions.forEach(s => s.unsubscribe());
	}

}
