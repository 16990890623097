import { JsonConvert, JsonConverter, JsonCustomConvert, JsonObject, JsonProperty } from "json2typescript";
import { Resp } from "./resp";

@JsonObject("UsersOperationsAnalysisObject")
export class UsersOperationsAnalysisObject {
    @JsonProperty('active_users', Number, true)
    active_users: number | null = null;
    @JsonProperty('operations_users', Number, true)
    operations_users: number | null = null;
    @JsonProperty('operations_per_user_average', Number, true)
    operations_per_user_average: number | null = null;
    @JsonProperty('storage_range_average', Number, true)
    storage_range_average: number | null = null;
    @JsonProperty('overdue_range_average', Number, true)
    overdue_duration_average: number | null = null;
    @JsonProperty('overdue_times', Number, true)
    overdue_times: number | null = null;
    @JsonProperty('operations_total_number', Number, true)
    operations_total_number: number | null = null;
    @JsonProperty('concierge_or_test_operations', Number, true)
    concierge_or_test_operations: number | null = null;
}

@JsonConverter
export class UsersOperationsAnalysisObjectConverter implements JsonCustomConvert<UsersOperationsAnalysisObject> {
    serialize() { }
    deserialize(data: any): UsersOperationsAnalysisObject {
        const jsonConvert: JsonConvert = new JsonConvert();
        return jsonConvert.deserializeObject(data, UsersOperationsAnalysisObject);
    }
}

@JsonObject('UsersOperationsAnalysisObjectData')
export class UsersOperationsAnalysisObjectData extends Resp {
    @JsonProperty('data', UsersOperationsAnalysisObjectConverter, true)
    data: UsersOperationsAnalysisObject = new UsersOperationsAnalysisObject;
}