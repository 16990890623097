<!-- Image preview -->
<div nz-row>
    <div nz-col [nzSpan]="24" [ngClass]="alignmentClass">
        <div class="cloudinary-container">
            <div class="image-holder" 
                [attr.style]="'background-position: center center;background-color: #ccc;background-image: url(' + (this.src || this.baseImage) + ');' | safe: 'style'"
                [ngClass]="{'horizontal-image': shape === _ImageShape.Horizontal, 'vertical-image': shape === _ImageShape.Vertical}"></div>
            <label *ngIf="canEdit" class="image-upload" (click)="upload()"
                nzTooltipTitle="{{'cloudinary.addNewImage' | translate}}" nzTooltipPlacement="top" nz-tooltip>
                <i nz-icon nzType="edit" nzTheme="outline"></i>
            </label>
            <span *ngIf="canDelete" class="image-cancel" (click)="deleteImage()"
                nzTooltipTitle="{{'cloudinary.removeImage' | translate}}" nzTooltipPlacement="bottom" nz-tooltip>
               <i nz-icon nzType="delete" nzTheme="outline"></i>
            </span>
        </div>
    </div>
</div>
<!-- Loader shown on file upload -->
<div *ngIf="isLoading" nz-row>
    <div nz-col [nzSpan]="24" [ngClass]="alignmentClass">
        <div class="cloudinary-image-preview text-center">
            <i nz-icon [nzType]="'loading'"></i>
        </div>
    </div>
</div>
<!-- Upload modal -->
<nz-modal [(nzVisible)]="isVisible" nzTitle="{{ 'cloudinary.addNewImage' | translate }}" (nzOnCancel)="closeModal()">
    <ng-container *nzModalContent>
        <div *ngIf="limits" class="cloudinary-row" nz-row>
            <div nz-col [nzSpan]="24" class="text-center">
                {{'cloudinary.limits.msg1' | translate}}{{limits.width}}px{{'cloudinary.limits.msg2' | translate}}{{limits.height}}px
            </div>
        </div>
        <!-- Upload type selectors -->
        <div class="cloudinary-row" nz-row>
            <div nz-col [nzSpan]="12" class="text-center">
                <button class="text-center modal-buttons" [ngClass]="{'selected': loadType === 'fromFolderText'}" type="button" (click)="initCloudinaryFileUpload('fromFolderText')">
                    <i nz-icon nzType="upload" nzTheme="outline"></i><br /><br />
                    <label class="kt-label-font-color-2">{{ 'cloudinary.fromFolderText'| translate }}</label>
                </button>
            </div>
            <div nz-col [nzSpan]="12" class="text-center">
                <button class="text-center modal-buttons" [ngClass]="{'selected': loadType === 'fromUrlText'}" type="button" (click)="initCloudinaryFileUpload('fromUrlText')">
                    <i nz-icon nzType="link" nzTheme="outline"></i><br /><br />
                    <label class="kt-label-font-color-2">{{ 'cloudinary.fromUrlText'| translate }}</label>
                </button>
            </div>
        </div>
        <!-- Case upload from remote URL -->
        <div class="cloudinary-row" *ngIf="loadType === 'fromUrlText'" nz-row>
            <div nz-col [nzSpan]="24" class="text-center">
                <input nz-input placeholder="{{ 'cloudinary.remoteUrl' | translate }}" [(ngModel)]="fromUrl" />
            </div>
        </div>
        <!-- Case upload from local folder -->
        <div class="cloudinary-row" *ngIf="loadType === 'fromFolderText'" nz-row>
            <div nz-col [nzSpan]="24" class="text-center">
                <nz-upload [(nzFileList)]="fileList" [nzBeforeUpload]="beforeUpload" 
                    nzAccept=".png,.jpg,.gif,.bmp,.jpeg"
                    [nzFileType]="'image/png,image/jpeg,image/gif,image/bmp'">
                  <button nz-button><i nz-icon nzType="upload"></i>{{'cloudinary.selectImage' | translate}}</button>
                </nz-upload>
            </div>
        </div>
        <!-- Loader shown on file upload -->
        <div class="cloudinary-row" *ngIf="isLoading" nz-row>
            <div nz-col [nzSpan]="24" class="text-center">
                <div class="cloudinary-image-preview text-center">
                    <i nz-icon [nzType]="'loading'"></i>
                </div>
            </div>
        </div>
        <!-- Error -->
        <div class="cloudinary-row" *ngIf="error" nz-row>
            <div nz-col [nzSpan]="24">
                <label nz-typography nzType="danger">{{error}}</label>
            </div>
        </div>
    </ng-container>
    <div *nzModalFooter>
      <button nz-button nzType="default" (click)="closeModal()">{{'general.cancel' | translate}}</button>
      <button nz-button *ngIf="loadType === 'fromUrlText'" nzType="primary" (click)="saveUrl()">{{'general.add' | translate}}</button>
    </div>
</nz-modal>
