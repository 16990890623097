import { Component, OnInit } from '@angular/core';
import { SpinnerService } from 'src/app/core/services/spinner.service';

@Component({
	selector: 'app-spinner',
	templateUrl: './spinner.component.html',
	styleUrls: ['./spinner.component.less']
})
export class SpinnerComponent implements OnInit {
	showSpinner: boolean = false;

	constructor(private spinnerService: SpinnerService) { }

	ngOnInit(): void {
		this.spinnerService.showSpinner$.subscribe(s => { this.showSpinner = s });
	}

}
