import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzTableQueryParams } from 'ng-zorro-antd/table';
import { Subscription, timer } from 'rxjs';
import { map } from 'rxjs/operators';
import { Helpers } from 'src/app/core/helpers';
import { MonitorLockerList } from 'src/app/core/models/locker/monitor-locker';
import { Paging } from 'src/app/core/models/paged-data';
import { LockerService } from 'src/app/core/services/network/locker.service';
import { TenantService } from 'src/app/core/services/tenant.service';
import { LockerConnectionStatusComponent } from '../locker-connection-status/locker-connection-status.component';
import { ShowBoxesStatusComponent } from '../show-boxes-status/show-boxes-status.component';

@Component({
	selector: 'app-lockers-list-table',
	templateUrl: './lockers-list-table.component.html',
	styleUrls: ['./lockers-list-table.component.less']
})
export class LockersListTableComponent implements OnInit {

	@ViewChild(ShowBoxesStatusComponent)
	child!: ShowBoxesStatusComponent;

	@ViewChild(LockerConnectionStatusComponent)
	childLockerConncetionStatus!: LockerConnectionStatusComponent;

	constructor(private service: LockerService,
		private router: Router,
		private notification: NzNotificationService,
		private translate: TranslateService,
		private tenantService: TenantService) { }

	loading: boolean = false;
	lockers: MonitorLockerList = new MonitorLockerList;
	paging: Paging = new Paging();
	isFirst: boolean = true;
	subscriptions: Array<Subscription> = [];

	ngOnInit(): void {
		this.subscriptions.push(
			this.tenantService.tenant$.subscribe(() => {
				this.getLockersList();
			}
			)
		);
		this.subscriptions.push(
			timer(300000).pipe(
				map(() => {
					this.onRefresh();
					console.info("Locker's status refreshed");
				})
			).subscribe()
		);
	}

	// Refresh locker boxes status
	onRefresh() {
		this.child.onRefresh();
	}

	// Refresh locker's connectivity status
	onRefreshStatus() {
		this.childLockerConncetionStatus.onRefresh();
	}

	getLockersList() {
		this.loading = true;
		this.subscriptions.push(
			this.service.getLockers(this.paging).subscribe(resp => {
				this.lockers = resp;
				this.loading = false;
			}, err => {
				console.error(err);
				this.loading = false;
				if (err.statusCode === 403 || err.status === 403) {
					Helpers.goToUnauthorized(this.router);
				} else {
					Helpers.errorNotification(this.notification, this.translate, 'monitor.errors.unableToGetLockers');
				}
			})
		);
	}

	onQueryParamsChange(params: NzTableQueryParams): void {
		const { pageSize, pageIndex, sort } = params;
		this.paging.sort = sort;
		this.paging.pageSize = pageSize;
		this.paging.pageIndex = pageIndex;
		if (!this.isFirst) {
			this.getLockersList();
		}
		this.isFirst = false;
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(s => s.unsubscribe());
	}
}
